import React, {Component} from 'react';
import { AuthUserContext, withAuthorization } from '../../../shared/Session';
import {ListItem, ListItemLabel} from 'baseui/list';
import Utils from '../../../shared/Utils';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { LabelLarge, LabelMedium, ParagraphLarge, ParagraphMedium, ParagraphSmall } from 'baseui/typography';
import {Button, SIZE, SHAPE, KIND} from 'baseui/button';
import MpsModal from '../../../shared/Utils/modal';
import * as ROUTES from '../../Constants';
import Request from '../../../shared/Request';
import Logger from '../../../shared/Utils/logger';

const restricrWidthProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                width: '60px',
                flexGrow: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start'
            }),
        },
    },
};

const leftCenterProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start'
            }),
        },
    },
};

class AttachedFiles extends Component {
    constructor(props) {
        super(props);

        this.state = {
            protocol_task_responses: this.props.protocol_task_responses.sort((a, b) => {return a.created_at > b.created_at ? -1 : 1}),
            showModal: false,
            imageUrl: ''
        }
    }

    componentDidMount = () => {
        const data = new FormData();

        this.props.protocol_task_responses.map(task_response => {
            if (!task_response.seen_by_provider) {
                data.append('id[]', task_response.id);
            }
        })

        Request.post({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/physician_task_response/seen_by_provider",
            body: data,
            callbackSuccess: result => {},
            callbackError: error => {}
        })
    };

    getFileExtension = (file) => {
        return decodeURI(file).split('.').pop();
    };

    getFileName = (file) => {
        return decodeURI(file).split('/').pop();
    };

    renderTasks = () => {
        return this.state.protocol_task_responses.map(
            (task, idx) => {
                return <FlexGrid key={idx} flexGridRowGap={"scale400"} marginBottom={'12px'} style={{backgroundColor: '#fafafa', padding: '12px'}}>
                    <FlexGridItem><LabelMedium style={{margin: 0}}>{Utils.renderLocaleString(task.updated_at)}</LabelMedium></FlexGridItem>
                    <FlexGridItem><ParagraphMedium style={{margin: 0}}>{task.description}</ParagraphMedium></FlexGridItem>
                    {task.files_url.map((file, idx) => <FlexGridItem key={idx}>
                        <FlexGrid flexGridColumnCount={2}>
                            <FlexGridItem {...restricrWidthProps}>{['pdf'].includes(this.getFileExtension(file.url).toLowerCase()) ? <Button $as='a' href={file.url} target="_blank" kind={KIND.secondary} size={SIZE.mini} shape={SHAPE.pill}>View</Button> : <Button kind={KIND.secondary} size={SIZE.mini} shape={SHAPE.pill} onClick={(e) => this.showModal(e, file.url)}>View</Button>}</FlexGridItem>
                            <FlexGridItem {...leftCenterProps}><ParagraphSmall style={{margin: 0, color: '#999999'}}>{this.getFileName(file.url)}</ParagraphSmall></FlexGridItem>
                        </FlexGrid>
                    </FlexGridItem>
                    )}
                </FlexGrid>
            }
        );
    };

    renderPhotoId = () => {
        return <FlexGrid flexGridRowGap={"scale400"} marginBottom={'12px'} style={{backgroundColor: '#fafafa', padding: '12px'}}>
            <FlexGridItem><LabelMedium style={{margin: 0}}>{Utils.renderLocaleString(this.props.photo_data.created_at)}</LabelMedium></FlexGridItem>
            <FlexGridItem><ParagraphMedium style={{margin: 0}}>Patient photo ID</ParagraphMedium></FlexGridItem>
            <FlexGridItem>
                <FlexGrid flexGridColumnCount={2}>
                    <FlexGridItem {...restricrWidthProps}><Button kind={KIND.secondary} size={SIZE.mini} shape={SHAPE.pill} onClick={(e) => this.showModal(e, this.props.photo_id)}>View</Button></FlexGridItem>
                    <FlexGridItem {...leftCenterProps}><ParagraphSmall style={{margin: 0, color: '#999999'}}>{this.getFileName(this.props.photo_id)}</ParagraphSmall></FlexGridItem>
                </FlexGrid>
            </FlexGridItem>
        </FlexGrid>
    }

    renderSelfie = () => {
        return <FlexGrid flexGridRowGap={"scale400"} marginBottom={'12px'} style={{backgroundColor: '#fafafa', padding: '12px'}}>
        <FlexGridItem><LabelMedium style={{margin: 0}}>{Utils.renderLocaleString(this.props.selfie_data.created_at)}</LabelMedium></FlexGridItem>
        <FlexGridItem><ParagraphMedium style={{margin: 0}}>Patient selfie</ParagraphMedium></FlexGridItem>
        <FlexGridItem>
            <FlexGrid flexGridColumnCount={2}>
                <FlexGridItem {...restricrWidthProps}><Button kind={KIND.secondary} size={SIZE.mini} shape={SHAPE.pill} onClick={(e) => this.showModal(e, this.props.selfie)}>View</Button></FlexGridItem>
                <FlexGridItem {...leftCenterProps}><ParagraphSmall style={{margin: 0, color: '#999999'}}>{this.getFileName(this.props.selfie)}</ParagraphSmall></FlexGridItem>
            </FlexGrid>
        </FlexGridItem>
    </FlexGrid>
    };

    closeModal = () => {
        this.setState({showModal: false});
    };

    showModal = (event, img) => {
        Logger.log("Settings sstate iumage url to " + img);
        this.setState({showModal: true, imageUrl: img});
    };

    createModal = () => {
        return <MpsModal
            open={this.state.showModal}
            callbackCancel={this.closeModal}
            title={this.state.title}
            body={<img src={this.state.imageUrl} alt={this.state.title} width="100%"/>}
        />
    };

    render = () => {
        return (
            <AuthUserContext.Consumer>
                {authUser =>
                    (
                        <div>
                            {this.renderTasks()}
                            {this.renderPhotoId()}
                            {this.renderSelfie()}
                            {this.createModal()}
                        </div>
                    )
                }
            </AuthUserContext.Consumer>
        )
    };
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(AttachedFiles);
