import React from 'react';
import {StyledLink} from 'baseui/link';

const data = {
    en: {
        // Manage
        page_title: "Clinic builder",
        page_subtext: <span>On Clinic, create a digital health clinic in minutes & reach patients from across the country. All required fields must be completed prior to submission. View an example clinic <StyledLink href="https://github.com/Pharmr/Example-of-a-server" target="_blank" rel="noopener noreferrer">here</StyledLink>.</span>,
        conditions_description: 'List the specific diagnosed conditions that your clinic treats. This displays on the clinic About page, is used in search discoverability & also in the patient-physician services contract.',
        protocol_name: <span>Name</span>,
        protocol_favicon_image: <span>Upload an icon for your clinic</span>,
        protocol_name_description: "Give your clinic a name. It should hint at the target condition or patient community it addresses. Names must be 15 characters or less, and contain only alphanumeric characters (letters A-Z, numbers 0-9) and underscores, without spaces. Names that include any drug or brand names will be rejected.",
        protocol_name_placeholder: "15 characters or less without spaces",
        protocol_subscription_fee: <span>Subscription price</span>,
        protocol_subscription_fee_description: "Select how much you want to charge each month. If your clinic addresses a standard condition with low maintenance requirements, consider pricing it lower for faster clinic growth.",
        protocol_summary: <span>Summary</span>,
        protocol_summary_description: "Describe your medical service (i.e. the disease focus, your patient population and/or therapeutic categories) in 1 sentence (160 characters).",
        protocol_summary_placeholder: "Max 160 characters",
        protocol_description: <span>Description</span>,
        protocol_description_text: "Describe your medical service in 2-4 sentences. What are your treatment goals? What will be expected from your members? What is your practice style? How is your service unique? Description is limited to 750 chracters.",
        protocol_description_placeholder: "Max 750 chracters",
        protocol_formulary: <span>Formulary</span>,
        protocol_formulary_description: "A list of medications approved for your members to request.",
        protocol_formulary_drug_placeholder: "Drug name",
        protocol_formulary_dosage_placeholder: "Dosage",
        protocol_formulary_quantity_placeholder: "Quantity",
        protocol_patient_eligibility: <span>Member eligibility </span>,
        protocol_patient_eligibility_description: "Describe the eligibility criteria to join. Include things like age, sex, diagnosis, contraindicated conditions, comorbidities or concurrent therapy that may qualify or disqualify prospective members.",
        protocol_patient_eligibility_placeholder: "Describe any contraindicated conditions, comorbidities or concurrent therapy that may qualify or disqualify prospective members.",
        protocol_welcome_message: <span>Welcome message</span>,
        protocol_welcome_message_placeholder:"Example of welcome message goes here",
        protocol_office_hours: <span>Banner message</span>,
        protocol_office_hours_description: "Address your subscribers directly when they log into the clinic (160 characters).",
        protocol_office_hours_placeholder: "Max 160 characters",
        protocol_risks: <span>Risks &amp; alternatives </span>,
        protocol_risks_description: "Describe the risks associated with this type of medical care and any treatment alternatives (if available). If you're prescribing off-label, mention it here. This will be displayed on your clinic's description page and in the patient-physician services agreement (260 characters).",
        protocol_risks_placeholder: "Describe the risks associated with this type of care and any treatment alternatives. Restricted to 260 characters.",
        protocol_conditions_title: <span>Conditions</span>,
        protocol_rules: <span>Community Rules</span>,
        protocol_rules_description: "Provide rules & guidelines for engaging with you & making drugstore requests. If you're not sure what to write, you can use our default #Rules (see example clinic at top of page).",
        protocol_rules_placeholder: "",

        protocol_intake_form: <span>Intake forms</span>,
        protocol_intake_form_description: "Create your intake & follow up forms. You'll use these along with other information to determine membership eligibility",
        protocol_onboarding_form: <span>Intake form <sup>*</sup></span>,
        protocol_onboarding_form_description: "Obtain a complete medical history to evaluate prospective members. This should include medical history, family history, current treatment regimens, possible complications, comorbidities, and comfort level with virtual care. We collect the following automatically on behalf of the patient: DOB, height, weight, biological sex, current medical conditions, current meds list & current drug allergies.",
        protocol_drug_request_form_name: <span>Follow up form <sup>*</sup></span>,
        protocol_drug_request_form_description: "This is used for refills and new drug requests for existing members. It should be shorter than the onboarding form and focus on follow-up and compliance.",
        protocol_submission_description: "You're about to submit this clinic for activation. If activated, it will go live and display immediately on the Clinic site. It should be noted, clinic-name and subscription-price cannot be changed once activated.",
        protocol_submitted_description: "Your activation request is pending. You'll be alerted again with a result.",


        // Optionals
        optionals_title: "Video call & file upload settings",
        optionals_description: "Customize your practice settings for your own needs.",

        optionals_video_call_title: "Adds a call requirement to join your clinic",
        optionals_video_call_description: "Enables a video calling requirement for new applicants. Select this if you want to screen all applicants with a video call prior to joining, or if you practice in a state(s) that require you to establish a patient-physician relationship via video call. ",
        optionals_video_call_url_description: "Enter the URL of your video call room (i.e. doxy.me, Zoom, etc). Alternatively, you can link to your calendar for scheduling.",
        optionals_video_call_url_title: "Enter url for video service provider",
        optionals_video_call_url_placeholder: "Enter call link or calendar link",
        optionals_video_call_availability: "Enter your call schedule availability",
        optionals_video_call_availability_description: "Let patients know when they can reach you (i.e. Monday - Thursday 8am to 12pm EST). If you're using a calendar instead to schedule calls, say that here (160 characters)",
        optionals_video_call_availability_placeholder: "Enter your call instructions",

        optionals_onboarding_file_attach_name: "Require file uploads for onboarding",
        optionals_onboarding_file_attach_description: "Select this if you require the patient to upload documents to become a member (i.e. photos of skin conditions, pdfs of recent lab work, medical records, etc). The user is limited to 10mb total (jpg, png, pdf).",
        optionals_onboarding_file_attach_field_name: "Describe the type of files you require",
        optionals_onboarding_file_attach_field_description: "Describe the requested files here (i.e. CBC, CD4 counts & viral load from the last 30 days). For labs, list the specific Quest & Labcorp test codes here (160 characters).",
        optionals_onboarding_file_attach_field_placeholder: "Describe required files (docs, labs or images). Restricted to jpg, pdf, png; 10mb limit.",

        optionals_drug_request_file_attach_name: "Require file uploads for drug requests",
        optionals_drug_request_file_attach_name_description: "Select this if you want to require members to upload documents in connection with drugstore requests (i.e. photos of skin conditions, recent lab work, medical records, etc). The user is limited to 10mb (jpg, png, pdf).",
        optionals_drug_request_file_attach_field_name: "Describe the type of files you require",
        optionals_drug_request_file_attach_field_description: "Describe the requested documents here (i.e. CBC & CMP-14 within last 30 days). For labs, list the specific Quest & Labcorp test codes here (160 characters).",
        optionals_drug_request_file_attach_field_placeholder: "Describe required files (docs, labs or images). Restricted to jpg, pdf, png; 10mb limit.",

        optionals_share_invite_title: "Passcode",
        optionals_share_invite_description: "Make your clinic exlusive by adding a passcode and sharing it with new subscribers. If left blank, anyone can apply to join your clinic.",

        // Messages
        protocol_submitted: "Your new clinic activation request is pending. You'll be alerted with a result.",
        required_fields_message: "All fields with an * are required.",
        rejecting_new_subscriptions: "Enabling this will prevent new patients from applying to your clinic.",
        rejecting_new_subscriptions_description: "Setting this to true will prevent new patients from applying to the clinic.",
        enable_community_features:"Enabling this adds patient-only group chat to your clinic.",
        is_refill_service:"Enabling this adds the patient drugstore to the patient dashboard and requires patients to request a prescription to be onboarded.",
        enable_community_features_description:"Setting this to true will enable patient groupchat, community rules, members list and count.",
    }
}

const content = (lang) => {
    return data[lang];
};

export default content;
