import React, {Component} from 'react';
import * as ROUTES from '../../Constants';
import { AuthUserContext, withAuthorization } from '../../../shared/Session';
import { Button, KIND, SHAPE, SIZE } from "baseui/button";
import Request from '../../../shared/Request';
import MpsModal from '../../../shared/Utils/modal';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { HeadingLarge, ParagraphMedium } from 'baseui/typography';
import Utils from '../../../shared/Utils';
import { Textarea } from 'baseui/textarea';
import content from './content';
import Logger from '../../../shared/Utils/logger';

class RejectPatient extends Component{
    constructor(props){
        super(props);

        this.state = {
            isLoaded: false,
            error: null,
            showModal: false,
            loading: false,
            reject_reason: '',
            show_component: ['pending'].includes(this.props.protocol_subscription_status),
            active_prescriptions: this.props.active_prescriptions,

            title: 'Reject patient?',
            url: ROUTES.BASE_URL_API + "/physician_patient_action/reapply/" + this.props.patient_uuid,

            content: content('en')
        }

        this.reapplyAction = 'Reject';
        this.errorInterval = null;
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.protocol_subscription_status !== prevProps.protocol_subscription_status
            || this.props.active_prescriptions.length !== prevProps.active_prescriptions.length
        )
        {
            this.setState({
                show_component: ['pending'].includes(this.props.protocol_subscription_status),
                error: null,
                active_prescriptions: this.props.active_prescriptions,
                ...Utils.clearAllNotifications()
            });
        }
    };

    isProtocolActive = () => {
        return this.props.protocol.status === 'active';
    };

    handleModalClose = () => {
        this.setState({showModal: false, ...Utils.clearAllNotifications()});
    }

    handleActionComplete = () => {
        this.handleSubmit();
    }

    presentModal = () => {
        if (this.state.active_prescriptions.length > 0) {
            this.setState({error: null}, () => this.setState({error: {message: this.state.content.reject_after_prescribe}}));
        } else {
            this.setState({showModal: true, ...Utils.clearAllNotifications()});
        }
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value, ...Utils.clearAllNotifications() });
    }

    handleSubmit = () => {
        const data = new FormData();

        data.append('reject_reason', this.state.reject_reason);
        data.append('protocol_id', this.props.protocol_number);

        Request.put({
            firebase: this.props.firebase,
            route: this.state.url,
            body: data,
            callbackSuccess: (result) => {
                this.setState({showModal: false, ...Utils.clearAllNotifications()});
                if (this.props.callbackSuccess) {
                    this.props.callbackSuccess();
                }

                if (this.props.callbackSubscriptionStatus) {
                    this.props.callbackSubscriptionStatus(this.props.protocol_subscription.id, 'reapply');
                }
            },
            callbackError: (error) => {
                this.setState({
                    isLoaded: true,
                    error,
                    ...Utils.clearAllNotificationsExceptError()
                })
            }
        })
    }

    renderItems = () => {
        return this.isProtocolActive() ? <Button
            shape={SHAPE.pill}
            size={SIZE.compact}
            kind={KIND.secondary}
            isLoading={this.state.loading}
            onClick={(e) => this.presentModal()}
            overrides={{
                BaseButton: {
                    style: ({ $theme }) => ({
                        color: $theme.colors.primaryA,
                        backgroundColor: $theme.colors.warning400,
                        ":hover": {
                            backgroundColor: $theme.colors.warning500
                        },
                        ":focus": {
                            backgroundColor: $theme.colors.warning300
                        },
                        ":active": {
                            backgroundColor: $theme.colors.warning300
                        },

                    })
                }
            }}
        >{this.reapplyAction}</Button> : <Button
            shape={SHAPE.pill}
            size={SIZE.compact}
            kind={KIND.secondary}
            disabled={true}
        >{this.reapplyAction}</Button>
    };

    render() {
        return (
            <AuthUserContext.Consumer>
                {authUser =>
                    (
                        this.state.show_component ? <span>
                            {Utils.renderError(this.state.error)}
                            {this.state.showModal
                            ? <MpsModal
                                open={this.state.showModal}
                                title={Utils.renderTitleCenter(this.state.title)}
                                callbackCancel={this.handleModalClose}
                                callbackCancelButton={'Go back'}
                                callback={this.handleActionComplete}
                                callbackButton={'Reject'}
                                disabled={!this.state.reject_reason}
                                withFooter={true}
                                body={<FlexGrid>
                                    <FlexGridItem style={{textAlign: 'center'}}><ParagraphMedium style={{marginTop: 0,  marginBottom:'10px', marginRight:'auto', marginLeft:'auto', width: '90%'}}>
                                        {"Rejected patients have the opportunity to reapply."}</ParagraphMedium>
                                    </FlexGridItem>
                                    <FlexGridItem>
                                        <Textarea
                                            id="reject_reason"
                                            name="reject_reason"
                                            value={this.state.reject_reason}
                                            onChange={this.handleChange}
                                            overrides={{
                                                Input: {
                                                    style: {
                                                        maxHeight: '300px',
                                                        minHeight: '100px',
                                                        minWidth: '300px',
                                                        width: '100vw', // fill all available space up to parent max-width
                                                        resize: 'both',
                                                    },
                                                },
                                                InputContainer: {
                                                    style: {
                                                        maxWidth: '100%',
                                                        width: 'min-content',
                                                    },
                                                },
                                            }}
                                        />
                                    </FlexGridItem>
                                </FlexGrid>
                                }
                            />
                            : null}

                            {this.renderItems()}
                        </span> : null
                    )
                }
            </AuthUserContext.Consumer>
        );
    }
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(RejectPatient);
