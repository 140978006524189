import React from "react";
import PropTypes from "prop-types";
import {
  PopupboxManager,
  PopupboxContainer
} from 'react-popupbox';
import "react-popupbox/dist/react-popupbox.css"

class VerifyPhotos extends React.Component {
  constructor(props) {
    super(props);
    this.firstPhoto = props.firstPhoto;
    this.secondPhoto = props.secondPhoto;
    const content = (
      <div className="verify_modal" onClick={PopupboxManager.close}>
        <img src={props.firstPhoto} alt="Verify Photo"  className="left verify_modal" />
        <img src={props.secondPhoto} alt="Verify Photo"  className="right verify_modal" />
      </div>
    );
    this.openPopupbox = function(e) {
      PopupboxManager.open({content, config: {titleBar: {enable: true, text: "Verify Photos match"}}});
    };
  }
  render() {
    return (
      <div onClick={this.openPopupbox}>
      <div className="left verify_thumb">
        <img src={this.firstPhoto} alt="Verify Photo"  className="verify_thumb" />
      </div>
      <div className="right verify_thumb">
        <img src={this.secondPhoto} alt="Verify Photo"  className="verify_thumb" />
      </div>
        <PopupboxContainer />
      </div>
    );
  }
}

VerifyPhotos.propTypes = {
  firstPhoto: PropTypes.string,
  secondPhoto: PropTypes.string,
};
export default VerifyPhotos;



