import React, {Component} from 'react';

class SubscriptionManagementBoard extends Component {
    constructor(props) {
        super(props);

        this.allowedStatuses = ['active', 'active_pending_cancel', 'inactive'];
    }

    drawButton = (subscription, clickEvent) => {
        return <button id={subscription.id} data-id={subscription.status} onClick={clickEvent}>{this.drawButtonName(subscription.status)}</button>
    };

    drawButtonName = (status) => {
        if (status == 'active') {
            return 'Cancel';
        } else if (status == 'inactive' || status == 'active_pending_cancel') {
            return 'Reactivate';
        }
    };

    isDrawAble = (status) => {
        return this.allowedStatuses.indexOf(status) >= 0;
    };

    render () {
        return (
            <div>
                {
                    this.isDrawAble(this.props.subscription.status)
                    ?
                    <div>
                        <div>{this.props.subscription.protocol.name} - ${this.props.subscription.protocol.billing_price}</div>
                        <div>{this.props.subscription.status}</div>
                        <div>{this.drawButton(this.props.subscription, this.props.clickEvent)}</div>
                        <p></p>
                    </div>
                    :
                    ''
                }
            </div>
        );
    }
}

// TODO Only authorized users shouls be able to toggle protocol subscriptions
export default SubscriptionManagementBoard;
