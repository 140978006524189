import React from 'react';
import { StyledLink } from 'baseui/link';
import {ParagraphSmall, LabelLarge} from 'baseui/typography';

const data = {
    en: {

        account_title: 'Account',
        credentials_title: 'Credentials',
        payments_title: 'Payments',
        id_proofing_button: 'ID proofing',

        business_info_name_description: <ParagraphSmall style={{margin: 0, marginTop: '4px', marginBottom: '10px', color: '#666666'}}>Enter your business name as it appears on your W-9.</ParagraphSmall>,
        business_info_address_description: <ParagraphSmall style={{margin: 0, marginTop: '4px', marginBottom: '10px', color: '#666666'}}>Enter your business address as it appears on your W-9.</ParagraphSmall>,

        // Profile
        profile_account_email: 'Account email',
        profile_account_phone: 'Account phone',

        profile_legal_name_title: 'Legal name',
        profile_legal_contact_email: 'Contact email',
        profile_legal_contact_email_description: 'You will receive receipts of remittance payments, updates and announcements at this email address.',
        profile_username_description: 'This is your public name. It will show on any clinics you create and any messages you send to patients.',

        profile_about_title: 'About',
        profile_about_description: 'Write a blurb about yourself, maxium 260 characters. Talk about your medical background. You can include your website, social media handles, youtube channels, whatever is relevant to your practice. This blurb will appear on the creator page of the Phamr App and gives patients an introduction to you.',
     
        profile_specialties_title: 'Specialties',
        profile_specialties_description: 'List your medical specialities. Use a comma to separate each specialty.',

        // Creds
        credentials_npi_number_title: 'NPI Number',
        credentials_npi_number_description: 'Enter your National Provider Identifier number.',

        credentials_dea_number_title: 'DEA Number',
        credentials_dea_number_description: 'If you intend on prescribing controlled substances, provide your DEA registration number.',

        credentials_npdb_query_title: 'Current NPDB self query (PDF only)',
        credentials_npdb_query_description: <span>Provide your National Practictioner Data Bank self-query. NPDB self queries can be ordered <span><StyledLink href={"https://www.npdb.hrsa.gov/pract/selfQueryBasics.jsp"} target="_blank" style={{color:"#00a1ff"}}>here</StyledLink></span></span>,

        credentials_malpractice_title: 'Current malpractice coverage (PDF only)',
        credentials_malpractice_description: 'Provide evidence of active medical professional liability insurance policy.',

        credentials_state_license_title: "State licenses",
        credentials_state_license_description: 'List your medical licenses. Include the state, license number, and expiration of the license.',

        // Payments
        payments_business_info_title: <LabelLarge style={{margin: 0}}>Business information</LabelLarge>,
        payments_business_info_description: <ParagraphSmall style={{margin: 0, marginTop: '4px', marginBottom: '10px', color: '#666666'}}>Provide your practice information by completing a W9 and uploading it below. Current W9 can be downloaded <StyledLink href="https://www.irs.gov/pub/irs-pdf/fw9.pdf" target="_blank" style={{color:"#00a1ff"}}>here.</StyledLink></ParagraphSmall>,

        payments_bank_information_title: <LabelLarge>Bank information</LabelLarge>,
        payments_bank_information_description: <ParagraphSmall style={{margin: 0, marginTop: '4px', color: '#666666'}}>Provide your business bank information for membership subscription payments.</ParagraphSmall>,

        payments_bank_name_title: <ParagraphSmall style={{margin: 0, marginTop: '4px', marginBottom: '10px', color: '#666666'}}>Bank name</ParagraphSmall>,
        payments_name_on_account_title: <ParagraphSmall style={{margin: 0, marginTop: '4px', marginBottom: '10px', color: '#666666'}}>Name on account</ParagraphSmall>,

        payments_routing_number_title: <ParagraphSmall style={{margin: 0, marginTop: '4px', marginBottom: '10px', color: '#666666'}}>Routing number</ParagraphSmall>,
        payments_routing_confirm_number_title: <ParagraphSmall style={{margin: 0, marginTop: '4px', marginBottom: '10px', color: '#666666'}}>Confirm routing number</ParagraphSmall>,

        payments_account_number_title: <ParagraphSmall style={{margin: 0, marginTop: '4px', marginBottom: '10px', color: '#666666'}}>Account number</ParagraphSmall>,
        payments_account_number_confirm_title: <ParagraphSmall style={{margin: 0, marginTop: '4px', marginBottom: '10px', color: '#666666'}}>Confirm account number</ParagraphSmall>,

        // ID Proofing
        id_proofing_title: <LabelLarge>ID Proofing</LabelLarge>,
        id_proofing_description: <ParagraphSmall style={{margin: 0, color: '#666666'}}>Pharmr uses ID.me to verify your identity as a provider. Please complete the ID proofing process by clicking the link below. If you already have an ID.me account, all you have to do is sign in.</ParagraphSmall>,
        id_proofing_hint: null,
        id_proofing_register: 'Register with ID.me',

        // Licenses
        license_edit_button: 'Edit licenses',
        state_license_modal_title: "State licenses",
        license_number: "License number",
    }
}

const content = (lang) => {
    return data[lang];
};

export default content;
