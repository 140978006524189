import React, {Component} from 'react';
import Logger from '../../../shared/Utils/logger';
import MpsModal from '../../../shared/Utils/modal';
import Utils from '../../../shared/Utils';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import {Button, KIND, SIZE, SHAPE} from 'baseui/button';
import content from './content';
import { AuthUserContext, withAuthorization } from '../../../shared/Session';
import { Textarea } from "baseui/textarea";
import * as ROUTES from '../../Constants';
import Request from '../../../shared/Request';
import {ParagraphMedium} from 'baseui/typography';

class MessagePatient extends Component {
    constructor(props){
        super(props);

        this.state = {
            protocol_subscription: this.props.protocol_subscription,
            error: null,
            content: this.getContent(),
            message_patient: ['pending', 'reapply'].includes(this.props.protocol_subscription.status) ? this.props.default_message : '',
            message_patient_submitting: false,

            showButton: this.props.show == undefined ? true : false,
            show: this.props.show != undefined ? this.props.show : false,
            modal_closeable: this.props.closeable == false ? false : true,
            message_title: this.props.message_title,
            message_description: this.props.message_description

        }
    }

    getContent = () => {
        return content('en');
    };

    closeMessagePatient = () => {
        this.setState({show: false, message_patient: ''});

        if (this.props.cancelMessageCallback) {
            this.props.cancelMessageCallback();
        }
    };

    openMessagePatient = (e) => {
        this.setState({show: true});
    };

    handleMessagePatientChange = (event) => {
        this.setState({message_patient: event.target.value});
    };

    submit = () => {
        this.setState({message_patient_submitting: true});

        const data = new FormData();
        data.append('patient_uuid', this.props.patient_uuid);
        data.append('protocol_number', this.props.protocol_number);
        data.append('message_for_patient', this.state.message_patient);

        Request.post({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/provider_message",
            body: data,
            callbackSuccess: (result) => {
                this.setState({
                    show: false,
                    message_patient: '',
                    message_patient_submitting: false
                });

                if (this.props.callback) {
                    this.props.callback(result.patient_message);
                }
            },
            callbackError: (error) => {
                this.setState({
                    message_patient_submitting: false,
                    isLoaded: true,
                    error
                })
            }
        })
    };

    /*isPatientMessageTooLong = (message) => {
        if (message.length > Utils.patientMessageMaxLen()){
            return true;
        } else {
            return false;
        }
    };*/

    isPatientMessageTooLong = (message) => {
        if (!message || message.length === 0) {
            return false; // Message is undefined or empty, so it's not too long.
        }
        return message.length > Utils.patientMessageMaxLen();
    };

    isProtocolActive = () => {
        return this.props.protocol && this.props.protocol.status === 'active';
    };

    render() {
        return <AuthUserContext.Consumer>
            {authUser =>
                (
                    <>
                    {this.state.showButton ? (this.isProtocolActive() ? <Button
                        kind={KIND.secondary}
                        size={SIZE.compact}
                        shape={SHAPE.pill}
                        onClick={this.openMessagePatient}
                        startEnhancer={() => <img style={{height:'16px'}} src={window.matchMedia("(prefers-color-scheme: light)").matches ?  "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_16/v1681361269/sendmessage-black-01_tchub4.png" : "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_16/v1681361269/sendmessage-white-01_l0se2m.png"}/>}
                        overrides={{
                            StartEnhancer: {
                                style: ({ $theme }) => ({
                                    marginRight: '4px'
                                })
                            }
                        }}
                    >{this.state.content.message_patient_button}</Button> : <Button
                        kind={KIND.secondary}
                        size={SIZE.compact}
                        shape={SHAPE.pill}
                        disabled={true}
                        startEnhancer={() => <img style={{height:'16px'}} src={/*Storage.get('theme') == 'light' ? "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_16/v1681361269/sendmessage-black-01_tchub4.png" :*/ "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_16/v1681361269/sendmessage-white-01_l0se2m.png"}/>}
                        overrides={{
                            StartEnhancer: {
                                style: ({ $theme }) => ({
                                    marginRight: '4px'
                                })
                            }
                        }}
                    >{this.state.content.message_patient_button}</Button>) : null}
                    <MpsModal
                        open={this.state.show}
                        title={Utils.renderTitleCenter(this.state.message_title ? this.state.message_title : this.state.content.message_patient_title)}
                        callback={this.submit}
                        callbackButton={this.state.content.message_patient_submit}
                        disabled={!this.state.message_patient}
                        callbackCancel={this.closeMessagePatient}
                        withFooter={true}
                        closeable={this.state.modal_closeable}
                        isLoading={this.state.message_patient_submitting}
                        body={
                            <FlexGrid flexGridRowGap={"scale600"}>
                                {
                                    this.state.error
                                    ? <FlexGridItem>
                                        {Utils.renderError(this.state.error)}
                                    </FlexGridItem>
                                    : null
                                }

                                {
                                    this.state.message_description
                                    ? <FlexGridItem><ParagraphMedium style={{textAlign: 'center', margin: 0, margin:'auto', width: '90%'}}>{this.state.message_description}</ParagraphMedium></FlexGridItem>
                                    : null
                                }

                                <FlexGridItem>
                                <Textarea
                                    error={this.isPatientMessageTooLong(this.state.message_patient)}
                                    id="message_patient"
                                    name="message_patient"
                                    value={this.state.message_patient}
                                    onChange={this.handleMessagePatientChange}
                                    placeholder={'6000 characters max'}
                                    autoFocus={true}
                                    overrides={{
                                        Input: {
                                        style: {
                                            //maxHeight: '300px',
                                            minHeight: '400px',
                                            minWidth: '300px',
                                            width: '100vw', // fill all available space up to parent max-width
                                            resize: 'both',
                                        },
                                        },
                                        InputContainer: {
                                            style: {
                                                maxWidth: '100%',
                                                width: 'min-content',
                                            },
                                        },
                                    }}
                                />
                                </FlexGridItem>
                            </FlexGrid>
                        }
                    />
                    </>
                )
            }
        </AuthUserContext.Consumer>
    }
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(MessagePatient);
