import React, {Component} from 'react';
import Utils from '../../shared/Utils';
import Logger from '../../shared/Utils/logger';
import MpsModal from '../../shared/Utils/modal';
import { withFirebase } from '../../shared/Firebase';
import {Table, DIVIDER, SIZE as TableSize} from 'baseui/table-semantic';
import {ParagraphSmall} from 'baseui/typography';

class ServerList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: this.props.show,
            error: null
        };
    }

    componentDidMount = () => {
        Logger.log("ServerList did mount...");
    };

    componentDidUpdate = (prevProps) => {
        Logger.log("ServerList did update...");
        if (this.props.show !== prevProps.show) {
            this.setState({show: this.props.show});
        }
    };

    render () {
        Logger.log(this.state);
        const COLUMNS = ['Community','Status','Description'];
        const DATA = [['Bionic', 'Online', 'A CGM support server for T1D patients']];
        return (
            <MpsModal
                open={this.state.show}
                callbackCancel={this.props.callback}
                title="Server list"
                body={<div style={{textAlign: 'left'}}>
                    <ParagraphSmall>The following communities are available to join on Clinic.</ParagraphSmall>
                    <Table columns={COLUMNS} data={DATA} divider={DIVIDER.vertical} size={TableSize.compact}/></div>}
                withFooter={false}
                autoFocus={false}
                overrideModalWidth={'343px'}
            />
        );
    }
}

export default withFirebase(ServerList);
