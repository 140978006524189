import React, {Component} from 'react';
import { AuthUserContext, withAuthorization } from '../../../shared/Session';
import Utils from '../../../shared/Utils';
import Logger from '../../../shared/Utils/logger';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import {ParagraphLarge, HeadingSmall, HeadingXSmall, LabelSmall, LabelLarge, ParagraphMedium, LabelMedium, ParagraphSmall} from 'baseui/typography';
import {Button, KIND, SHAPE, SIZE} from 'baseui/button';
import * as ROUTES from '../../Constants';
import Request from '../../../shared/Request';

const outlinedBlock = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                border: "1px solid " + $theme.colors.backgroundTertiary,
                marginBottom: "8px",
                borderRadius: "13px",
                padding: "12px"
            }),
        },
    },
};

class RequestDrugs extends Component {
    constructor(props){
        super(props);

        this.state = {
            alreadyPrescribed: this.props.alreadyPrescribed,
            protocol_subscription: this.props.protocol_subscription,
            dismiss_line_item_id: null,
            dismiss_in_progress: false,
            dismissed: []
        }
    }

    isOnboarding = () => {
        return this.state.protocol_subscription.status == 'pending';
    };

    componentDidUpdate = (prevProps) => {
        if (prevProps.alreadyPrescribed.length != this.state.alreadyPrescribed.length) {
            this.setState({alreadyPrescribed: prevProps.alreadyPrescribed});
        }
    };

    dismissDrug = (line_item_id) => {
        const line_item_id_to_dismiss = line_item_id;
        this.setState({dismiss_line_item_id: line_item_id, dismiss_in_progress: true})

        Request.put({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/physician_patient_request/dismiss/" + line_item_id,
            body: new FormData(),
            callbackSuccess: (result) => {
                this.setState(prevState => {
                    prevState.dismissed.push(line_item_id_to_dismiss);
                    prevState.dismiss_line_item_id = null;
                    prevState.dismiss_in_progress = false;
                    
                    return prevState
                }, () => this.props.dismissed(this.state.dismissed));
            },
            callbackError: (error) => {
                this.setState({dismiss_line_item_id: null, dismiss_in_progress: false})
            }
        })
    };

    renderBox = (line_item_id, color, time, drug, quantity, pharmacy) => {
        return <FlexGrid key={line_item_id} flexGridRowGap={"scale300"} {...outlinedBlock}>
            <FlexGridItem><LabelSmall style={{margin:0, color: "#999999"}}>{time}</LabelSmall></FlexGridItem>
            <FlexGridItem>
            <Button 
                kind={KIND.tertiary} 
                size={SIZE.mini} 
                shape={SHAPE.pill} 
                onClick={(event) => Utils.copyToClipboard(drug)}
                overrides={{
                    BaseButton: {
                        style: ({ $theme }) => ({
                            padding:'0 2px 0 0'
                        })
                    }
                }}
                ><img style={{height:'18px'}} src={window.matchMedia("(prefers-color-scheme: light)").matches ?  "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_18/v1681409754/copy-black-01_j0lgrq.png" : "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_18/v1681409754/copy-white-01_dv3pr0.png"}/>
            </Button>
            <LabelMedium style={{margin:0, marginRight: '5px', display: 'inline', userSelect:'all'}}>{drug}</LabelMedium>
            </FlexGridItem>
            <FlexGridItem><ParagraphSmall style={{margin:0}}>Quantity {quantity}</ParagraphSmall></FlexGridItem>
            <FlexGridItem><ParagraphSmall style={{margin:0}}>{pharmacy}</ParagraphSmall></FlexGridItem>
            {this.isOnboarding() ? null : <FlexGridItem>
                <Button
                    kind={KIND.secondary}
                    size={SIZE.compact}
                    shape={SHAPE.pill}
                    onClick={(event) => this.dismissDrug(line_item_id)}
                    isLoading={this.state.dismiss_line_item_id == line_item_id}
                    disabled={this.state.dismiss_in_progress || this.props.is_rx_pad_open}
                    startEnhancer={() => <img style={{height:'16px'}} src={/*Storage.get('theme') == 'light' ?*/ "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_16/v1681409661/dismiss-black-01_dcrtcj.png" /*: "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_16/v1681409661/dismiss-white-01_bidpml.png"*/}/>}
                    overrides={{
                        BaseButton: {
                            style: ({ $theme }) => ({
                                color: "black",
                                backgroundColor: $theme.colors.warning400,
                                ":hover": {
                                    backgroundColor: $theme.colors.warning500
                                },
                                ":focus": {
                                    backgroundColor: $theme.colors.warning300
                                },
                                ":active": {
                                    backgroundColor: $theme.colors.warning300
                                },
                            
                            })
                        },
                        StartEnhancer: {
                            style: ({ $theme }) => ({
                              marginRight: '3px'
                            })
                        }
                    }}
                >Dismiss</Button>
            </FlexGridItem>}
        </FlexGrid>
    };

    renderRequestedDrugs = () => {
        if (this.props.orders.length == 0) {
            return null;
        }

        let showNoPendingRequests = true;
        let drugs = this.props.requested_drugs.map((line_item, idx) => {
            if (!this.wasDrugPrescribed(line_item) && !this.wasDrugDismissed(line_item)) {
                showNoPendingRequests = false ;
                return this.renderBox(
                    line_item.id,
                    '#ffe6ff',
                    Utils.renderLocaleString(line_item.created_at),
                    line_item.product.display_name,
                    line_item.variant.quantity,
                    line_item.fulfillment_pharmacy_id !== null
                        ? line_item.fulfillment_pharmacy.name + ' - ' + line_item.fulfillment_pharmacy.address + ' - ' + line_item.fulfillment_pharmacy.phone
                        : this.fomoAddress(line_item)
                );
            } else {
                return null;
            }
        });

        if (showNoPendingRequests) {
            return <ParagraphMedium style={{margin: '0px', textAlign:'center'}}>No pending requests</ParagraphMedium>
        } else {
            return drugs;
        }
    };

    wasDrugPrescribed = (line_item) => {
        if (line_item.prescription_id) {
            return true;
        }

        // TODO Verify there will always be a variant object so this code does not raise exception
        let prescribedList = this.state.alreadyPrescribed.filter( prescription => prescription.variant_id == line_item.variant_id && prescription.change_drug_id == line_item.variant.change_drug_id);
        return prescribedList.length > 0;
    };

    wasDrugDismissed = (line_item) => {
        return line_item.request_status == 'dismissed' || this.state.dismissed.includes(line_item.id);
    };

    fomoAddress = (line_item) => {
        const found = this.props.orders.find(order => order.id == line_item.order_id);
        if (found && found.fomo_pharmacy) {
            return found.fomo_pharmacy.name + ' - ' + Utils.renderAddress(found.fomo_pharmacy) + ' - ' + found.fomo_pharmacy.phone;
        } else {
            return 'No FOMO address found.';
        }
    };

    render () {
        return (
            <AuthUserContext.Consumer>
                {authUser =>
                    (
                        <span>
                            {this.renderRequestedDrugs()}
                        </span>
                    )
                }
            </AuthUserContext.Consumer>
        );
    }
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(RequestDrugs);
