import React, {Component} from 'react';
import { withAuthorization } from '../../shared/Session';

import { Checkbox, LABEL_PLACEMENT, STYLE_TYPE } from "baseui/checkbox";
import { Input } from "baseui/input";
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { HeadingSmall, LabelLarge, LabelMedium, ParagraphSmall } from 'baseui/typography';
import { FormControl } from "baseui/form-control";
import content from './content';
import { Textarea } from "baseui/textarea";

class Optionals extends Component {
    constructor(props) {
        super(props);

        var onboarding_video_call = this.getTaskFor('onboarding_video_call');
        var onboarding_file_attach = this.getTaskFor('onboarding_file_attach');
        var drug_request_file_attach = this.getTaskFor('drug_request_file_attach');

        this.state = {
            content: this.getContent(),
            video_call_id: onboarding_video_call != null ? onboarding_video_call.id : null,
            video_call_enable: onboarding_video_call != null,
            video_call_url: onboarding_video_call != null ? onboarding_video_call.url : '',
            video_call_url_error: false,
            video_call_schedule: onboarding_video_call != null ? onboarding_video_call.task_description : '',
            onboarding_file_attachment_id: onboarding_file_attach != null ? onboarding_file_attach.id : null,
            onboarding_file_attachment_enable: onboarding_file_attach != null,
            onboarding_file_attachment_description: onboarding_file_attach != null ? onboarding_file_attach.task_description : '',
            drug_request_file_attachment_id: drug_request_file_attach != null ? drug_request_file_attach.id : null,
            drug_request_file_attachment_enable: drug_request_file_attach != null,
            drug_request_file_attachment_description: drug_request_file_attach != null ? drug_request_file_attach.task_description : ''
        }
    }

    getContent = () => {
        return content('en');
    };

    getTaskFor = (key) => {
        var tasks = this.props.protocol_tasks;
        for (var i = 0; i < tasks.length; i++) {
            if (tasks[i].context_type + '_' + tasks[i].task_type == key) {
                return tasks[i];
            }
        }

        return null;
    };

    callback = () => {
        var settings = this.prepareStateForCallback();
        this.props.callback(settings);
    };

    checkVideoUrl = (url) => {
        if (url != '' && !url.startsWith("https://") && !url.startsWith('http://')) {
            if(this.props.callbackError && !this.state.video_call_url_error){
                this.props.callbackError({message: "Invalid video URL"});
                this.state.video_call_url_error = true;
            }
            return "Invalid link. Must start with https:// or http://";
        } else {
            if (this.props.callbackError && this.state.video_call_url_error){
                this.props.callbackError(null);
            }
            this.state.video_call_url_error = false;
            return false;
        }
    };

    prepareStateForCallback = () => {
        var newState = [];
        var onboarding_video_call = this.prepareStateForVideoCallback();
        if (onboarding_video_call) {
            newState.push(onboarding_video_call);
        }

        var onboarding_file_attach = this.prepareStateForOnboardingFileAttachCallback();
        if (onboarding_file_attach) {
            newState.push(onboarding_file_attach);
        }

        var drug_reqeust_file_attach = this.prepareStateForDrugRequestFileAttachCallback();
        if (drug_reqeust_file_attach) {
            newState.push(drug_reqeust_file_attach);
        }

        return newState;
    };

    prepareStateForVideoCallback = () => {
            // Edit                     // New
        if (this.state.video_call_id || this.state.video_call_enable) {
            var result = {}

            result.context_type = 'onboarding';
            result.task_type = 'video_call';
            result.task_description = this.state.video_call_schedule;
            result.url = this.state.video_call_url;
            result.id = this.state.video_call_id;

            if (!this.state.video_call_enable) {
                result._destroy = true;
            }

            return result;
        }

        return null;
    };

    prepareStateForOnboardingFileAttachCallback = () => {
        // Edit                     // New
        if (this.state.onboarding_file_attachment_id || this.state.onboarding_file_attachment_enable) {
            var result = {}

            result.context_type = 'onboarding';
            result.task_type = 'file_attach';
            result.task_description = this.state.onboarding_file_attachment_description;
            result.url = '';
            result.id = this.state.onboarding_file_attachment_id;

            if (!this.state.onboarding_file_attachment_enable) {
                result._destroy = true;
            }

            return result;
        }

        return null;
    };

    prepareStateForDrugRequestFileAttachCallback = () => {
        // Edit                     // New
        if (this.state.drug_request_file_attachment_id || this.state.drug_request_file_attachment_enable) {
            var result = {}

            result.context_type = 'drug_request';
            result.task_type = 'file_attach';
            result.task_description = this.state.drug_request_file_attachment_description;
            result.url = '';
            result.id = this.state.drug_request_file_attachment_id;

            if (!this.state.drug_request_file_attachment_enable) {
                result._destroy = true;
            }

            return result;
        }

        return null;
    };

    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value}, () => {this.callback();});
    };

    handleVideoCallCheckBoxChange = (event) => { 
        this.setState({[event.target.name]: event.target.checked, video_call_url: '', video_call_schedule: ''}, () => this.callback());
    };

    renderOptionalVideoCall = () => {
        return <FlexGridItem>
            <FlexGrid>
                <FlexGridItem>
                    <Checkbox
                        name='video_call_enable'
                        checked={this.state.video_call_enable}
                        onChange={this.handleVideoCallCheckBoxChange}
                        labelPlacement={LABEL_PLACEMENT.right}
                        disabled={['pending'].includes(this.props.protocol_status)}
                        checkmarkType={STYLE_TYPE.toggle}
                        overrides={{
                            ToggleTrack: {
                              style: ({ $theme }) => ({
                                width:'100%',
                                maxWidth: '40px'
                              })
                            }
                          }}
                    ><LabelMedium style={{margin: 0}}>{this.state.content.optionals_video_call_title}</LabelMedium></Checkbox>
                </FlexGridItem>
                {/*<FlexGridItem><ParagraphSmall style={{margin: 0, marginLeft: '32px', color: '#666666', marginTop: '4px', marginBottom: '10px'}}>
                    {this.state.content.optionals_video_call_description}</ParagraphSmall></FlexGridItem>*/}
            </FlexGrid>
        </FlexGridItem>;
    };

    renderOptionalVideoCallFields = () => {
        if (this.state.video_call_enable) {
            return <FlexGridItem>
                <Input
                    overrides={{
                        Root: {
                            style: {
                                //marginLeft: '32px',
                                //width: '100%',
                                //maxWidth: '325px'
                                marginBottom: '10px'
                            }
                        }
                    }}
                    name='video_call_schedule'
                    value={this.state.video_call_schedule}
                    onChange={this.handleChange}
                    placeholder={this.state.content.optionals_video_call_availability_placeholder}
                    disabled={['pending'].includes(this.props.protocol_status)}
                />
                <Input
                        overrides={{
                            Root: {
                                style: {
                                    //marginLeft: '32px',
                                    //marginTop: '',
                                    //width: '100%',
                                    //maxWidth: '325px'
                                }
                            }
                        }}
                        name='video_call_url'
                        value={this.state.video_call_url}
                        onChange={this.handleChange}
                        placeholder={this.state.content.optionals_video_call_url_placeholder}
                        disabled={['pending'].includes(this.props.protocol_status)}
                    />
                
                {/*<LabelMedium style={{margin: 0}}>{this.state.content.optionals_video_call_url_title}</LabelMedium>
                <ParagraphSmall style={{margin: 0, color: '#666666', marginTop: '4px', marginBottom: '10px'}}>
                    {this.state.content.optionals_video_call_url_description}
                </ParagraphSmall>
                <FormControl
                    error={this.checkVideoUrl(this.state.video_call_url)}
                    overrides={{
                        Caption: {
                          style: ({ $theme }) => ({
                            //marginLeft: '32px',
                          })
                        }
                      }}
                >
                    <Input
                        overrides={{
                            Root: {
                                style: {
                                    //marginLeft: '32px',
                                    //marginTop: '',
                                    //width: '100%',
                                    //maxWidth: '325px'
                                }
                            }
                        }}
                        name='video_call_url'
                        value={this.state.video_call_url}
                        onChange={this.handleChange}
                        placeholder={this.state.content.optionals_video_call_url_placeholder}
                        disabled={['pending'].includes(this.props.protocol_status)}
                    />
                </FormControl>
                <LabelMedium style={{margin: 0, marginTop: '20px'}}>{this.state.content.optionals_video_call_availability}</LabelMedium>
                <ParagraphSmall style={{margin: 0, color: '#666666', marginTop: '4px', marginBottom: '10px'}}>
                    {this.state.content.optionals_video_call_availability_description}
                </ParagraphSmall>
                <Input
                    overrides={{
                        Root: {
                            style: {
                                //marginLeft: '32px',
                                //width: '100%',
                                //maxWidth: '325px'
                            }
                        }
                    }}
                    name='video_call_schedule'
                    value={this.state.video_call_schedule}
                    onChange={this.handleChange}
                    placeholder={this.state.content.optionals_video_call_availability_placeholder}
                    disabled={['pending'].includes(this.props.protocol_status)}
                />*/}
            </FlexGridItem>
        }
    };

    handleOnboardingFileAttachCheckBoxChange = (event) => {
        this.setState({[event.target.name]: event.target.checked, onboarding_file_attachment_description: ''}, () => this.callback());
    };

    renderOptionalOnboardingFileAttachment = () => {
        return <FlexGridItem>
            <Checkbox
                name='onboarding_file_attachment_enable'
                checked={this.state.onboarding_file_attachment_enable}
                onChange={this.handleOnboardingFileAttachCheckBoxChange}
                labelPlacement={LABEL_PLACEMENT.right}
                disabled={['pending'].includes(this.props.protocol_status)}
                checkmarkType={STYLE_TYPE.toggle}
                overrides={{
                    ToggleTrack: {
                        style: ({ $theme }) => ({
                        width:'100%',
                        maxWidth: '40px'
                        })
                    }
                }}
                >{this.state.content.optionals_onboarding_file_attach_name}</Checkbox>
                {/*<ParagraphSmall style={{margin: 0, marginLeft: '32px', color: '#666666', marginTop: '4px', marginBottom: '10px'}}>
                    {this.state.content.optionals_onboarding_file_attach_description}
                        </ParagraphSmall>*/}
        </FlexGridItem>;
    };

    renderOptionalOnboardingFileAttachmentFields = () => {
        if (this.state.onboarding_file_attachment_enable) {
            return <FlexGridItem>
                {/*<LabelMedium style={{margin: 0}}>{this.state.content.optionals_onboarding_file_attach_field_name}</LabelMedium>
                <ParagraphSmall style={{margin: 0, color: '#666666', marginTop: '4px', marginBottom: '10px'}}>
                    {this.state.content.optionals_onboarding_file_attach_field_description}
        </ParagraphSmall>*/}
                <Textarea
                    overrides={{
                        Root: {
                            style: {
                                //marginLeft: '32px',
                                //width: '100%',
                                //maxWidth: '325px'
                            }
                        }
                    }}
                    name='onboarding_file_attachment_description'
                    value={this.state.onboarding_file_attachment_description}
                    onChange={this.handleChange}
                    placeholder={this.state.content.optionals_onboarding_file_attach_field_placeholder}
                    disabled={['pending'].includes(this.props.protocol_status)}
                />
            </FlexGridItem>
        }
    };

    handleDrugRequestFileAttachCheckBoxChange = (event) => {
        this.setState({[event.target.name]: event.target.checked, drug_request_file_attachment_description: ''}, () => this.callback());
    };

    renderOptionalDrugRequestFileAttachment = () => {
        return <FlexGridItem>
            <Checkbox
                name='drug_request_file_attachment_enable'
                checked={this.state.drug_request_file_attachment_enable}
                onChange={this.handleDrugRequestFileAttachCheckBoxChange}
                labelPlacement={LABEL_PLACEMENT.right}
                disabled={['pending'].includes(this.props.protocol_status)}
                checkmarkType={STYLE_TYPE.toggle}
                overrides={{
                    ToggleTrack: {
                        style: ({ $theme }) => ({
                            width:'100%',
                            maxWidth: '40px'
                        })
                    }
                }}
                ><LabelMedium style={{margin:0}}>{this.state.content.optionals_drug_request_file_attach_name}</LabelMedium></Checkbox>
                {/*<ParagraphSmall style={{margin: 0, marginLeft: '32px', color: '#666666', marginTop: '4px', marginBottom: '10px'}}>
                    {this.state.content.optionals_drug_request_file_attach_name_description}
            </ParagraphSmall>*/}
        </FlexGridItem>;
    };

    renderOptionalDrugRequestFileAttachmentFields = () => {
        if (this.state.drug_request_file_attachment_enable) {
            return <FlexGridItem>
                {/*<LabelMedium style={{margin: 0}}>{this.state.content.optionals_drug_request_file_attach_field_name}</LabelMedium>
                <ParagraphSmall style={{margin: 0, color: '#666666', marginTop: '4px', marginBottom: '10px'}}>
                    {this.state.content.optionals_drug_request_file_attach_field_description}
        </ParagraphSmall>*/}
                <Textarea
                    overrides={{
                        Root: {
                            style: {
                                //marginLeft: '32px',
                                //width: '100%',
                                //maxWidth: '325px'
                            }
                        }
                    }}
                    name='drug_request_file_attachment_description'
                    value={this.state.drug_request_file_attachment_description}
                    onChange={this.handleChange}
                    placeholder={this.state.content.optionals_drug_request_file_attach_field_placeholder}
                    disabled={['pending'].includes(this.props.protocol_status)}
                />
            </FlexGridItem>
        }
    };

    renderTitle = () => {
        return <FlexGridItem>
            <HeadingSmall style={{margin: 0, marginBottom:"10px"}}>{this.state.content.optionals_title}</HeadingSmall>
            {/*<ParagraphSmall style={{margin: 0, color: '#666666'}}>{this.state.content.optionals_description}</ParagraphSmall>*/}
        </FlexGridItem>
    };

    render = () => {
        return (
            <FlexGrid flexGridRowGap={'scale600'}>
                {this.renderOptionalVideoCall()}
                {this.renderOptionalVideoCallFields()}
                {this.renderOptionalOnboardingFileAttachment()}
                {this.renderOptionalOnboardingFileAttachmentFields()}
                {this.renderOptionalDrugRequestFileAttachment()}
                {this.renderOptionalDrugRequestFileAttachmentFields()}
            </FlexGrid>
        );
    };
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(Optionals);
