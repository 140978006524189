// Header

(function() {
  var isShowingNav = function() {
    return App.$body.hasClass('is-showing-nav');
  };

  var closeNav = function() {
    App.$body.removeClass('is-showing-nav');
  };

  var openNav = function() {
    App.$body.addClass('is-showing-nav');

    $(document).one('click', function(e) {
      var $target = $(e.target);

      if ( !$target.closest('.nav').length && !$target.closest('.header__nav-button').length ) {
        closeNav();
      }
    });
  };

  var toggleNav = function() {
    if ( isShowingNav() ) {
      closeNav();
    } else {
      openNav();
    }
  };

  $(document).on('click', '.header__nav-button', function() {
    toggleNav();
  });

  $(document).on('click', '.close-nav-button', function() {
    closeNav();
  });
})();
