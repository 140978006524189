import React, {Component, useEffect, useState} from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Navigation from '../../shared/Navigation';
import HomePage from '../Home';

import * as ROUTES from '../Constants';
import AccountPage from '../Account'

import { ProtocolManagement, ProtocolView } from '../ProtocolManagement';

import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';

import {Client as Styletron} from 'styletron-engine-atomic';
import {Provider as StyletronProvider} from 'styletron-react';
import {LightTheme, BaseProvider, styled} from 'baseui';

// Shared
import SignInPage from '../../shared/SignIn';
import SignUpPage from '../../shared/SignUp';
import {withAuthentication} from '../../shared/Session'
import PasswordResetPage from '../../shared/Password/reset'
import Screen from "../../shared/Utils/screen";

const engine = new Styletron();

import './app.scss';
import '../../shared/Styles/style.scss';
import NotFound from '../../shared/NotFound';
import MFAEnroll from '../../shared/MFA/enroll';

import {createTheme, createDarkTheme, createLightTheme} from 'baseui';

import { useIdleTimer } from 'react-idle-timer'
import Request from '../../shared/Request';
import Logger from '../../shared/Utils/logger';

import { ErrorBoundary } from "react-error-boundary";
import { ParagraphLarge } from 'baseui/typography';
import { StyledLink } from 'baseui/link';

const primitives = {
  primaryFontFamily: 'RoobertRegular',
};

const overrides = {
    typography: {
        ParagraphXSmall: {fontFamily: 'RoobertRegular', fontWeight:"normal"},
        ParagraphSmall: {fontFamily: 'RoobertRegular', fontWeight:"normal"},
        ParagraphMedium: {fontFamily: 'RoobertRegular', fontWeight:"normal"},
        ParagraphLarge: {fontFamily: 'RoobertRegular', fontWeight:"normal"},

        LabelXSmall: {fontFamily: 'RoobertMedium', fontWeight:"normal"},
        LabelSmall: {fontFamily: 'RoobertMedium', fontWeight:"normal"},
        LabelMedium: {fontFamily: 'RoobertMedium', fontWeight:"normal"},
        LabelLarge: {fontFamily: 'RoobertMedium', fontWeight:"normal"},

        HeadingXSmall: {fontFamily: 'RoobertMedium', fontWeight:"normal"},
        HeadingSmall: {fontFamily: 'RoobertMedium', fontWeight:"normal"},
        HeadingMedium: {fontFamily: 'RoobertMedium', fontWeight:"normal"},
        HeadingLarge: {fontFamily: 'RoobertMedium', fontWeight:"normal"},
        HeadingXLarge: {fontFamily: 'RoobertMedium', fontWeight:"normal"},
        HeadingXXLarge: {fontFamily: 'RoobertMedium', fontWeight:"normal"},

        DisplayLarge: {fontFamily: 'RoobertSemiBold', fontSize: '52px', lineHeight: '56px', fontWeight:"normal"},
        DisplayMedium: {fontFamily: 'RoobertSemiBold', fontSize: '44px', lineHeight: '48px', fontWeight:"normal"},
        DisplaySmall: {fontFamily: 'RoobertSemiBold', fontSize: '40px', lineHeight: '44px', fontWeight:"normal"},
        DisplayXSmall: {fontFamily: 'RoobertSemiBold', fontSize: '20px', lineHeight: '28px', fontWeight:"normal"},
    },
};

//const theme = createTheme(primitives, overrides);

const getResponsiveSpacing = () => {
    let css = {};

    if (['SMALL', 'MEDIUM'].includes(Screen.size())) {
        css.marginTop = '0px';
        css.marginRight = '16px';
        css.marginBottom = '16px';
        css.marginLeft = '16px';
    } else {
        css.marginTop = '0px';
        css.marginRight = '24px';
        css.marginBottom = '24px';
        css.marginLeft = '24px';
    }

    return css;
};

const getResponsiveMarginBottom = () => {
    if (['SMALL', 'MEDIUM'].includes(Screen.size())) {
        return '16px';
    } else {
        return '40px';
    }
};

function App(props) {

    const handleOnIdle = (event) => {
        if(props.firebase.isUserOnline()){
            Request.get({
                firebase: props.firebase,
                route: ROUTES.BASE_URL_API + "/user_management/user_idle",
                callbackSuccess: result => {},
                callbackError: error => {
                    Logger.log('. . . . . . Could not mark user idle . . . . . . ');
                }
            });
        }
    }

    const handleOnActive = (event) => {
        if(props.firebase.isUserOnline()) {
            Request.get({
                firebase: props.firebase,
                route: ROUTES.BASE_URL_API + "/user_management/user_online",
                callbackSuccess: result => {},
                callbackError: error => {
                    Logger.log('. . . . . . Could not mark user online . . . . . . ');
                }
            });
        }
    }

    const handleOnAction = (event) => {}

    const { getRemainingTime, getLastActiveTime } = useIdleTimer({
        timeout: 1000 * 60 * 15,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 500
    })

    const execOnBeforeUnload = (event) => {

        event.preventDefault();
        event.returnValue = '';

        if(props.firebase.isUserOnline()) {
            Request.get({
                firebase: props.firebase,
                route: ROUTES.BASE_URL_API + "/user_management/user_offline",
                callbackSuccess: result => {},
                callbackError: error => {}
            });
        }

        return "Exiting...";
    };

    const [isDarkMode, setIsDarkMode] = useState(false);

    function Fallback({ error, resetErrorBoundary }) {
        // Call resetErrorBoundary() to reset the error boundary and retry the render.
      
        return (
          <div role="alert" style={{textAlign:"center"}}>
            <ParagraphLarge>Something went wrong...</ParagraphLarge>
            <ParagraphLarge style={{ color: "red", fontFamily:"bold"}}>{error.message}</ParagraphLarge>
            <ParagraphLarge>Contact support to report this issue:<br/>
            Text <StyledLink href="sms:1-646-389-5550" style={{color:"#00a1ff"}}>646-389-5550</StyledLink><br/>
            Message our <StyledLink href="https://www.reddit.com/r/clinicapp/" target="_blank" style={{color:"#00a1ff"}}>subbreddit</StyledLink><br/>
            Message our <StyledLink href="https://discord.gg/RkK77ajn24" target="_blank" style={{color:"#00a1ff"}}>discord</StyledLink>
            </ParagraphLarge>
          </div>
        );
      }
      

    useEffect(() => {

        if(props.firebase.isUserOnline()) {
            Request.get({
                firebase: props.firebase,
                route: ROUTES.BASE_URL_API + "/user_management/user_online",
                callbackSuccess: result => {},
                callbackError: error => {}
            });

            window.addEventListener('unload', execOnBeforeUnload);
        } else {
            window.removeEventListener('unload', execOnBeforeUnload);
        }

        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        setIsDarkMode(mediaQuery.matches);

        const listener = (event) => setIsDarkMode(event.matches);
        mediaQuery.addEventListener('change', listener);

        return () => {
            mediaQuery.removeEventListener('change', listener);
        };
    }, []);

    const theme = isDarkMode ? createDarkTheme(primitives, overrides) : createLightTheme(primitives, overrides);

    return <StyletronProvider value={engine}>
        <BaseProvider theme={theme}>
        <ErrorBoundary FallbackComponent={Fallback}>
        <div id="div1">
        <Router>
        <FlexGrid
            flexGridColumnCount={1}
            //flexGridColumnGap="scale800"
            
            >
            <FlexGridItem style={{maxWidth: ['XSMALL','SMALL'].includes(Screen.size()) ? 'calc(100vw - 32px)': '570px', width: '100%', marginLeft: 'auto', marginRight: 'auto'}}>
                <Navigation/>
            </FlexGridItem>
            <FlexGridItem /*style={getResponsiveSpacing()}*/>
                <Switch>
                    <Route exact path={ROUTES.SIGN_IN} component={SignInPage} />
                    <Route exact path={ROUTES.SIGN_UP} component={SignUpPage} />
                    <Route exact path={ROUTES.MFA_ENROLL} component={MFAEnroll} />

                    <Route exact path={ROUTES.ACCOUNT} component={AccountPage} />
                    <Route exact path={ROUTES.HOME} component={HomePage} />
                    <Route exact path={ROUTES.PASSWORD_RESET} component={PasswordResetPage} />
                    <Route exact path={ROUTES.PROTOCOLS_VIEW} component={ProtocolView} />
                    <Route exact path={ROUTES.PROTOCOLS_VIEW_ID} component={ProtocolView} />
                    <Route exact path={ROUTES.PROTOCOLS_NEW} component={ProtocolManagement} />
                    <Route exact path={ROUTES.PROTOCOLS_EDIT} component={ProtocolManagement} />
                    <Route exact path={ROUTES.PROTOCOLS_EDIT_ID} component={ProtocolManagement} />
                    <Route component={NotFound} />
                </Switch>
            </FlexGridItem>
        </FlexGrid>
        </Router>
        </div>
        </ErrorBoundary>
        </BaseProvider>
    </StyletronProvider>
};

export default withAuthentication(App);
