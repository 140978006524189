import React, {Component} from 'react';
import Iframe from 'react-iframe';
import { AuthUserContext, withAuthorization } from '../../../shared/Session';
import * as ROUTES from '../../Constants';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import {Button, KIND, SIZE, SHAPE} from 'baseui/button';
import Logger from '../../../shared/Utils/logger';
import {Spinner} from '../../../shared/Spinner';
import SyncPrescriptions from './sync_prescriptions';
import PrescriptionResults from './prescription_results';
import Utils from '../../../shared/Utils';

const leftProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                overflow:'hidden'
            }),
        },
    },
};

class RxPad extends Component {
    constructor(props){
        super(props);

        this.state = {
            isLoaded: false,
            error: null,
            syncing: false,
            show_prescription_results: false,
            prescription_results: null
        };
    }

    componentDidMount = () => {
        this.getRxPad();
    }

    getRxPad = () => {
        this.setState({isLoaded: false});
        this.props.firebase.currentUser().getIdToken(false).then((token) => {

            var orderUrlFragments = this.orderUrlFragments();

            var fetches = orderUrlFragments.map(orderUrlFrag => {

                return fetch(
                    //TODO this needs to send an ordernumber along or if more than one, we want to hit it multiple times
                    //that way all the fomo_pharmacies get loaded.
                    ROUTES.BASE_URL_API + "/physician_patient/health_change_login/" + this.props.uuid + orderUrlFrag, {headers: {
                        "Authorization" : `Bearer ${token}`
                    }})
                    .then(res => res.json())
                    .then(
                        (result) => {
                        },
                        (error) => {
                            this.setState({
                                error
                            });
                        }
                    );
            });
            Promise.all(fetches).then((result) => {
                this.setState({isLoaded: true});
            });
        })
    };

    orderUrlFragments = () => {
        var orderNumbers = this.orderNumbers();
        if (orderNumbers.length == 0) {
            return [''];
        } else {
            return orderNumbers.map(orderNumber => '/' + orderNumber);
        }
    }


    orderNumbers = () => {
        return this.props.orders.map(order => order.number);
    }

    refresh = () => {
        this.getRxPad();
    };

    closePrescriptionResults = (e) => {
        this.setState({show_prescription_results: false});
    };

    // Executed on prescription conflict acceptance
    finishedPrescribing = () => {
        this.setState({show_prescription_results: false});
        if (this.props.closeRx) {
            this.props.closeRx(this.state.prescription_results);
        }
    };

    // Called on Close Rx button click
    executeDone = () => {
        this.setState({syncing: true});

        let synced_prescriptions = new SyncPrescriptions(
            {
                firebase: this.props.firebase,
                patient_uuid: this.props.uuid,
                orders: this.props.orders,
                protocol_subscription: this.props.protocol_subscription
            }
        );

        let syncPromise = synced_prescriptions.run();
        syncPromise.then((result) => {
            if (result.isError()) {
                this.setState({error: {message: result.results.error.message}, syncing: false});
            } else {
                if(result.isEverythingCorrect()){
                    if (this.props.closeRx) {
                        this.props.closeRx(result.getResults());
                    }
                } else {
                    this.setState({
                        show_prescription_results: true,
                        syncing: false,
                        prescription_results: result.getResults()
                    });
                }
            }
        }).catch(reson => {
            this.setState({error: {message: result.results.error}, syncing: false});
        }) 
    };

    render () {
        const isLoaded = this.state.isLoaded;
        let iframe;

        if (isLoaded) {
            Logger.log('Loading iframe...');
            iframe =  <Iframe url={this.props.rx_pad}
            width="970px"
            height="780px"
            id="rx_pad"
            display="initial"
            position="relative"
            scrolling="yes"
            overflow="scroll"
            styles={{}}
            backgroundColor="#f6f6f6"
            frameBorder="0"/>;
        } else {
            iframe = <Spinner />
        }

        return (
            <AuthUserContext.Consumer>
                {authUser =>
                    (
                        <>
                        {Utils.renderError(this.state.error)}
                        <FlexGrid>
                            <FlexGridItem {...leftProps}>
                                <FlexGrid flexGridColumnCount={2} flexGridColumnGap="scale0">
                                    <FlexGridItem>
                                        <Button
                                            //style={{marginTop: '5px'}}
                                            onClick={this.executeDone}
                                            kind={KIND.primary}
                                            shape={SHAPE.pill}
                                            isLoading={this.state.syncing}
                                            size={SIZE.compact}
                                            startEnhancer={() => <img style={{height:'16px'}} src={/*Storage.get('theme') == 'light' ?*/ "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_16/v1681409571/close-white-01_zczjmo.png" /*: "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_16/v1681409571/close-black-01_nrtbuz.png"*/}/>}
                                            overrides={{
                                                StartEnhancer: {
                                                    style: ({ $theme }) => ({
                                                    marginRight: '4px'
                                                    })
                                                }
                                            }}
                                            >Close</Button>
                                    </FlexGridItem>
                                    <FlexGridItem>
                                        <Button
                                            //style={{marginTop: '5px'}}
                                            onClick={this.refresh}
                                            kind={KIND.secondary}
                                            shape={SHAPE.pill}
                                            size={SIZE.compact}
                                            startEnhancer={() => <img style={{height:'18px'}} src={/*Storage.get('theme') == 'light' ?*/ "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_18/v1681409502/refresh-black-01_yi8lx2.png" /*: "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_18/v1681409502/refresh-white-01_i1grnb.png"*/}/>}
                                            overrides={{
                                                StartEnhancer: {
                                                    style: ({ $theme }) => ({
                                                    marginRight: '4px'
                                                    })
                                                }
                                            }}
                                            >Refresh</Button>
                                    </FlexGridItem>
                                </FlexGrid>
                            </FlexGridItem>
                            <FlexGridItem style={{overflow:'auto'}}><div style={{transform: this.props.transform, transformOrigin: '0 0'}}>{iframe}</div></FlexGridItem>
                        </FlexGrid>
                        {this.state.show_prescription_results ? <PrescriptionResults
                            protocol_subscription={this.props.protocol_subscription}
                            prescribed_list={this.state.prescription_results.prescribed_list}
                            not_prescribed_list={this.state.prescription_results.not_prescribed_list}
                            warning_list={this.state.prescription_results.warning_list}
                            error_list={this.state.prescription_results.error_list}
                            callback={this.finishedPrescribing}
                            callbackCancel={this.closePrescriptionResults}
                        /> : null}
                        </>
                    )
                }
            </AuthUserContext.Consumer>
        );
    }
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(RxPad);
