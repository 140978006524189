import React, {Component} from 'react';
import { AuthUserContext, withAuthorization } from '../../../shared/Session';
import { StyledLink } from "baseui/link";
import Utils from '../../../shared/Utils';
import { Accordion, Panel } from "baseui/accordion";
import Logger from '../../../shared/Utils/logger';
import * as ROUTES from '../../Constants';
import Request from '../../../shared/Request';
import { ParagraphMedium, LabelMedium, HeadingXSmall, LabelLarge } from 'baseui/typography';

const narrowItemProps = {
    //backgroundColor: 'mono300',
    overrides: {
        Block: {
            style: ({$theme}) => ({
                width: '200px',
                flexGrow: 0,
            }),
        },
    },
};

class FormResponses extends Component {
    constructor(props){
        super(props);

        this.state = {
            form_id: '',
            form_responses: this.props.responses.sort((a, b) => {return a.created_at > b.created_at ? -1 : 1})
        };
    }

    componentDidMount = () => {
        const data = new FormData();

        this.props.responses.map(form_response => {
            if (!form_response.seen_by_provider) {
                data.append('id[]', form_response.id);
            }
        })

        Request.post({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/physician_form_response/seen_by_provider",
            body: data,
            callbackSuccess: result => {},
            callbackError: error => {}
        })
    };


    changeForm = (event) => {
        event.preventDefault();
        this.setState({form_id: event.target.id});
    };

    renderFormOptions = () => {
        return this.state.form_responses.map((response, idx) => <LabelMedium style={{margin: 0}} key={idx}><StyledLink href="#" id={idx} onClick={this.changeForm}>{response.form.name} - {Utils.toLocaleString(response.updated_at)}</StyledLink>{Utils.renderSpace()}</LabelMedium>);
    };

    renderFormresponses = () => {
        if(this.state.form_id) {
            var form = this.state.form_responses[this.state.form_id];
            return form.results.questionResponses.map((response, idx) => <div key={idx} style={{marginBottom: '10px'}}>
                <div style={{background: '#f2f2f2'}}>{response.questionText}</div>
                {Utils.renderSpace()}
                {this.renderResponse(response.responseText, response.responseFlag)}
                <hr/>
            </div>);
        }
    };

    renderResponse = (responseText, responseFlag) => {
        return responseText.map((response, idx) => {
            var style = {};
            style.margin = 0;
            style.marginLeft = '35px';
            style.marginTop = '15px';
            style.flex = 1;
            style.flexWrap = 'wrap';

            return <ParagraphMedium style={style} key={idx}> { responseFlag && responseFlag[idx] ? <span style={{fontSize: '24px'}}>🚫</span> : ''} {response}</ParagraphMedium>
        })
    };

    renderQuestionnareResponse = () => {
        return <Accordion
            overrides={{
                Content: {
                    style: ({ $theme }) => ({
                      backgroundColor: 'transparent'
                    })
                  },
            }}
        >
        {this.state.form_responses.map((response, idx) => <Panel
            key={idx}
            title={<HeadingXSmall style={{margin: 0}}>{(response.form.form_type == 'drug_request_form' ? 'Drug request' : 'Onboarding request') + ' - ' + Utils.renderLocaleString(response.created_at)}</HeadingXSmall>}
            >{response.results.questionResponses.map((res, idx) => <div key={idx} style={{marginBottom: '10px'}}>
            <LabelLarge style={{margin: 0, marginTop: (idx == 0 ? '15px' : '30px')}}>{idx+1}. {res.questionText}</LabelLarge>
            {this.renderResponse(res.responseText, res.responseFlag)}
        </div>)}</Panel>)}
    </Accordion>
    };

    render = () => {
        return <AuthUserContext.Consumer>{authUser => this.renderQuestionnareResponse()}</AuthUserContext.Consumer>
    };
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(FormResponses);
