import React from "react";
import PropTypes from "prop-types";
import {Button, SIZE, KIND, SHAPE} from 'baseui/button';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import Utils from '../components/shared/Utils';
import InputSelect from '../components/shared/Utils/input_select';
import Delete from 'baseui/icon/delete';
import Logger from "./shared/Utils/logger";

const narrowItemProps = {
  overrides: {
      Block: {
          style: ({$theme}) => ({
              width: '30px',
              flexGrow: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
          }),
      },
  },
};

const centerProps = {
  overrides: {
      Block: {
          style: ({$theme}) => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }),
      },
  },
};

class ReportedValueSelector extends React.Component {
  constructor(props) {
    super(props);

    this.url = props.url;
    this.reportedValueType = props.reportedValueType;
    this.index = props.index;
    this.html_id = this.reportedValueType + this.index
    this.state = { reportedValue: this.props.reportedValue, deleted: this.props.reportedValue._destroy, disabled: this.props.disabled };
    this.textInput = React.createRef();
    this.severityInput = React.createRef();
    this.onChangeHandler = props.onChangeHandler;

    this.deleteCondition = (e) => {
        //remove me and remove this item from state
        this.setState({deleted: true});

        if (this.state.reportedValue.id) {
            this.onChangeHandler(this.index, { id: this.state.reportedValue.id, details: this.state.reportedValue.details, short_name: this.state.reportedValue.short_name, _destroy: true});
        } else {
            this.onChangeHandler(this.index, { id: this.state.reportedValue.id, details: this.state.reportedValue.details, short_name: '', _destroy: true});
        }
    };

    this.handleChange = (e) => {
      Logger.log('Change occured....');
      Logger.log(e)
      if (this.textInput.current) {
        Logger.log("---- Text Input is current...");
        Logger.log('Allergy type: ' + this.state.reportedValue.type);
        var autocomp = this.textInput.current.autocomp;
        var itemData = autocomp.getSelectedItemData();
        if (itemData) {
          Logger.log("---- Item data found...");
          var selectedData = itemData[0]['data'];
          Logger.log('Allergy type: ' + this.state.reportedValue.type);
          if (this.state.reportedValue.type == 'ReportedAllergy' && e.target && e.target.type == 'select') {
            Logger.log('Settings severity to ' + e.target.value);
            selectedData['severity'] = e.target.value;
          }
          var val = {
            short_name: itemData[0]['text'],
            details: JSON.stringify(selectedData),
            type: this.state.reportedValue.type
          };
          if (this.state.reportedValue.id) {
            val['id'] = this.state.reportedValue.id;
          }

          this.onChangeHandler(this.index, val);
        } else if (this.state.reportedValue.type == 'ReportedAllergy' && e.target && e.target.type == 'select') {
          let newSeverity = e.target.value;
          let val = this.state.reportedValue;
          let details = JSON.parse(val.details);

          details.severity = newSeverity;
          val.details = JSON.stringify(details);

          this.onChangeHandler(this.index, val);
        }
      }
    };

    this.handleBlur = (event) => {
      Logger.log('Bluring....');
      event.preventDefault();
      event.persist(); // Keeps the event as is to be passed on to the next method

      this.handleChange(event);
    };
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.disabled !== prevProps.disabled) {
      this.setState({disabled: this.props.disabled});
    }
    if (this.props.reportedValue.id !== prevProps.reportedValue.id) {
        this.setState({reportedValue: this.props.reportedValue});

    }
  };

  render() {
    if (this.state.deleted) {
      return(null);
    } else if (this.state.reportedValue.type == 'ReportedAllergy') {
      var details = this.state.reportedValue;
      if (this.state.reportedValue.details){
        details = JSON.parse(this.state.reportedValue.details);
      }

      Logger.log("details...");
      Logger.log(details);
      return (
        <div>
          <FlexGrid flexGridColumnCount={2} flexGridColumnGap="scale200" flexGridRowGap="scale100" marginBottom={"scale800"}>
            <FlexGridItem {...centerProps}>
              <input
                type="text"
                id={this.html_id}
                ref={this.textInput}
                defaultValue={this.state.reportedValue.short_name}
                placeholder={this.props.placeHolder}
                className="reported_value"
              />
            </FlexGridItem>
            <FlexGridItem {...narrowItemProps}>
              <Button onClick={this.deleteCondition.bind(this)} shape={SHAPE.circle} kind={KIND.tertiary} size={SIZE.compact}>
                <Delete size={24} />
              </Button>
            </FlexGridItem>
            <FlexGridItem>
              <InputSelect
                id={this.html_id + 'severity'}
                name="severity"
                value={details.severity}
                onChange={this.handleChange.bind(this)}
                options={[{id: "Mild", label: "Mild"}, {id: "Moderate", label: "Moderate"}, {id: "Severe", label: "Severe"}]}
                placeholder="Select Allergy Severity"
              />
            </FlexGridItem>
            <FlexGridItem display="none"></FlexGridItem>
          </FlexGrid>
        </div>
      );
    } else {
      return (
        <div>
          <FlexGrid flexGridColumnCount={2} flexGridColumnGap="scale200" flexGridRowGap="scale800" >
            <FlexGridItem {...centerProps}>
            <input
          type="text"
          id={this.html_id}
          ref={this.textInput}
          defaultValue={this.state.reportedValue.short_name}
          placeholder={this.props.placeHolder}
          className="reported_value"/>
            </FlexGridItem>
            <FlexGridItem {...narrowItemProps}>
              <Button onClick={this.deleteCondition.bind(this)} shape={SHAPE.circle} kind={KIND.tertiary} size={SIZE.compact}>
                <Delete size={24} />
              </Button>
            </FlexGridItem>
          </FlexGrid>
          {Utils.renderSpace()}
        </div>
      );
    }
  };

  componentDidMount = () => {
    if (!this.state.deleted) {
      new Def.Autocompleter.Search(this.html_id, this.url);
      Def.Autocompleter.Event.observeListSelections(this.html_id, this.handleChange.bind(this));
    }
  };

}

ReportedValueSelector.propTypes = {
  url: PropTypes.string,
  index: PropTypes.number,
  onChangeHandler: PropTypes.func,
  reportedValue: PropTypes.object,
  placeHolder: PropTypes.string
};
export default ReportedValueSelector;



