// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

//import './bootstrap_custom.js'
// import '../stylesheets/application'
import '../../assets/javascripts/address_and_payment.js'

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

// Frontend partial scripts
import '../../assets/javascripts/frontend/vendor/jquery.easing.1.3'
import '../../assets/javascripts/frontend/vendor/jquery.transit'
import '../../assets/javascripts/frontend/main'
import '../../assets/javascripts/frontend/partials/patient_chat_test'
import '../../assets/javascripts/frontend/partials/show_more_buttons'
import '../../assets/javascripts/frontend/partials/auto_submit_forms'
import '../../assets/javascripts/frontend/partials/file_upload_image_preview'
import '../../assets/javascripts/frontend/partials/accordions'
import '../../assets/javascripts/frontend/partials/external_links'
import '../../assets/javascripts/frontend/partials/product_forms'
import '../../assets/javascripts/frontend/partials/header'
import '../../assets/javascripts/frontend/partials/show_panel_menu'
import '../../assets/javascripts/frontend/partials/show_dash_panel_pages'
import '../../assets/javascripts/frontend/partials/reported_values'


