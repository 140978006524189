import React, {Component} from 'react';
import Question from './question';
import { Button, KIND, SHAPE, SIZE } from "baseui/button";
import Utils from '../../shared/Utils';
import MpsModal from '../../shared/Utils/modal';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import {List} from "baseui/dnd-list";
import {StatelessAccordion, Panel} from 'baseui/accordion';
import Logger from '../../shared/Utils/logger';

class ProtocolForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            edit: 0,
            open: [],
            show: true,
            errors: [],
            success: null,
            form_title: this.props.title,
            protocol_id: this.props.protocol_id ? this.props.protocol_id : null,
            form_id: this.props.form_id ? this.props.form_id : null,
            form: this.props.data
        };
    }

    callback = () => {
        this.setState({errors: []}, () => {
            if (this.validateForm()) {
                this.props.callback(this.state.form);
                //this.setState({show: false});
            }
        });
    };

    validateForm = () => {
        let valid = true;
        this.state.form.forEach(question => {
            if (['multi', 'single', 'text'].indexOf(question.questionType) < 0) {
                valid = false;
                this.setState(prevState => ({
                    errors: [...prevState.errors, {message: 'A question is missing a Response Type'}]
                }));
            }
            if (['multi', 'single'].indexOf(question.questionType) >= 0 &&
                question.responseChoices.length < 1) {
                valid = false;
                this.setState(prevState => ({
                    errors: [...prevState.errors, {message: "A question doesn't have  enough responses"}]
                }));
            }
            if (question.questionText.length <= 0) {
                valid = false;
                this.setState(prevState => ({
                    errors: [...prevState.errors, {message: 'A question is missing text'}]
                }));
            }
        });
        return valid;
    }


    closeModal = () => {
        this.props.callbackCancel();
        this.setState({show: false});
    };

    questionHasBeenUpdated = (question, index) => {
        this.setState((prevState) => {
            Logger.log('in questionHasBeenUpdated');
            let new_form = [...prevState.form];
            new_form[index] = question;
            return {
                form: new_form
            }
        });
    };

    deleteQuestion = (event, id) => {
        let form = [...this.state.form]
        form.splice(id, 1);
        this.setState({form: form});
    };

    newQuestion = (event) => {
        let count = this.state.form.length;
        this.setState((prevState) => ({
            form: [...prevState.form, {id: Utils.uuid(), questionText: '', questionType: '', responseChoices:[]}],
            open: [count.toString()],
            edit: 1
        }));
    };

    editUp = (key, item) => {
        Logger.log("Key: " + key);
        this.setState({edit: 1, open: [key]}, () => {Logger.log("New edit value: " + this.state.edit + " and open: " + this.state.open); Logger.log(this.state.open)});
    };

    editDown = (key, item) => {
        Logger.log("Key: " + key);
        // TODO remove the correct key
        this.setState({edit: 0, open: []}, () => {Logger.log("New edit value: " + this.state.edit + " and open: " + this.state.open); Logger.log(this.state.open)});
    };

    renderQuestionDndList = () => {
        let items = this.renderQuestions();
        if (this.state.edit == 0) {
            return <List
                items={items}
                onChange={({ oldIndex, newIndex }) => newIndex === -1
                    ? this.deleteQuestion(null, oldIndex)
                    : this.moveQuestion(oldIndex, newIndex)
                }
            />
        } else {
            return items;
        }
    };

    moveQuestion = (oldIndex, newIndex) => {
        let deleted = this.state.form.splice(oldIndex, 1);
        let moved = this.state.form.splice(newIndex, 0, deleted[0]); // Returns [] on success
        this.setState({form: this.state.form, count: this.state.count + 1});
        // Insert element: arr.splice(index, 0, item);
        // Remove element: arr.splice(index, 1);
    };

    renderQuestions = () => {
        return this.state.form.map((question, idx) => <StatelessAccordion
            overrides={{
                Content: {
                    style: ({ $theme }) => ({
                        backgroundColor: 'transparent'
                    })
                }
            }}
            key={idx}
            expanded={this.state.open}
            onChange={({key, expanded}) => {expanded.length == 0 ? this.editDown(key, expanded) : this.editUp(key, expanded)}}
        >
            <Panel title={question.questionText ? question.questionText : "Enter new question below."} key={idx}>
                <FlexGrid key={question.id}>
                    <FlexGridItem>
                        <Question
                            key={idx}
                            id={question.id}
                            index={idx}
                            questionText={question.questionText}
                            questionType={question.questionType}
                            responseChoices={question.responseChoices}
                            callback={this.questionHasBeenUpdated}
                        />
                    </FlexGridItem>
                    <FlexGridItem>
                        <Button
                            style={{float: 'right'}}
                            id={idx}
                            kind={KIND.secondary}
                            shape={SHAPE.pill}
                            onClick={(event) => this.deleteQuestion(event, idx)}
                            overrides={{
                                BaseButton: {
                                    style: ({ $theme }) => ({
                                        backgroundColor: $theme.colors.negative,
                                        color: $theme.colors.white
                                    })
                                }
                            }}
                        >Delete Question</Button>
                    </FlexGridItem>
                </FlexGrid>
            </Panel>
        </StatelessAccordion>)
    };

    renderAddQuestionButton = () => {
        return <div style={{textAlign: 'center', marginTop: '25px'}}>
            <Button
                shape={SHAPE.pill}
                size={SIZE.default}
                onClick={this.newQuestion}
            >Add Question</Button></div>
    };

    renderActionButtons = () => {
        return <div>
            {Utils.renderSpace()}
            <div style={{margin: 'auto', width: '100px'}}><Button style={{width: '100%'}} onClick={this.saveForm}>Save</Button></div>
            {Utils.renderSpace()}
            <div style={{margin: 'auto', width: '100px'}}><Button style={{width: '100%'}} onClick={this.cancelForm}>Cancel</Button></div>
        </div>
    };

    render = () => {
        return ( <MpsModal
                open={this.state.show}
                title={Utils.renderTitleCenter(this.state.form_title)}
                callback={this.callback}
                callbackCancel={this.closeModal}
                callbackButton={'Save'}
                callbackCancelButton={'Exit'}
                withFooter={true}
                fullscreen={true}
                body={
                    <div style={{width: '100%'}}>
                        {Utils.renderErrorMulti(this.state.errors)}
                        {Utils.renderSuccess(this.state.success)}
                        {this.renderQuestionDndList()}
                        {this.renderAddQuestionButton()}
                    </div>
                }
            />
        );
    };
}

// TODO User must be authorized
export default ProtocolForm;
