import React, {Component} from 'react';
import * as ROUTES from '../../Constants';
import { AuthUserContext, withAuthorization } from '../../../shared/Session';
import { Button, KIND, SHAPE, SIZE } from "baseui/button";
import Request from '../../../shared/Request';
import MpsModal from '../../../shared/Utils/modal';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { ParagraphMedium } from 'baseui/typography';
import Utils from '../../../shared/Utils';
import { Textarea } from 'baseui/textarea';
import Storage from '../../../shared/Storage';

class BanPatient extends Component{
    constructor(props){
        super(props);

        this.state = {
            isLoaded: false,
            error: null,
            showModal: false,
            loading: false,
            ban_reason: '',
            show_component: ['active', 'active_pending_cancel'].includes(this.props.protocol_subscription_status),

            title: 'Kick patient?',
            url: ROUTES.BASE_URL_API + "/physician_patient_action/deactivate_subscription/" + this.props.patient_uuid,
        }

        this.deactivateSubscriptionAction = 'Kick';
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.protocol_subscription_status !== prevProps.protocol_subscription_status) {
            this.setState({
                show_component: ['active', 'active_pending_cancel'].includes(this.props.protocol_subscription_status),
                error: null
            });
        } else {
            this.state.error = null;
        }
    };

    handleModalClose = () => {
        this.setState({showModal: false});
    }

    handleActionComplete = () => {
        this.handleSubmit();
    }

    presentModal = () => {
        this.setState({
            showModal: true,
        });
    }

    handleChange = (event) => {
        this.setState({ban_reason: event.target.value});
    };

    handleSubmit = () => {
        const data = new FormData();

        data.append('reject_reason', this.state.ban_reason);
        data.append('protocol_id', this.props.protocol_number);

        Request.put({
            firebase: this.props.firebase,
            route: this.state.url,
            body: data,
            callbackSuccess: (result) => {
                this.setState({showModal: false});
                if (this.props.callbackSuccess) {
                    this.props.callbackSuccess();
                }

                if (this.props.callbackSubscriptionStatus) {
                    this.props.callbackSubscriptionStatus(this.props.protocol_subscription.id, 'dead');
                }
            },
            callbackError: (error) => {
                this.setState({
                    isLoaded: true,
                    error
                })
            }
        })
    }

    isProtocolActive = () => {
        return this.props.protocol && this.props.protocol.status === 'active';
    };

    renderItems = () => {
        return this.isProtocolActive() ? <Button
            shape={SHAPE.pill}
            size={SIZE.compact}
            kind={KIND.secondary}
            isLoading={this.state.loading}
            onClick={(e) => this.presentModal()}
            startEnhancer={() => <img style={{height:'16px'}} src={/*Storage.get('theme') == 'light' ?*/ "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_16/v1681361349/kick-black-01_kjgze9.png" /*: "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_16/v1681361349/kick-white-01_v996ik.png"*/}/>}
            overrides={{
                BaseButton: {
                    style: ({ $theme }) => ({
                        color: "black",
                        backgroundColor: "#ec8e3b",
                        ":hover": {
                            backgroundColor: "#e87d1e"
                        },
                        ":focus": {
                            backgroundColor: "#e69650"
                        },
                        ":active": {
                            backgroundColor: "#e69650"
                        },
                    })
                },
                StartEnhancer: {
                    style: ({ $theme }) => ({
                      marginRight: '4px'
                    })
                }
            }}
        >{this.deactivateSubscriptionAction}</Button> : <Button
            shape={SHAPE.pill}
            size={SIZE.compact}
            kind={KIND.secondary}
            disabled={true}
            startEnhancer={() => <img style={{height:'16px'}} src={/*Storage.get('theme') == 'light' ? "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_16/v1681361349/kick-black-01_kjgze9.png" :*/ "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_16/v1681361349/kick-white-01_v996ik.png"}/>}
            overrides={{
                StartEnhancer: {
                    style: ({ $theme }) => ({
                      marginRight: '4px'
                    })
                }
            }}
        >{this.deactivateSubscriptionAction}</Button>
    };

    render() {
        return (
            <AuthUserContext.Consumer>
                {authUser =>
                    (
                        this.state.show_component ?
                        <span>
                            {this.state.showModal
                            ? <MpsModal
                                open={this.state.showModal}
                                title={Utils.renderTitleCenter(this.state.title)}
                                callbackCancel={this.handleModalClose}
                                callbackCancelButton={'Go back'}
                                callback={this.handleActionComplete}
                                callbackButton={'Kick'}
                                disabled={!this.state.ban_reason}
                                withFooter={true}
                                body={<FlexGrid>
                                    <FlexGridItem>{Utils.renderError(this.state.error)}</FlexGridItem>
                                    <FlexGridItem style={{textAlign: 'center', marginBottom:'30px'}}><ParagraphMedium style={{margin: 0, margin:'auto', width: '90%'}}>{`Are you sure you want to kick this patient? They will be removed from the clinic and refunded their latest subscription payment.`}</ParagraphMedium></FlexGridItem>
                                    <FlexGridItem>
                                        <Textarea
                                            id="ban_reason"
                                            name="ban_reason"
                                            placeholder='Reason for kick'
                                            value={this.state.ban_reason}
                                            onChange={this.handleChange}
                                            overrides={{
                                                Input: {
                                                    style: {
                                                        maxHeight: '300px',
                                                        minHeight: '100px',
                                                        minWidth: '300px',
                                                        width: '100vw', // fill all available space up to parent max-width
                                                        resize: 'both',
                                                    },
                                                },
                                                InputContainer: {
                                                    style: {
                                                        maxWidth: '100%',
                                                        width: 'min-content',
                                                    },
                                                },
                                            }}
                                        />
                                    </FlexGridItem>
                                </FlexGrid>
                                }
                            />
                            : null}

                            {this.renderItems()}
                        </span>
                        : null
                    )
                }
            </AuthUserContext.Consumer>
        );
    }
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(BanPatient);
