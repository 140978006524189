
$(document).ready(function(){

  var counter = 0;

  $('#add_condition_button').click(function(e) {
    e.preventDefault();
    e.stopPropagation();
    var cs = $('#condition_select').clone();
    cs.removeAttr('hidden');
    cs.addClass('reported_value_input');
    cs.attr('id', 'condition' + counter);
    cs.attr('data-index', counter);
    cs.insertBefore($('#submit_button'));

    new Def.Autocompleter.Search('condition'+counter,
      'https://clinicaltables.nlm.nih.gov/api/conditions/v3/search?ef=primary_name,consumer_name,key_id,icd10cm_codes,icd10cm,term_icd9_code,term_icd9_text,word_synonyms,synonyms,info_link_data');
    counter += 1;
  });

  $('#add_medication_button').click(function(e) {
    e.preventDefault();
    e.stopPropagation();
    var cs = $('#medication_select').clone();
    cs.removeAttr('hidden');
    cs.addClass('reported_value_input');
    cs.attr('id', 'medication' + counter);
    cs.attr('data-index', counter);
    //TODO add reported val type data_item here
    cs.insertBefore($('#submit_button'));


    //this needs to look up via change drug search insteaad so we get the id
    //
    // 'https://clin-table-search.lhc.nlm.nih.gov/api/rxterms/v3/search?ef=RXCUIS,FULL_NAME,PSN,FULL_GENERIC_NAME,DISPLAY_NAME_SYNONYM,GENERIC_RXCUI');
    new Def.Autocompleter.Search('medication'+counter,
      '/reported_values/drug_lookup');
    counter += 1;
  });

  $('#add_allergy_button').click(function(e) {
    e.preventDefault();
    e.stopPropagation();
    var cs = $('#allergy_select').clone();
    cs.removeAttr('hidden');
    cs.addClass('reported_value_input');
    cs.attr('id', 'allergy' + counter);
    cs.attr('data-index', counter);
    //TODO add reported val type data_item here
    cs.insertBefore($('#submit_button'));

    new Def.Autocompleter.Search('allergy'+counter,
      '/reported_values/allergy_lookup');
    counter += 1;
  });


  $('#report_form').submit(function (e) {
    $('.reported_value_input').each (function(index, reported_val) {
      var autocomp = reported_val.autocomp;
      var itemData = autocomp.getSelectedItemData();
      var counter = $(reported_val).attr('data-index');
      var rv_type = $(reported_val).attr('data-value-type');
      if (itemData && itemData[0]) {
        $('#report_form').append("<input type='hidden' name='reported_values[" + counter + "][short_name]' value='" + itemData[0]['text'] + "'>")
        $('#report_form').append("<input type='hidden' name='reported_values["+ counter +"][details]' value='" + JSON.stringify( itemData[0]['data']) + "'>")
        $('#report_form').append("<input type='hidden' name='reported_values["+ counter +"][type]' value='" + rv_type + "'>")
      }
    });
    return true;
  });



});


