import React, {Component} from 'react';

import MedicalInfo from './medical_info';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import RxPad from './Rx';
import * as ROUTES from '../Constants';
import { Button, SIZE, SHAPE, KIND } from 'baseui/button';
import {ButtonGroup, SIZE as BGSize, SHAPE as BGShape, MODE} from 'baseui/button-group';
import Utils from '../../shared/Utils';
import Request from '../../shared/Request';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import Logger from '../../shared/Utils/logger';
import {Spinner} from '../../shared/Spinner';
import Screen from  '../../shared/Utils/screen';
import { LabelLarge, LabelMedium, ParagraphMedium, ParagraphLarge, HeadingXSmall, ParagraphSmall, HeadingMedium, LabelSmall, ParagraphXSmall, HeadingSmall } from 'baseui/typography';
import content from './content';
import MpsModal from '../../shared/Utils/modal';
import RequestDrugs from './Note/request_drugs';
import { Drawer, SIZE as DrawerSize, ANCHOR  } from "baseui/drawer";
import EncounterNote from './Note/encounter_note';
import MessagePatient from './Messages/message_patient';
import AttachmentButton from './AttachmentButton/attachment_button';
import { RejectPatient, BanPatient, Accept } from './Actions';
import Presence from '../../shared/Presence';
import {Tag, KIND as TagKind, SIZE as TagSize, VARIANT} from 'baseui/tag';
import { Tabs, Tab, FILL } from "baseui/tabs-motion";
import { NotificationCircle, COLOR } from "baseui/badge";
import {
    Card,
    StyledBody
  } from "baseui/card";
import {Plus as PlusIcon} from 'baseui/icon';
import {CheckIndeterminate as CheckIndeterminateIcon} from 'baseui/icon';
import { StyledLink } from 'baseui/link';


import {StatefulTreeView} from 'baseui/tree-view';
import Linkify from 'react-linkify';

const restricrWidthProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                width: '60px',
                flexGrow: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start'
            }),
        },
    },
};

const leftCenterProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start'
            }),
        },
    },
};
const rightButtonProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                width: "180px",
                flexGrow: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }),
        },
    },
};

const leftProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                width: '25%',
                display: 'flex',
                flexGrow: 0,
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                marginRight: '10px'
            }),
        },
    },
};

const rightAdjusted = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
            }),
        },
    },
};

const emptySide = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                width: '25%',
                display: 'flex',
                flexGrow: 0,
            }),
        },
    },
};

const physicianBlock = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                backgroundColor: window.matchMedia("(prefers-color-scheme: light)").matches ? "#d0edff" : "#00426b" ,
                marginBottom: "12px",
                borderRadius: "13px",
                padding: "12px"
            }),
        },
    },
};

const patientBlock = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                backgroundColor: $theme.colors.backgroundSecondary,
                marginBottom: "12px",
                borderRadius: "13px",
                padding: "12px"
            }),
        },
    },
};

const outlinedBlockLeft = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                border: "1px solid " + $theme.colors.backgroundTertiary,
                marginBottom: "8px",
                borderRadius: "13px",
                padding: "12px"
            }),
        },
    },
};

const negativeProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                color: $theme.colors.negative,
            }),
        },
    },
}

const grayProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                color: $theme.colors.primary400,
            }),
        },
    },
};

const tagProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                maxWidth: '200px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end'
            }),
        },
    },
};

const blockProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: 'transparent',
                borderRadius: '',
                width:"100%",
                marginTop:'40px',
                marginRight: "auto",
                marginBottom:'10px',
                marginLeft: "auto"
            }),
        },
    },
  };

const LEFT_PANEL_MEDICAL = 0;
const LEFT_PANEL_REQUESTS = 1;
const LEFT_PANEL_CONTACT = 2;

class Patient extends Component{
    constructor(props){
        super(props);

        this.protocolInterval = null;

        this.state = {
            isLoaded: false,
            isError: false,
            response: null,
            prescriptions: [],
            data: null,
            patient_index: 0,
            error: null,
            protocol_id: this.props.match.params.protocol_id || this.props.protocol_id,
            patient_id: this.props.match.params.patient_id || this.props.patient_id,
            protocol_subscription: this.props.protocol_subscription,
            action: this.props.match.params.action,
            new_prescriptions: [], // Needed to keep the new Requests list on the left panel up to date
            new_encounter_notes: [],
            new_message_to_patient: [],

            unread_messages: false,
            unread_task_responses: false,
            unread_form_responses: false,

            taskResponseModalShow: false,
            taskResponseTitle: null,
            taskResponseDescription: null,
            taskResponseFileName: null,
            taskResponseFileUrl: null,

            formResponseModalShow: false,
            formResponseData: null,

            open_rx_pad: false,
            backdrop_clicked: false,

            leftPanelButtonSelected: LEFT_PANEL_MEDICAL,
            content: this.getContent(),

            dismissed: [],
            activeKey: "0",
        };

        this.handleChange = this.handleChange.bind(this);
    }

    getContent = () => {
        return content('en');
    };

    isOnboarding = () => {
        return this.state.protocol_subscription.status == 'pending';
    };

    componentDidMount = () => {
        this.getProtocolData();

        this.protocolInterval = setInterval(() => {
            if (this.props.firebase.isUserOnline()) {
                this.getProtocolData();
            } else {
                clearInterval(this.protocolInterval);
                this.protocolInterval = null;
            }
        }, 30000);
    };

    getProtocolData = () => {
        Request.get({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/physician_patient/protocol/" + this.getArguments(),
            callbackSuccess: (result) => {
                this.setState({
                    isLoaded: true,
                    response: result,
                    prescriptions: result.data.patients[this.state.patient_index].prescriptions,
                    error: this.state.isError ? result.message : '',
                    isError: false,
                    unread_messages: this.unreadItemExists(result.data.patients[this.state.patient_index].patient_messages),
                    unread_form_responses: this.unreadItemExists(result.data.patients[this.state.patient_index].form_responses),
                    unread_task_responses: this.unreadItemExists(result.data.patients[this.state.patient_index].protocol_task_responses),
                    new_encounter_notes: [],
                    new_message_to_patient: [],

                }, () => {
                    if (this.state.unread_messages) {
                        this.markMessagesRead(result.data.patients[this.state.patient_index].patient_messages);
                        if (this.props.messages_read_callback) {
                            this.props.messages_read_callback();
                        }
                    }
                });

                // Mark messages as read.
            },
            callbackError: (error) => {
                this.setState({
                    isLoaded: true,
                    error
                })
            }
        })
    };

    newEncounterNote = (note) => {
        note.ui_type = 'encounter_note';

        this.setState((prevState) => ({
            new_encounter_notes: prevState.new_encounter_notes.concat(note)
        }));
    };

    newMessageToPatient = (message) => {
        message.ui_type = 'provider_message';

        this.setState((prevState) => ({
            new_message_to_patient: prevState.new_message_to_patient.concat(message)
        }));
    };

    newFileToPatient = () => {
        this.setState({isLoaded: false}, () => this.getProtocolData())
    };

    markMessagesRead = (messages) => {
        const data = new FormData();

        messages.map(message => {
            if (!message.seen_by_provider) {
                data.append('updated_at[]', message.updated_at);
            }
        });

        data.append('protocol_subscription_id', this.state.protocol_subscription.id);

        Request.post({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/physician_message/seen_by_provider",
            body: data,
            callbackSuccess: result => {},
            callbackError: error => {}
        })
    };

    openFile = (url, event) => {
      event.preventDefault();
      event.stopPropagation();
      Request.post_to_new_window({
        firebase: this.props.firebase,
        route: url
      });
    };


    lineDataUp = () => {
        let allData = [];
        allData = allData.concat(this.state.new_message_to_patient);
        allData = allData.concat(this.state.new_encounter_notes);
        allData = allData.concat(this.currentPatientEncounterNotes());
        allData = allData.concat(this.currentPatient().patient_messages.map(message => {message.ui_type = 'patient_message'; return message;}));
        allData = allData.concat(this.currentPatientResponsesAndTasks());
        allData = allData.concat(this.currentPatientRefills());
        allData = allData.concat(this.currentPatientProviderMessages());

        return allData.sort((a, b) => new Date(a.created_at) > new Date(b.created_at) ? -1 : 1);
    };

    renderHistoryMonolithic = () => {
        let patientDataTypes = ['patient_message', 'patient_order', 'selfie', 'photo'];
        let datas = this.lineDataUp();
        let newPatientStuff = false;

        return datas.map((data, index) => {
            if(patientDataTypes.includes(data.ui_type)){
                if (index == 0) {
                    newPatientStuff = true;
                }
                return this.renderPatientData(data);
            } else {
                if (newPatientStuff){
                    newPatientStuff = false;
                    return <>{this.renderNewRequest()} {this.renderPhysicianData(data)}</>
                }
                return this.renderPhysicianData(data)
            }
        });
    };

    renderNewRequest = () => {
        return <FlexGrid>
        <FlexGridItem style={{borderBottom: "1px solid #00dd75", marginBottom: "15px"}}><ParagraphXSmall style={{margin: 0, color: "#00dd75"}}>New</ParagraphXSmall></FlexGridItem>
    </FlexGrid>
    };


    renderPatientData = (data) => {
        if (data.ui_type == 'patient_message') {
            return this.renderPatientMessage(data);
        } else if (['patient_order'].includes(data.ui_type)) {
            return this.renderPatientFormsAndAttachments(data.data);
        } else {
            return <div>Patient {Utils.renderLocaleString(data.created_at)} - {data.ui_type}</div>
        }
    };

    renderPhysicianData = (data) => {
        if (data.ui_type == 'encounter_note') {
            return this.renderPhysicianEncounterNote(data);
        } else if (data.ui_type == 'provider_message'){
            return this.renderProviderMessage(data);
        } else if (['prescriptions', 'new_prescription'].includes(data.ui_type)){
            return this.renderPrescriptions(data);
        } else {
            return <div>Physician {Utils.renderLocaleString(data.created_at)} - {data.ui_type}</div>
        }
    };

    renderEnounterNoteHeader = (data) => {
        if (data.soap_note.startsWith('Rejection reason:')) {
            return data.provider.username + ' rejected ' + this.currentPatient().first_name + ' ' + this.currentPatient().last_name + "'s application";
        } else if (data.soap_note.startsWith('Ban reason:')) {
            return data.provider.username + ' banned ' + this.currentPatient().first_name + ' ' + this.currentPatient().last_name + ' from the ' + this.props.protocol.name + ' server';
        } else if (data.soap_note === "Accepted") {
            return data.provider.username + ' accepted ' + this.currentPatient().first_name + ' ' + this.currentPatient().last_name + ' to the ' + this.props.protocol.name + ' server';
        } else {
            return <><span style={{color:"#EC8E38"}}>Encounter note</span>{' by ' + data.provider.username}</>
        }
    };

    renderPhysicianEncounterNote = (data) => {
        return <FlexGrid flexGridColumnCount={2}>
            <FlexGridItem {...emptySide}></FlexGridItem>
            <FlexGridItem {...physicianBlock}>
                <FlexGrid flexGridRowGap={"scale400"}>
                    <FlexGridItem><LabelSmall style={{margin: 0, color: this.state.content.time_ago_color}}>{this.renderEnounterNoteHeader(data)} {Utils.renderLocaleString(data.created_at)}</LabelSmall></FlexGridItem>
                    <FlexGridItem>
                        <ParagraphMedium style={{margin: 0}}>{data.soap_note}</ParagraphMedium>
                    </FlexGridItem>
                </FlexGrid>
            </FlexGridItem>
        </FlexGrid>
    };

    renderProviderMessage = (data) => {
        return <FlexGrid flexGridColumnCount={2}>
            <FlexGridItem {...emptySide}></FlexGridItem>
            <FlexGridItem {...physicianBlock}>
                <FlexGrid flexGridRowGap={"scale400"}>
                    <FlexGridItem><LabelSmall style={{margin: 0, color: this.state.content.time_ago_color}}><span style={{color:"#EC8E38"}}>Message</span> by {data.provider.username} {Utils.renderLocaleString(data.created_at)}</LabelSmall></FlexGridItem>
                    <FlexGridItem style={{wordWrap: 'break-word'}}>
                        <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (<a style={{color:"#00a1ff", overflowWrap:"break-word"}} target="blank" href={decoratedHref} key={key}>{decoratedText}</a>)}>
                            <ParagraphMedium style={{margin: 0, whiteSpace:"pre-line"}}>{data.message_for_patient}</ParagraphMedium>
                            { data.file_upload_url ? <ParagraphMedium style={{margin: 0, whiteSpace:"pre-line", color:"00a1ff"}}><StyledLink onClick={(e) => {this.openFile(data.file_upload_url, e)}} href={data.file_upload_url}>{data.file_upload_filename}</StyledLink></ParagraphMedium> : ''}
                        </Linkify>
                    </FlexGridItem>
                </FlexGrid>
            </FlexGridItem>
        </FlexGrid>
    };

    renderPrescriptions = (data) => {
        return <FlexGrid flexGridColumnCount={2}>
            <FlexGridItem {...emptySide}></FlexGridItem>
            <FlexGridItem {...physicianBlock}>
                <FlexGrid flexGridRowGap={"scale400"}>
                    <FlexGridItem>
                        <FlexGrid flexGridColumnCount={2}>
                            <FlexGridItem><LabelSmall style={{margin: 0, color: this.state.content.time_ago_color}}><span style={{color:"#EC8E38"}}>Prescription</span> by {data.prescriber_username} {Utils.renderLocaleString(data.created_at)}</LabelSmall></FlexGridItem>
                            <FlexGridItem {...tagProps}>
                                <Tag kind={data.change_status == 'AUTHORIZED' ? TagKind.positive : TagKind.negative} closeable={false}>
                                    {Utils.upperCaseFirstLetter(data.change_status)}
                                </Tag>
                            </FlexGridItem>
                        </FlexGrid>
                    </FlexGridItem>
                    <FlexGridItem><LabelMedium style={{margin: 0}}>{data.drug_name}</LabelMedium></FlexGridItem>
                    <FlexGridItem><ParagraphSmall style={{color: 'grey', margin: 0}}>Quantity {data.quantity_dispensed} • Day supply {data.days_supply} • Refills {data.refills} • ID {data.number}</ParagraphSmall></FlexGridItem>
                    <FlexGridItem><ParagraphSmall style={{margin: 0}}>{data.pharmacy_detail}</ParagraphSmall></FlexGridItem>
                </FlexGrid>
            </FlexGridItem>
        </FlexGrid>
    };

    renderPatientMessage = (message) => {
        return <FlexGrid flexGridColumnCount={2}>
            <FlexGridItem {...patientBlock}>
                <FlexGrid flexGridRowGap={"scale400"}>
                    <FlexGridItem><LabelSmall style={{margin: 0, color: this.state.content.time_ago_color}}><span style={{color:"#882bbf"}}>Message</span> from {this.patientName()}, {Utils.renderLocaleString(message.created_at)}</LabelSmall></FlexGridItem>
                    <FlexGridItem style={{wordWrap: 'break-word'}}>
                        <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (<a style={{color:"#00a1ff", overflowWrap:"break-word"}} target="blank" href={decoratedHref} key={key}>{decoratedText}</a>)}>
                            <ParagraphMedium style={{margin: 0, whiteSpace:"pre-line"}}>{message.body}</ParagraphMedium>
                            { message.file_upload_url ? <ParagraphMedium style={{margin: 0, whiteSpace:"pre-line"}}><StyledLink stype={{color:"#00a1ff"}} onClick={(e) => {this.openFile(message.file_upload_url, e)}} href={message.file_upload_url}>{message.file_upload_filename}</StyledLink></ParagraphMedium> : ''}
                        </Linkify>
                    </FlexGridItem>
                </FlexGrid>
            </FlexGridItem>
            <FlexGridItem {...emptySide}></FlexGridItem>
        </FlexGrid>
    };

    isOnboardingForm = (data) => {
        let onboarding = false;
        data.forEach(element => {
            if( element.form && element.form.form_type == 'onboarding_form') {
                onboarding = true;
            }
        })

        return onboarding;
    };

    renderPatientFormsAndAttachments = (data) => {
        return <FlexGrid flexGridColumnCount={2}>
            <FlexGridItem {...patientBlock}>
                <FlexGrid flexGridRowGap={"scale400"}>
                <FlexGridItem><LabelSmall style={{margin: 0, color: this.state.content.time_ago_color}}> {this.isOnboardingForm(data) ? <span style={{color:"#882bbf"}}>New application</span> : <span style={{color:"#882bbf"}}>Check-in</span>} from {this.patientName()}, {Utils.renderLocaleString(data[0].created_at)}</LabelSmall></FlexGridItem>
                    <FlexGridItem>{data.map((t_or_r, idx) => {
                        if (t_or_r.ui_type == 'task_response') {
                            const title = "Attachment " + idx;
                            return t_or_r.files_url.map((file, res_id) => <Button
                                key={res_id}
                                overrides={{
                                    BaseButton: {
                                      style: ({ $theme }) => ({
                                        marginRight: '10px',
                                        marginBottom: '10px'
                                      })
                                    },
                                    StartEnhancer: {
                                        style: ({ $theme }) => ({
                                        marginRight: '4px'
                                        })
                                    }
                                }}
                                kind={KIND.primary}
                                size={SIZE.compact}
                                shape={SHAPE.pill}
                                startEnhancer={() => <img style={{height:'16px'}} src={window.matchMedia("(prefers-color-scheme: light)").matches ? "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_24/v1680711121/link-white_xqpoqu.png": "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_24/v1680711121/link-black_r1lx3s.png" }/>}
                                onClick={(e) => this.setState({
                                    taskResponseModalShow: true,
                                    taskResponseFileName: this.getFileName(file.url),
                                    taskResponseFileUrl: this.loadFileObjectUrl(file.url),
                                    taskResponseTitle: "Attachment " + (res_id+1),
                                    taskResponseDescription: t_or_r.description
                                })}>{"Attachment " + (res_id+1)}</Button>
                            )
                        } else if (t_or_r.ui_type == 'form_response') {
                            return <Button
                                key={idx}
                                startEnhancer={() => <img style={{height:'16px'}} src={/*Storage.get('theme') == 'light' ?*/ "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_24/v1681496306/forms-white-01_k86c6g.png" /*: "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_24/v1681496306/forms-black-01_ln7nra.png"*/ }/>}
                                overrides={{
                                    BaseButton: {
                                        style: ({ $theme }) => ({
                                            marginRight: '10px',
                                            marginBottom: '10px'
                                        })
                                    },
                                    StartEnhancer: {
                                        style: ({ $theme }) => ({
                                        marginRight: '4px'
                                        })
                                    }
                                }}
                                kind={KIND.primary}
                                size={SIZE.compact}
                                shape={SHAPE.pill}
                                onClick={(e) => this.setState({formResponseModalShow: true, formResponseData: t_or_r})}>View questionnaire</Button>
                        } else if (['photo', 'selfie'].includes(t_or_r.ui_type)) {
                            //this.loadFileObjectUrl(t_or_r.url)
                            return <Button
                                key={idx}
                                startEnhancer={() => <img style={{height:'16px'}} src={t_or_r.ui_type == 'photo' ? window.matchMedia("(prefers-color-scheme: light)").matches ?  "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_24/v1681496225/ID-white-01_vu68cc.png" : "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_24/v1681496225/ID-black-01_ceab7o.png" : window.matchMedia("(prefers-color-scheme: light)").matches ?  "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_24/v1681496161/selfie-white-01_byggz6.png" : "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_24/v1681496161/selfie-black-01_onpgc5.png" }/>}
                                overrides={{
                                    BaseButton: {
                                        style: ({ $theme }) => ({
                                            marginRight: '10px',
                                            marginBottom: '10px'
                                        })
                                    },
                                    StartEnhancer: {
                                        style: ({ $theme }) => ({
                                        marginRight: '4px'
                                        })
                                    }
                                }}
                                kind={KIND.primary}
                                size={SIZE.compact}
                                shape={SHAPE.pill}
                                onClick={(e) => this.setState({
                                    taskResponseModalShow: true,
                                    taskResponseFileUrl: this.loadFileObjectUrl(t_or_r.url),
                                    taskResponseTitle: t_or_r.ui_type == 'photo' ? "Patient photo ID" : "Patient selfie",
                                    taskResponseDescription: "",
                                    taskResponseFileName: ""
                                })}>{t_or_r.ui_type == 'photo' ? "View photo ID" : "View selfie"}</Button>
                        }
                    })}
                    </FlexGridItem>
                </FlexGrid>
            </FlexGridItem>
                <FlexGridItem {...emptySide}></FlexGridItem>
        </FlexGrid>
    };

    taskResponseModalClose = () => {this.setState({taskResponseModalShow: false})};

    openFile = (file_url, e) => {
      console.log('opening file');
      e.stopPropagation();
      e.preventDefault();
      Request.post_to_new_window({
        firebase: this.props.firebase,
        route: file_url
      });
    };

    loadFileObjectUrl = (file_url) => {
      if (['pdf', 'PDF'].includes(this.getFileExtension(file_url.toLowerCase()))) {
        return file_url
      }

      Request.get({
        notJson: true,
        firebase: this.props.firebase,
        route: file_url,
        headers: {responseType: 'blob'},
        callbackSuccess: (data) => {
          data.blob().then(blob => {
            const url = window.URL || window.webkitURL;
            const src = url.createObjectURL(blob);
            this.setState({
              taskResponseFileUrl: src
            });
          });
        },
        callbackError: (error) => {
          this.setState({
            taskResponseFileUrl: null
          })
        }
      })
    };

    createTaskResponseModal = () => {
        return <MpsModal
            open={this.state.taskResponseModalShow}
            callbackCancel={this.taskResponseModalClose}
            title={Utils.renderTitleCenter(this.state.taskResponseTitle)}
            body={<div style={{textAlign: 'center'}}>
                    <ParagraphSmall style={{color: this.state.content.time_ago_color, textAlign: 'center', marginTop: '0px'}}>{this.state.taskResponseFileName}</ParagraphSmall>
                    {this.state.taskResponseDescription ? <ParagraphMedium style={{textAlign: 'center'}}><i>"{this.state.taskResponseDescription}"</i></ParagraphMedium> : null}
                    {['pdf'].includes(this.getFileExtension(this.state.taskResponseFileName).toLowerCase())
                    ? <Button
                        $as='a'
                        onClick={(e) => this.openFile(this.state.taskResponseFileUrl, e)}
                        href={this.state.taskResponseFileUrl}
                        target="_blank"
                        kind={KIND.primary}
                        size={SIZE.large}
                        shape={SHAPE.pill}
                        overrides={{
                            BaseButton: {
                                style: ({ $theme }) => ({
                                    marginTop: '30px',
                                    marginBottom: '30px'
                                })
                            }
                        }}
                        >View PDF in new tab</Button>
                    : <img src={this.state.taskResponseFileUrl} alt={this.state.taskResponseFileName} width="100%"/>}
                </div>
            }
        />
    };

    formResponseModalClose = () => {this.setState({formResponseModalShow: false})};

    renderResponse = (responseText, responseFlag) => {
        var style = {};
        style.margin = 0;
        style.marginLeft = '35px';
        style.marginTop = '15px';
        style.flex = 1;
        style.flexWrap = 'wrap';

        if (responseText.length == 0 ) {
            style.color = "#999999";
            return <ParagraphMedium style={style}>No response provided</ParagraphMedium>
        }

        return responseText.map((response, idx) => {
            if (responseFlag && responseFlag[idx]) {
                return <ParagraphMedium style={style} key={idx} {...negativeProps}>{response}</ParagraphMedium>
            }

            return <ParagraphMedium style={style} key={idx}>{response}</ParagraphMedium>
        })
    };

    createFormResponseModal = () => {
        if (!this.state.formResponseData) {
            return null;
        }
        return <MpsModal
            open={this.state.formResponseModalShow}
            callbackCancel={this.formResponseModalClose}
            callbackCancelButton={"Close"}
            withFooter={true}
            callbackButtonShow={false}
            body={<FlexGrid>
                <FlexGridItem><HeadingXSmall style={{margin: 0}}>{(this.state.formResponseData.form.form_type == 'drug_request_form' ? 'Drug request' : 'Onboarding request') + ' - ' + Utils.renderLocaleString(this.state.formResponseData.created_at)}</HeadingXSmall></FlexGridItem>
                {this.state.formResponseData.results.questionResponses.map((res, idx) => <FlexGridItem key={idx} style={{marginBottom: '10px'}}>
            <LabelLarge style={{margin: 0, marginTop: (idx == 0 ? '15px' : '30px')}}>{idx+1}. {res.questionText}</LabelLarge>
            {this.renderResponse(res.responseText, res.responseFlag)}</FlexGridItem>)}
            </FlexGrid>
            }
        />
    };


    getFileExtension = (file) => {
        return decodeURI(file).split('.').pop();
    };

    getFileName = (file) => {
        return decodeURI(file).split('/').pop();
    };

    getArguments = () => {
        if (this.state.action) {
            return this.state.action + '/' + this.state.protocol_id;
        } else {
            return this.state.protocol_id + (this.state.patient_id ? '/' + this.state.patient_id : '');
        }
    };

    getProtocolId = () => {
        return this.state.protocol_id;
    };

    currentPatient = () => {
        if (!this.state.isLoaded || this.state.error) {
            return {};
        }

        return this.state.response.data.patients[this.state.patient_index];
    };

    currentProtocolSubscription = () => {
        return this.state.protocol_subscription;
    };

    currentPatientEncounterNotes = () => {
        let encounter_notes = []
        if (!this.state.isLoaded || this.state.error) {
            return encounter_notes;
        }

        encounter_notes = this.state.response.data.patients[this.state.patient_index].encounter_notes.map(encounter_note => { encounter_note.ui_type = 'encounter_note'; return encounter_note;})

        return encounter_notes
    };

    currentPatientProviderMessages = () => {
        let provider_messages = []
        if (!this.state.isLoaded || this.state.error) {
            return provider_messages;
        }

        provider_messages = this.state.response.data.patients[this.state.patient_index].provider_messages.map(provider_message => { provider_message.ui_type = 'provider_message'; return provider_message;})

        return provider_messages;
    };

    currentPatientRefills = () => {
        if (!this.state.isLoaded || this.state.error) {
            return [];
        }

        let results = [];
        this.state.prescriptions.forEach(prescription => {
            if (prescription.protocol_subscription_id == this.state.protocol_subscription.id) {
                prescription.ui_type = 'prescriptions';
                results.push(prescription);
            }
        });
        return results;
    };

    /* Group *all* tasks and form response into one so they can be grouped in UI

    data = [
        {
            order_id: 1,
            data: [
                form_response, task1, task2, task3
            ]
        },
        ...
    ]
    */

    currentPatientResponsesAndTasks = () => {
        let data = [];
        if (this.state.response.data.patients[this.state.patient_index].form_responses) {
            this.state.response.data.patients[this.state.patient_index].form_responses.forEach(response => {
                response.ui_type = 'form_response';
                const index = data.findIndex(element => element.order_id == response.order_id);
                if (index >= 0) {
                    data[index].data.push(response);
                } else {
                    // New order
                    data.push({
                        ui_type: 'patient_order',
                        order_id: response.order_id,
                        created_at: response.order.checked_out_at, // used for sorting
                        data: [response]
                    });
                }
            });
        }

        if (this.state.response.data.patients[this.state.patient_index].protocol_task_responses) {
            this.state.response.data.patients[this.state.patient_index].protocol_task_responses.forEach(task => {
                task.ui_type = 'task_response';
                const index = data.findIndex(element => element.order_id == task.order_id);
                if (index >= 0) {
                    data[index].data.push(task);
                } else {
                    // New order
                    data.push({
                        ui_type: 'patient_order',
                        order_id: task.order_id,
                        created_at: task.order.checked_out_at, // used for sorting
                        data: [task]
                    });
                }
            });
        }

        let photo = this.state.response.data.patients[this.state.patient_index].photo_identification;
        photo.url = this.currentPatient().photo_identification_url;
        photo.ui_type = 'photo';

        let selfie = this.state.response.data.patients[this.state.patient_index].verification_picture;
        selfie.url = this.currentPatient().verification_picture_url;
        selfie.ui_type = 'selfie';

        const compiledData = {
            ui_type: 'patient_order',
            order_id: null,
            created_at: photo.created_at, // used for sorting
            data: [photo, selfie]
        };

        const lastOrder = data.length-1;
        if (lastOrder >= 0) {
            data[lastOrder].data = data[lastOrder].data.concat([photo, selfie])
        } else {
            data.push(compiledData);
        }

        return data;
    };

    currentPatientResponses = () => {
        if (!this.state.isLoaded || this.state.error) {
            return [];
        }

        return this.state.response.data.patients[this.state.patient_index].form_responses.map(response => {response.ui_type = 'form_response'; return response;});
    };

    currentPatientOrder = () => {
        if (!this.state.isLoaded || this.state.error) {
            return [];
        }

        return this.state.response.data.patients[this.state.patient_index].requested_and_prescribed_orders;
    };

    currentPatientProtocolTaskResponses = () => {
        if (!this.state.isLoaded || this.state.error) {
            return [];
        }

        return this.state.response.data.patients[this.state.patient_index].protocol_task_responses.map(task => {task.ui_type = 'task_response'; return task;});
    };

    nextPatient = (optional_encounter) => {
        if (!this.lastNextPatient()) {
            this.setState((prev_state) => ({patient_index: prev_state.patient_index + 1}));
        } else {
            // TODO is this needed anymore now that we sync evey 5 seconds?
            //update notes to show the newest one
            if (optional_encounter) {
                this.setState((prev_state, props) => {
                    prev_state.response.data.patients[prev_state.patient_index].encounters.push(optional_encounter);
                    return(prev_state);
                });
            }
        }
    };

    lastNextPatient = () => {
        return this.state.isLoaded && this.state.patient_index >= this.state.response.data.patients.length - 1;
    };

    backPatient = () => {
        if (this.state.patient_index > 0) {
            this.setState({patient_index: this.state.patient_index - 1});
        }
    };

    lastBackPatient = () => {
        return (!this.state.isLoaded) || (this.state.isLoaded && this.state.patient_index == 0);
    };

    patientRxPad = () => {
        if (!this.state.isLoaded || this.state.error){
            return ""
        }

        return this.state.response.data.rx_pad + this.currentPatient().number;
    };

    renderTabTitle = () => {
        return <HeadingMedium style={{marginTop: '10px', marginBottom: '10px'}}></HeadingMedium>
    };

    renderTabContent = () => {
        return this.renderHistoryMonolithic();
    };

    unreadItemExists = (collection) => {
        return collection.filter(item => !item.seen_by_provider).length > 0
    }

    patientUsername = () => {
        return this.currentPatient().username;
    };

    patientName = () => {
        return this.currentPatient().first_name + ' ' + this.currentPatient().last_name
    };

    renderBox = (color, title, content) => {
        return <FlexGrid {...outlinedBlockLeft}>
            <FlexGridItem><LabelSmall style={{margin:'0 0 6px 0', color: '#999999'}}>{title}</LabelSmall></FlexGridItem>
            <FlexGridItem><LabelMedium style={{margin:0}}>{content}</LabelMedium></FlexGridItem>
        </FlexGrid>
    };

    currentProtocolPatientActiveRx = () => {
        return this.state.prescriptions.filter(prescription => {
            return !prescription.patient_hide
                && new Date(prescription.end_date) > new Date()
                && prescription.active === true
                && prescription.protocol_subscription_id == this.state.protocol_subscription.id
        }).sort((a, b) => {return a.created_at > b.created_at ? -1 : 1})
    };

    allProtocolsPatientActiveRx = () => {
        return this.state.prescriptions.filter(prescription => {
            return !prescription.patient_hide
                && new Date(prescription.end_date) > new Date()
                && prescription.active === true
        }).sort((a, b) => {return a.created_at > b.created_at ? -1 : 1})
    }

    renderAllPatientActiveRx = () => {
        return this.allProtocolsPatientActiveRx().map((prescription, idx) => <FlexGridItem key={idx}>
            {this.renderBox('#def0ff',  prescription.protocol_name + " • " + Utils.renderLocaleString(prescription.created_at), prescription.drug_name)}
        </FlexGridItem>)
    };

    renderPatientDemographics = () => {
        return <FlexGrid>
            <FlexGridItem><HeadingMedium style={{margin: 0, marginTop: '20px', marginBottom: '10px', fontSize: "20px"}}>Demographics</HeadingMedium></FlexGridItem>
            <FlexGridItem>
                {this.renderBox(
                    '#ffeedb',
                    'Sex • Height • Weight',
                    this.currentPatient().birth_sex + ' • ' + this.currentPatient().height + '" • ' + this.currentPatient().weight + 'lbs' )}
            </FlexGridItem>
        </FlexGrid>
    };

    renderPatientReportedInfo = () => {
        return <FlexGrid>
            {/*<FlexGridItem>{this.renderBox('#d9ffcf', 'Preferred pharmacy', this.props.patient.preferred_pharmacy)}</FlexGridItem>*/}
            <FlexGridItem><MedicalInfo patient={this.currentPatient()}/></FlexGridItem>
        </FlexGrid>
    };

    renderPatientActivePrescriptions = () => {
        return this.state.prescriptions.length > 0
            ? <FlexGrid>
                <FlexGridItem><FlexGrid>{this.renderAllPatientActiveRx()}</FlexGrid></FlexGridItem>
            </FlexGrid>
        : <FlexGrid>
            <FlexGridItem><HeadingMedium style={{margin: 0, marginTop: '10px', marginBottom: '10px', fontSize: "20px"}}>All scripts on Clinic</HeadingMedium></FlexGridItem>
            <FlexGridItem><ParagraphMedium margin={0}>No active prescriptions</ParagraphMedium></FlexGridItem>
        </FlexGrid>
    };

    renderPatientMedical = () => {
        return <FlexGrid>
            <FlexGridItem>
                <>{this.renderPatientReportedInfo()} {this.renderPatientActivePrescriptions()}</>
            </FlexGridItem>
        </FlexGrid>
    };

    getOrderLineItems = (orders) => {
        let lineItems = [];

        if (orders && Object.keys(orders).length != 0) {
            let filteredItems = orders.map(order => order.line_items.filter((item) => item.type == 'VariantLineItem' && item.variant_id != null));
            lineItems = [].concat.apply([], filteredItems);
        }

        return lineItems;
    };

    dismissed = (line_item_ids) => {
        this.setState(prevState => {
            prevState.dismissed = prevState.dismissed.concat(line_item_ids);

            return prevState
        })
    };

    renderPatientRequests = () => {
        let orders = this.currentPatientOrder();
        let lineItems = this.getOrderLineItems(orders);
        let alreadyPrescribed = this.state.new_prescriptions;

        return <FlexGrid>
            <FlexGridItem>{lineItems.length > 0 ? <RequestDrugs
                patient_uuid={this.currentPatient().uuid}
                orders={orders}
                requested_drugs={lineItems}
                alreadyPrescribed={alreadyPrescribed}
                protocol_subscription={this.state.protocol_subscription}
                dismissed={this.dismissed}
                is_rx_pad_open={this.state.open_rx_pad}
            /> : <ParagraphMedium style={{margin: '0px', textAlign:'center'}}>No pending requests</ParagraphMedium>}</FlexGridItem>
        </FlexGrid>
    };

    calculateNumRequests = () => {
        let num_requests = 0;
        let line_items = this.getOrderLineItems(this.currentPatientOrder()).filter(line_item => !line_item.prescription_id && line_item.request_status != 'dismissed' && !this.state.dismissed.includes(line_item.id));

        line_items.forEach(line_item => {
            let prescribedList = this.state.new_prescriptions.filter( prescription => prescription.variant_id == line_item.variant_id && prescription.change_drug_id == line_item.variant.change_drug_id);
            if(prescribedList.length == 0) {
                num_requests += 1
            }
        })

        return num_requests;
    };

    renderPatientContactInfo = () => {
        return (
            <StatefulTreeView
            data={[
                {
                    id: 1,
                    label: <ParagraphSmall style={{margin:0}}>Contact info</ParagraphSmall>,
                    isExpanded: false,
                    children: [
                    {
                        id: 2,
                        label: <FlexGrid>
                        <FlexGridItem><LabelSmall style={{margin:0, color: '#999999'}}>Phone number</LabelSmall></FlexGridItem>
                        <FlexGridItem><LabelMedium style={{margin:'0 0 10px 0'}}>{Utils.renderPhone(this.currentPatient().mobile_number)}</LabelMedium></FlexGridItem>
                        <FlexGridItem><LabelSmall style={{margin:0, color: '#999999'}}>Email address</LabelSmall></FlexGridItem>
                        <FlexGridItem><LabelMedium style={{margin:'0 0 10px 0'}}>{(this.currentPatient().email)}</LabelMedium></FlexGridItem>
                        <FlexGridItem><LabelSmall style={{margin:0, color: '#999999'}}>Address</LabelSmall></FlexGridItem>
                        <FlexGridItem><LabelMedium style={{margin:'0 0 10px 0'}}>{Utils.renderAddress(this.currentPatient().shipping_address)}</LabelMedium></FlexGridItem>
                        <FlexGridItem><LabelSmall style={{margin:0, color: '#999999'}}>UID</LabelSmall></FlexGridItem>
                        <FlexGridItem><LabelMedium style={{margin:'0 0 10px 0'}}>{(this.currentPatient().number)}</LabelMedium></FlexGridItem>
                    </FlexGrid>,
                    },
                    ],
                },
                ]}
            overrides={{
                TreeLabel:{
                    style:{
                        paddingLeft:"0px",
                        ":hover": {
                            backgroundColor: 'transparent',
                        },
                    }
                },
                TreeItemList: {
                    style: {
                        paddingLeft: "3px",
                    },
                },
                IconContainer: {
                    style: {
                      marginRight: 0,
                    },
                  },
                LeafIconContainer: {
                    style: ({ $theme }) => ({
                        display: 'none'
                    })
                },
                ExpandIcon: {
                    component: PlusIcon,
                  },

                CollapseIcon: {
                    component: CheckIndeterminateIcon,
                  },
                }}
            />
        );
    };

    renderLeftPanelData = () => {
        if (this.state.leftPanelButtonSelected == LEFT_PANEL_MEDICAL) {
            return this.renderPatientMedical();
        } else if (this.state.leftPanelButtonSelected == LEFT_PANEL_REQUESTS) {
            return this.renderPatientRequests();
        } else if (this.state.leftPanelButtonSelected == LEFT_PANEL_CONTACT) {
            return this.renderPatientContactInfo();
        } else return null;
    };

    renderSubscriptionStatus = () => {
        let status = this.currentProtocolSubscription().status;

        if (status == 'pending') {
            return <span><Tag
            closeable={false}
            kind={TagKind.custom}
            size={TagSize.small}
            color="#d576f8"
            variant={VARIANT.solid}
            overrides={{
                Root: {
                  style: ({ $theme }) => ({
                    borderRadius: '4px',
                    marginLeft: '0px'
                  })
                }
              }}
          >
            Wants to join
          </Tag></span>;
        } else if (status == 'dead') {
            return <span><Tag
            closeable={false}
            kind={TagKind.custom}
            size={TagSize.small}
            color="#ef8037"
            variant={VARIANT.solid}
            overrides={{
                Root: {
                  style: ({ $theme }) => ({
                    borderRadius: '4px',
                    marginLeft: '0px'
                  })
                }
              }}
          >
          Kicked
          </Tag></span>;
        } else if (status == 'inactive') {
            return <span><Tag
            closeable={false}
            kind={TagKind.custom}
            size={TagSize.small}
            color="#9a9a9a"
            variant={VARIANT.solid}
            overrides={{
                Root: {
                  style: ({ $theme }) => ({
                    borderRadius: '4px',
                    marginLeft: '0px'
                  })
                },
                Text: {
                    style: ({ $theme }) => ({
                      maxWidth: '200px',
                    })
                  }
              }}
          >
            Subscription paused
          </Tag></span>;
        } else {
            return <span><Tag
            closeable={false}
            kind={TagKind.custom}
            size={TagSize.small}
            color="#00ff00"
            variant={VARIANT.solid}
            startEnhancer={() => <span>✓</span>}
            overrides={{
                Root: {
                  style: ({ $theme }) => ({
                    borderRadius: '4px',
                    marginLeft: '0px'
                  })
                }
              }}
          >
            Subscribed
          </Tag></span>;
        }
    };

    renderTabContents = () => {
        const [activeKey, setActiveKey] = React.useState("0");
        return
    };

    handleChange({ activeKey }) {
        localStorage.setItem("activeKey", activeKey);
        this.setState({ activeKey });
      }

    renderLeftPanel = () => {
        let numRequests = this.calculateNumRequests();
        return <FlexGrid width="100%">
            <Card
                overrides={{
                    Root: {
                    style: ({ $theme }) => ({
                        width: 'inherit',
                        backgroundColor: $theme.colors.backgroundSecondary
                    })
                    }
                }}
                >
            <StyledBody>
            <FlexGridItem style={{display:'flex', alignItems:'center', justifyContent:'flex-start'}}>{this.renderSubscriptionStatus()}</FlexGridItem>
            <FlexGridItem style={{display:'flex', alignItems:'center'}}><Presence status={this.currentPatient().presence} /><HeadingSmall style={{margin: 0, marginBottom: '5px', display: 'inline-block', fontFamily:'RoobertSemiBold'}}>&nbsp;{this.currentPatient().first_name + ' ' + this.currentPatient().last_name}</HeadingSmall></FlexGridItem>
            {/*<FlexGridItem><LabelMedium style={{margin: 0, marginBottom: '9px'}}>DOB&nbsp;{this.currentPatient().birthdate}&nbsp;•&nbsp;{this.currentPatient().number}</LabelMedium></FlexGridItem>*/}
            <FlexGridItem><LabelMedium>{this.currentPatient().birthdate} • {this.currentPatient().birth_sex} • {this.currentPatient().height}" • {this.currentPatient().weight + 'lbs'}</LabelMedium></FlexGridItem>
            <FlexGridItem><LabelMedium>{this.renderPatientContactInfo()}</LabelMedium></FlexGridItem>
            </StyledBody>
            </Card>
            <FlexGridItem>
            <Tabs
            activeKey={this.state.activeKey}
            onChange={this.handleChange}
            activateOnFocus
            fill={FILL.fixed}
            overrides={{
                TabContent: {
                  style: ({ $theme }) => ({
                    paddingRight:'0px',
                    paddingLeft:'0px'
                  })
                }
              }}
            >
            <Tab
                title='Reported info'
                overrides={{
                    TabPanel: {
                        style: {
                        paddingLeft: 0,
                        paddingRight: 0
                        },
                    },
                    }}
                >{this.renderPatientReportedInfo()}
            </Tab>
            <Tab
                title='Scripts on Clinic'
                overrides={{
                    TabPanel: {
                        style: {
                        paddingLeft: 0,
                        paddingRight: 0
                        },
                    },
                    }}
                >{this.renderPatientActivePrescriptions()}
            </Tab>
            <Tab
                title={numRequests > 0 ? <div>Requests<sup><span style={{
                                    outline: window.matchMedia("(prefers-color-scheme: light)").matches ? "3px solid white" : "3px solid #141414",
                                    padding: "0px 5px 0px 5px",
                                    margin: "0px 0px 0px 3px",
                                    color: 'white',
                                    backgroundColor: '#EA4345',
                                    borderRadius: '10px'}}>{numRequests}</span>
                            </sup></div> : 'Requests'}
                overrides={{
                    TabPanel: {
                        style: {
                        paddingLeft: 0,
                        paddingRight: 0
                        },
                    },
                    }}
                >{this.renderPatientRequests()}
            </Tab>
            </Tabs>
            </FlexGridItem>
        </FlexGrid>
    };

    resetBackdrop = () => {
        this.state.backdrop_clicked = false;
        return null;
    };

    closeRx = (results) => {
        this.syncNewlyPrescribedDrugs(results.prescribed);

        this.props.requested_orders_callback(this.currentPatientOrder())

        this.setState({open_rx_pad: false});
        if(this.props.executeOnClosedPad) {
            this.props.executeOnClosedPad(results);
        }
    };

    getWelcomeMessage = () => {
      if (this.props.protocol_subscription && this.props.protocol) {
        return this.props.protocol.welcome_message
      } else {
        return ''
      }
    }

    // Update patient single with new prescriptions or cancelled prescriptions
    syncNewlyPrescribedDrugs = (synced_prescriptions) => {
        let new_prescriptions = this.state.new_prescriptions;
        let updatedPrescriptions = this.state.new_prescriptions;
        updatedPrescriptions = updatedPrescriptions.concat(this.currentPatientRefills());

        synced_prescriptions.forEach(new_prescription => {
            let found = updatedPrescriptions.filter(prescription => prescription.number == new_prescription.number);
            if (found.length == 0) {
                new_prescriptions.push(new_prescription);
            }
        });

        // in case the dr cancels rx we need to update the active prescriptions
        this.setState({
            new_prescriptions: new_prescriptions,
            prescriptions: synced_prescriptions
        });
    };

    renderRxPad = () => {
        var protocolSubscription = this.currentProtocolSubscription();
        if (this.props.protocol.status !== 'active') {
            return null;
        }

        return <Drawer
            isOpen={this.state.open_rx_pad}
            autoFocus
            animate={false}
            closeable={false}
            onClose={() => this.state.backdrop_clicked ? this.resetBackdrop() : this.setState({open_rx_pad: false})}
            size={DrawerSize.auto}
            anchor={ANCHOR.right}
            showBackdrop={false}
            onBackdropClick={() => this.state.backdrop_clicked = true}
            mountNode={document.getElementById("padIt")}
            overrides={{
                Root: {
                    style: ({ $theme }) => ({
                        width:"74%",
                        left:"26%"
                    })
                  },
                  Backdrop: {
                    style: ({ $theme }) => ({
                        width:"74%",
                        left:"26%"
                    })
                  },
                DrawerBody: {
                    style: ({ $theme }) => ({
                      marginBottom: "0px",
                    })
                  },
                DrawerContainer: {
                  style: ({ $theme }) => ({
                    width: "74%",
                    borderRadius: "25px",
                    maxHeight: "99%",
                  })
                }
              }}
        >
            <RxPad
                protocol_subscription={protocolSubscription}
                uuid={this.currentPatient().uuid}
                rx_pad={this.patientRxPad()}
                orders={this.currentPatientOrder()}
                transform={this.state.content.rx_pad_scale}
                closeRx={this.closeRx}
            />
        </Drawer>
    };

    executeOnOpenPad = () => {
        this.setState({open_rx_pad: true});

        if (this.props.executeOnOpenPad) {
            this.props.executeOnOpenPad();
        }
    };

    render = () => {
        const renderMe = this.currentPatient() && this.currentPatient().uuid !== undefined;
        let lScreen = Screen.height() - (50 + 32); // 32 is top/bottom margin
        let rScreen = Screen.height() - (100 + 32); // 32 is top/bottom margin

        if (renderMe)
            return <AuthUserContext.Consumer>
            {authUser =>
                (
                <FlexGrid flexGridColumnCount={2}>
                <FlexGridItem {...leftProps} height={lScreen + 'px'} style={{overflowY: 'auto', paddingRight: '20px', /*zIndex: '2000'*/}}>
                    {this.renderLeftPanel()}
                </FlexGridItem>
                <FlexGridItem>
                    <FlexGrid>
                        <FlexGridItem>
                            <FlexGrid flexGridColumnCount={2}>
                                <FlexGridItem>
                                    <span style={{marginRight: '5px'}}>
                                        {this.props.protocol.status == 'active' ? <Button
                                            kind={KIND.secondary}
                                            shape={SHAPE.pill}
                                            size={SIZE.compact}
                                            onClick={this.executeOnOpenPad}
                                            startEnhancer={() => <img style={{height:'16px'}} src={/*Storage.get('theme') == 'light' ?*/ "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_16/v1681361029/rxpad-black-01_vmgbn0.png" /*: "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_16/v1681361036/rxpad-white-01_tknek0.png"*/}/>}
                                            overrides={{
                                                BaseButton: {
                                                    style: ({ $theme }) => ({
                                                        color: "black",
                                                        backgroundColor: "#82D8FF",
                                                        ":hover": {
                                                            backgroundColor: "#42c3ff"
                                                        },
                                                        ":focus": {
                                                            backgroundColor: "#9ee0ff"
                                                        },
                                                        ":active": {
                                                            backgroundColor: "#9ee0ff"
                                                        },
                                                    })
                                                },
                                                StartEnhancer: {
                                                    style: ({ $theme }) => ({
                                                      marginRight: '4px',
                                                      color: 'black'
                                                    })
                                                }
                                            }}
                                        >Rx pad</Button> : <Button
                                            kind={KIND.secondary}
                                            shape={SHAPE.pill}
                                            size={SIZE.compact}
                                            disabled={true}
                                            startEnhancer={() => <img style={{height:'16px'}} src={/*Storage.get('theme') == 'light' ? "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_16/v1681361029/rxpad-black-01_vmgbn0.png" : */ "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_16/v1681361036/rxpad-white-01_tknek0.png"}/>}
                                            overrides={{
                                                BaseButton: {
                                                    style: ({ $theme }) => ({
                                                      color: 'black'
                                                    })
                                                },
                                                StartEnhancer: {
                                                    style: ({ $theme }) => ({
                                                      marginRight: '4px',
                                                    })
                                                }
                                            }}
                                        >Rx pad</Button>}

                                    </span>
                                    <span style={{marginRight: '5px'}}>
                                    <EncounterNote
                                        protocol_subscription={this.currentProtocolSubscription()}
                                        patient_uuid={this.currentPatient().uuid}
                                        protocol_number={this.getProtocolId()}
                                        protocol={this.props.protocol}
                                        callback={this.newEncounterNote}
                                    />
                                    </span>
                                    {
                                        this.state.protocol_subscription.status == 'pending'
                                        ? null
                                        : <span style={{marginRight: '5px'}}>
                                            <MessagePatient
                                                protocol={this.props.protocol}
                                                protocol_subscription={this.currentProtocolSubscription()}
                                                patient_uuid={this.currentPatient().uuid}
                                                protocol_number={this.getProtocolId()}
                                                callback={this.newMessageToPatient}
                                            />
                                        </span>
                                    }
                                    {
                                        this.state.protocol_subscription.status == 'pending'
                                        ? null
                                        : <span style={{marginRight: '5px'}}>
                                            <AttachmentButton
                                                protocol_subscription={this.currentProtocolSubscription()}
                                                patient_uuid={this.currentPatient().uuid}
                                                protocol_number={this.getProtocolId()}
                                                callback={this.newFileToPatient}
                                            />
                                        </span>
                                    }

                                    <span style={{marginRight: '5px'}}>
                                        <BanPatient
                                            protocol_number={this.getProtocolId()}
                                            protocol_subscription={this.currentProtocolSubscription()}
                                            protocol_subscription_status={this.state.protocol_subscription.status}
                                            protocol={this.props.protocol}
                                            patient_uuid={this.currentPatient().uuid}
                                            callbackSuccess={this.props.callbackSuccess}
                                            callbackSubscriptionStatus={this.props.callbackSubscriptionStatus}
                                        />
                                    </span>
                                </FlexGridItem>
                                <FlexGridItem {...rightButtonProps}>
                                    <FlexGrid flexGridColumnCount={2} flexGridColumnGap={"scale200"} width={"100%"}>
                                        <FlexGridItem marginRight={"10px"} {...rightAdjusted}>
                                            <RejectPatient
                                                protocol_number={this.getProtocolId()}
                                                protocol_subscription={this.currentProtocolSubscription()}
                                                protocol_subscription_status={this.state.protocol_subscription.status}
                                                protocol={this.props.protocol}
                                                patient_uuid={this.currentPatient().uuid}
                                                callbackSuccess={this.props.callbackSuccess}
                                                callbackSubscriptionStatus={this.props.callbackSubscriptionStatus}
                                                active_prescriptions={this.currentProtocolPatientActiveRx()}
                                            />
                                        </FlexGridItem>
                                        <FlexGridItem>
                                            <Accept
                                                protocol_number={this.getProtocolId()}
                                                default_message={this.getWelcomeMessage()}
                                                patient_uuid={this.currentPatient().uuid}
                                                orders={this.currentPatientOrder()}
                                                protocol_subscription={this.currentProtocolSubscription()}
                                                protocol={this.props.protocol}
                                                nextPatient={this.nextPatient}
                                                requested_orders_callback={this.props.requested_orders_callback}
                                                callbackSuccess={this.props.callbackSuccess}
                                                callbackSubscriptionStatus={this.props.callbackSubscriptionStatus}
                                            />
                                        </FlexGridItem>
                                    </FlexGrid>
                                </FlexGridItem>
                            </FlexGrid>
                        </FlexGridItem>
                        <FlexGridItem>{this.renderTabTitle()}</FlexGridItem>
                        <FlexGridItem>
                            <FlexGrid height={rScreen + 'px'} style={{overflowY: 'auto',}}>
                                <FlexGridItem>
                                    {this.renderTabContent()}
                                </FlexGridItem>
                                <FlexGridItem id="padIt">{this.renderRxPad()}</FlexGridItem>
                            </FlexGrid>
                        </FlexGridItem>
                    </FlexGrid>
                </FlexGridItem>
                <FlexGridItem>{this.createTaskResponseModal()}</FlexGridItem>
                <FlexGridItem>{this.createFormResponseModal()}</FlexGridItem>
            </FlexGrid>
                )
            }
        </AuthUserContext.Consumer>
        else
            return <Spinner />
    };
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(Patient);
