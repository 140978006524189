import React, {Component} from 'react';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import * as ROUTES from '../Constants';
import Utils from '../../shared/Utils';
import { HeadingSmall, LabelLarge, ParagraphMedium } from 'baseui/typography';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import { StyledLink } from "baseui/link";
import Request from '../../shared/Request';
import {Spinner} from '../../shared/Spinner';
import MpsModal from '../../shared/Utils/modal';
import PaymentDetails from './details';

const narrowItemProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                width: '80px',
                display: 'flex',
                flexGrow: 0,
                justifyContent: 'flex-end',
                alignItems: 'start',
            }),
        },
    },
};

const centerProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }),
        },
    },
};

class Payments extends Component {
    constructor(props) {
        super(props);

        this.state = {
            protocol_number: this.props.protocol_number,
            isLoaded: false,
            paymentNumber: null,
            payments: [],
            error: null,
        };
    }

    componentDidMount = () => {
        Request.get({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/protocol/" + this.state.protocol_number + "/remittances",
            callbackSuccess: (result) => {
                this.setState({
                    isLoaded: true,
                    payments: result.sort((a, b) => new Date(a.created_at) > new Date(b.created_at) ? -1 : 1),
                    ...Utils.clearAllNotifications()
                })
            },
            callbackError: (error) => this.setState({
                isLoaded: true,
                payments: [],
                error,
                ...Utils.clearAllNotificationsExceptError()
            })
        })
    };

    renderPayments = () => {
        if (!this.state.isLoaded) {
            return <Spinner />;
        } else {
            if (this.state.payments.length > 0) {
                return <FlexGrid
                    flexGridColumnCount={1}
                    flexGridRowGap={'scale800'}
                >
                    {this.state.payments.map(
                        (payment, idx) => <FlexGridItem key={idx}>
                            <StyledLink
                                onClick={(e) => this.setState({paymentNumber: payment.number})}
                                className="a_no_line"
                            >
                                <FlexGrid flexGridColumnCount={2} flexGridColumnGap={'scale800'}>
                                    <FlexGridItem>
                                        <LabelLarge>{payment.start_date.replaceAll("-", "/")} - {payment.end_date.replaceAll("-", "/")}</LabelLarge>
                                    </FlexGridItem>
                                    <FlexGridItem {...narrowItemProps}>
                                        <LabelLarge style={{margin: 0}}>{Utils.renderDollarAmount(payment.total)}</LabelLarge>
                                    </FlexGridItem>
                                    <FlexGridItem>
                                        <ParagraphMedium style={{margin: 0, color: 'grey'}}>{payment.number}</ParagraphMedium>
                                    </FlexGridItem>
                                    <FlexGridItem {...narrowItemProps}>
                                        <ParagraphMedium style={{color: payment.status == "Pending" ? 'orange' : 'green', margin: 0}}>{payment.status}</ParagraphMedium>
                                    </FlexGridItem>
                                </FlexGrid>
                            </StyledLink>
                        </FlexGridItem>)}
                </FlexGrid>
            } else {
                return <FlexGrid>
                    <FlexGridItem><ParagraphMedium style={{textAlign: 'center', margin:'0 auto 30px auto'}}>No payments found.</ParagraphMedium></FlexGridItem>
                </FlexGrid>
                ;
            }
        }
    };

    closePaymentDetails = () => {
        this.setState({paymentNumber: null});
    };

    render () {
        return (
            <AuthUserContext.Consumer>
                {
                    authUser => (
                        <div>
                            {Utils.renderError(this.state.error)}
                            <MpsModal
                                open={true}
                                callbackCancel={this.props.closePayments}
                                title={Utils.renderTitleCenter('Payments')}
                                body={
                                    this.renderPayments()
                                }
                            />

                            <MpsModal
                                open={this.state.paymentNumber}
                                callbackCancel={this.closePaymentDetails}
                                title={Utils.renderTitleCenter('Payment details')}
                                body={
                                    <PaymentDetails number={this.state.paymentNumber} close={this.closePaymentDetails}/>
                                }
                            />
                        </div>
                    )
                }
            </AuthUserContext.Consumer>
        );
    }
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(Payments);
