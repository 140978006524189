import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { LabelMedium, ParagraphMedium } from 'baseui/typography';
import React, {Component} from 'react';
import Request from '../../../shared/Request';
import { AuthUserContext, withAuthorization } from '../../../shared/Session';
import Utils from '../../../shared/Utils';
import * as ROUTES from '../../Constants';


//TODO is this used? -> it seems not... ok to delete i think.
class Messages extends Component{
    constructor(props){
        super(props);

        this.props.messages.sort((a, b) => {return a.created_at > b.created_at ? -1 : 1});
    }

    componentDidMount = () => {
        const data = new FormData();

        this.props.messages.map(message => {
            if (!message.seen_by_provider) {
                data.append('updated_at[]', message.updated_at);
            }
        });

        Request.post({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/physician_message/seen_by_provider",
            body: data,
            callbackSuccess: result => {},
            callbackError: error => {}
        })
    };

    renderMessages = () => {
        return <FlexGrid>{this.props.messages.map((message, idx) => <FlexGridItem key={idx}>
                <FlexGrid flexGridRowGap={"scale400"} marginBottom={'12px'} style={{backgroundColor: '#fafafa', padding: '12px'}}>
                    <FlexGridItem><LabelMedium style={{margin: 0}}>{Utils.renderLocaleString(message.created_at)} • {this.props.patient_name}</LabelMedium></FlexGridItem>
                    <FlexGridItem><ParagraphMedium style={{margin: 0}}>{message.body}</ParagraphMedium></FlexGridItem>
                </FlexGrid>
            </FlexGridItem>)}
        </FlexGrid>
    };

    render() {
        return (
            <AuthUserContext.Consumer>
                {authUser =>
                    (
                        this.renderMessages()
                    )
                }
            </AuthUserContext.Consumer>
        );
    }
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(Messages);
