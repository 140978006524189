// Auto submit forms

import { object } from "prop-types";

$(document).on('change', '.autosubmit', function() {
  var $el = $(this);
  var $form = $el.closest('form');

  $form.submit();
});

$(document).ready(function() {
  $('#condition_name').change(function() {
      var selected = $(this).find('option:selected').text();
      var matchingProtocols = document.getElementsByClassName(selected);
      var allProtocols = document.getElementsByClassName('protocol-item');

      for(var i =0; i < allProtocols.length; i++){
        allProtocols[i].classList.add("hide");
      }
      for(var i =0; i < matchingProtocols.length; i++){
        matchingProtocols[i].classList.remove("hide");
      }

      if(selected=='All Conditions'){
        for(var i =0; i < allProtocols.length; i++){
          allProtocols[i].classList.remove("hide");
        }
      }
  });
});

$(document).ready(function() {
  $('#product_display_name').change(function() {
      var selected = $(this).find('option:selected').text();
      selected = selected.replace(/\s/g,'');
      var matchingProtocols = document.getElementsByClassName(selected);
      var allProtocols = document.getElementsByClassName('protocol-item');

      for(var i =0; i < allProtocols.length; i++){
        allProtocols[i].classList.add("hide");
      }
      for(var i =0; i < matchingProtocols.length; i++){
        matchingProtocols[i].classList.remove("hide");
      }
      if(selected=='All Drugs'){
        for(var i =0; i < allProtocols.length; i++){
          allProtocols[i].classList.remove("hide");
        }
      }
  });
});
