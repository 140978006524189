import React, {Component} from 'react';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import * as ROUTES from '../Constants';
import Utils from '../../shared/Utils';

class Ops extends Component {
    constructor(props){
        super(props);

        this.state = {
            error: null
        };
    }

    componentDidMount() {
        var csrftok = $('meta[name="csrf-token"]')[0].attributes.content.value;
        this.props.firebase.currentUser().getIdToken(false).then((token) => {
            fetch(
                ROUTES.BASE_URL + "/users/login",
                {
                    headers: {
                        "Authorization" : `Bearer ${token}`,
                        'X-CSRF-Token': csrftok
                    },
                    method: 'POST',
                    credentials: 'include'
                }
            ).then(res => {
                if (!res.ok) {
                    this.setState({error: {message: 'Could not successfully open Ops.'}});
                }
            }).catch(error => this.setState({error}))
        });
    }

    render() {
        return (
            <AuthUserContext.Consumer>
            {authUser =>
                (
                    <div>
                        {Utils.renderError(this.state.error)}

                        <p><a href={ROUTES.BASE_URL + '/admin'} target="_blank">Go To Ops</a></p>
                    </div>
                )
            }
            </AuthUserContext.Consumer>
        )
    }
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(Ops);
