$(document).ready(function() {

var hideMenu = function () { $('#dashboard-panel-menu').addClass('hide'); } 

    $(".account-link").on('click', function() {
        console.log("account Link clicked")
        hideMenu();
        $("#pages .page:not('.hide')").stop().fadeOut('fast', function() {
            $(this).addClass('hide');
            $('#account').fadeIn('slow').removeClass('hide');
        });
    });

    $("#orders-link").on('click', function() {
        hideMenu();
        $("#pages .page:not('.hide')").stop().fadeOut('fast', function() {
            $(this).addClass('hide');
            $('#orders').fadeIn('slow').removeClass('hide');
        });
    });

    $("#subscriptions-link").on('click', function() {
        hideMenu();
        $("#pages .page:not('.hide')").stop().fadeOut('fast', function() {
            $(this).addClass('hide');
            $('#subscriptions').fadeIn('slow').removeClass('hide');
        });
    });

    // ARMEN
    $("#change-phone-link").on('click', function() {
        console.log("change-phone-link clicked")
        hideMenu();
        $("#pages .page:not('.hide')").stop().fadeOut('fast', function() {
            $(this).addClass('hide');
            $('#change-phone').fadeIn('slow').removeClass('hide');
        });
    });

    $("#confirm-phone-link").on('click', function() {
        console.log("confirm-phone clicked")
        hideMenu();
        $("#pages .page:not('.hide')").stop().fadeOut('fast', function() {
            $(this).addClass('hide');
            $('#confirm-phone').fadeIn('slow').removeClass('hide');
        });
    });


    // var showSection = function(divId) {
    //   $("#pages .page:not('.hide')").stop().fadeOut('fast', function() {
    //       $(this).addClass('hide');
    //       $(divId).fadeIn('slow').removeClass('hide');
    //       });
    //     }
    
    // $('#account-link').on('click',showSection('#account'));
    // $('#orders-link').on('click',showSection('#orders'));
    // $('#subscritions-link').on('click',showSection('#subscritions'));

});