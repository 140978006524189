import React, {Component} from 'react';
import { HeadingXSmall, LabelMedium, ParagraphMedium, ParagraphXSmall } from 'baseui/typography';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { AuthUserContext, withAuthorization } from '../Session';
import Presence from '../Presence';

const usernameProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-end',
            }),
        },
    },
};

const overallProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                borderBottomWidth: '1px',
                borderBottomStyle: 'solid',
                borderBottomColor: '#7f7f7f47',
                margin: '0px 0px 0px 0px',
                padding: '0px 0px 24px 0px'
            }),
        },
    },
};

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: this.props.username,
            socket: this.props.socket,
            protocol: this.props.protocol,
            num_users: this.props.num_users
        };
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.num_users !== prevProps.num_users) {
            this.setState({num_users: this.props.num_users});
        }
    }

    render = () => {
        return <AuthUserContext.Consumer>
            {authUser => (
                <FlexGrid {...overallProps}></FlexGrid>
            )}
        </AuthUserContext.Consumer>
    }
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(Header);
