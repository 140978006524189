import React, {Component} from 'react';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import * as ROUTES from '../Constants';
import Utils from '../../shared/Utils';
import UsStateSelect from '../../shared/Utils/us_states_select';
import Request from '../../shared/Request';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import {ParagraphSmall, LabelLarge, ParagraphLarge, ParagraphMedium} from 'baseui/typography';
import {Input} from 'baseui/input';
import { Textarea } from "baseui/textarea";
import {Button, KIND, SIZE, SHAPE} from 'baseui/button';
import { FileUploader } from "baseui/file-uploader";
import Logger from '../../shared/Utils/logger';
import MpsModal from '../../shared/Utils/modal';
import {ButtonGroup, SIZE as BGSize, SHAPE as BGShape, MODE} from 'baseui/button-group';
import PasswordChangeForm from '../../shared/Password/change';
import AddressPicker from '../../shared/Address';
import { Tag, SIZE as TagSize } from "baseui/tag";
import { StyledLink } from "baseui/link";
import { DatePicker } from "baseui/datepicker";
import { parseISO, format, formatDistance, formatRelative, subDays } from 'date-fns';
import { Username } from '../../shared/Users';
import content from './content';

const rightProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
            }),
        },
    },
};

const narrowProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                width: '50px',
                flexGrow: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
            }),
        },
    },
};

const stateProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                width: '75px',
                flexGrow: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
            }),
        },
    },
};

const licenseProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                width: 'fit-content',
                marginRight:'20px',
                flexGrow: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
            }),
        },
    },
};

const PROFILE = 0;
const CREDENTIALS = 1;
const PAYMENTS = 2;
//const ID_PROOFING = 3;

class Account extends Component{
    constructor(props){
        super(props);

        this.state = {
            isLoaded: false,
            error: null,
            success: null,

            name: '',
            username: '',
            // facvIcon: '', // an attachemnt
            professional_email: '',
            mobile_phone: '',
            biography: '',
            specialty_list: '',
            n_p_i: '',
            dea_number: '',
            business_name: '',
            business_address: '',

            npdb_url: "",
            npdb_self_query: [],
            npdb_self_query_fail: [],
            npdb_self_query_error: null,

            malpractice_coverage_url: '',
            malpractice_coverage: [],
            malpractice_coverage_fail: [],
            malpractice_coverage_error: null,

            edited_license_states: [this.newLicenseStateEntry()],
            view_license_states: [],
            edit_licenses: false,

            selectedTab: PROFILE,

            w9_url: '',
            w9: [],
            w9_fail: [],
            w9_error: null,
            bank_name: '',
            bank_account_name: '',
            bank_routing_number: '',
            confirm_bank_routing_number: '',
            confirm_bank_routing_number_error: false,
            bank_account_number: '',
            confirm_bank_account_number: '',
            confirm_bank_account_number_error: false,

            business_name: '',
            business_address: {},
            new_business_address: {},
            show_address_form: false,

            content: this.getContent()
        };

        this.deleteStateLicenses = [];
    }

    getContent = () => {
        return content('en');
    };

    changeLicenseHandler = (event, idx) => {
        let value = event.target.value;
        let name = event.target.name;
        this.setState((prevState) => {
            let edited_license_states = [...prevState.edited_license_states];
            edited_license_states[idx][name] = value;
            return({ edited_license_states});
        });
    };

    changeLicenseExpirationHandler = (date, idx) => {
        let value = Array.isArray(date) ? date[0] : date;

        Logger.log("Date value: ");
        Logger.log(value);
        Logger.log(Array.isArray(date));
        Logger.log(typeof date);

        this.setState((prevState) => {
            let edited_license_states = prevState.edited_license_states;
            edited_license_states[idx]['expiration'] = value;
            return({ edited_license_states });
        });
    };

    changeHandler = (event) => {
        let state_settings = { [event.target.name]: event.target.value };
        if (event.target.name == 'confirm_bank_account_number') {
            if (!this.confirmAccountNumber(event.target.value)) {
                if (this.state.confirm_bank_account_number_error){
                    state_settings.error = null;
                } else {
                    state_settings.error = {message: 'Account numbers do not match'};
                    state_settings.confirm_bank_account_number_error = true;
                }
            } else {
                state_settings.error = null;
                state_settings.confirm_bank_account_number_error = false;
            }
        } else if (event.target.name == 'bank_account_number'){
            if(this.state.confirm_bank_account_number !== '' && event.target.value !== this.state.confirm_bank_account_number){
                if (this.state.confirm_bank_account_number_error) {
                    state_settings.error = null;
                } else {
                    state_settings.error = {message: 'Account numbers do not match'};
                    state_settings.confirm_bank_account_number_error = true;
                }
            }  else {
                state_settings.error = {message: 'Account numbers do not match'};
                state_settings.confirm_bank_account_number_error = true;
            }
        } else if (event.target.name == 'confirm_bank_routing_number') {
            if (!this.confirmRoutingNumber(event.target.value)){
                if (this.state.confirm_bank_routing_number_error){
                    state_settings.error = null;
                } else {
                    state_settings.error = {message: 'Routing number do not match'};
                    state_settings.confirm_bank_routing_number_error = true;
                }
            } else {
                state_settings.error = null;
                state_settings.confirm_bank_routing_number_error = false;
            }
        } else if (event.target.name == 'bank_routing_number'){
            if (this.state.confirm_bank_routing_number !== '' && event.target.value !== this.state.confirm_bank_routing_number){
                if (this.state.confirm_bank_routing_number_error) {
                    state_settings.error = null;
                } else {
                    state_settings.error = {message: 'Routing numbers do not match'};
                    state_settings.confirm_bank_routing_number_error = true;
                }
            } else {
                state_settings.error = null;
                state_settings.confirm_bank_routing_number_error = false;
            }
        }

        this.setState(state_settings);
    }

    changeFileHandler = (event) => this.setState({ [event.target.name]: event.target.files[0], ...Utils.clearAllNotifications() });

    submitHandler = (event) => {
        event.preventDefault();

        Logger.log("Submitting...");

        Logger.log(this.state.view_license_states);
        const data = new FormData();

        data.append('name', this.state.name);
        data.append('username', this.state.username);
        data.append('professional_email', this.state.professional_email);
        data.append('mobile_phone', this.state.mobile_phone);
        data.append('blurb', this.state.biography);
        data.append('specialty_list', this.state.specialty_list);
        data.append('n_p_i', this.state.n_p_i);
        data.append('dea_number', this.state.dea_number);
        data.append('bank_name', this.state.bank_name);
        data.append('bank_account_name', this.state.bank_account_name);
        data.append('bank_routing_number', this.state.bank_routing_number);
        data.append('bank_account_number', this.state.bank_account_number);
        data.append('business_name', this.state.business_name);
        data.append('business_address', this.state.business_address);

        this.state.view_license_states.forEach(st => {
            if (st.id) {
                data.append('state_licenses_attributes[][id]', st.id);
            }
            data.append('state_licenses_attributes[][state]', st.state);
            data.append('state_licenses_attributes[][license_number]', st.license_number);
            data.append('state_licenses_attributes[][expiration]', st.expiration);
            if (st._destroy) {
                data.append('state_licenses_attributes[][_destroy]', st._destroy);
            }
        });

        if (this.state.w9.length == 1){
            data.append('scanned_w9', this.state.w9[0]);
        }

        if (this.state.npdb_self_query.length == 1){
            data.append('npdb_self_query', this.state.npdb_self_query[0]);
        }

        if(this.state.malpractice_coverage.length == 1){
            data.append('malpractice_coverage', this.state.malpractice_coverage[0]);
        }

        Request.put({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/physician_account/" + this.props.firebase.currentUser().email,
            body: data,
            callbackSuccess: (result) => {

                let sorted_licenses = null;

                if (result.data.state_licenses != null){
                    sorted_licenses = result.data.state_licenses.sort((a, b) => {return a.state.toLowerCase() > b.state.toLowerCase() ? 1 : -1});
                    sorted_licenses = sorted_licenses.map(license => {
                        license.expiration = parseISO(license.expiration);
                        return license;
                    });
                }

                this.setState({success: result,
                    edited_license_states: sorted_licenses == null ? [this.newLicenseStateEntry()] : sorted_licenses,
                    view_license_states: sorted_licenses == null ? [this.newLicenseStateEntry()] : sorted_licenses,
                    ...Utils.clearAllNotificationsExceptSuccess()})
            },
            callbackError: (error) => {
                this.setState({error})
            }
        })
    };

    componentDidMount() {
        Request.get({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/physician_account",
            callbackSuccess: (result) => {
                let sorted_licenses = null;

                if (result.data.state_licenses != null){
                    sorted_licenses = result.data.state_licenses.sort((a, b) => {return a.state.toLowerCase() > b.state.toLowerCase() ? 1 : -1});
                    sorted_licenses = sorted_licenses.map(license => {
                        license.expiration = parseISO(license.expiration);
                        return license;
                    });
                }

                this.setState({
                    isLoaded: true,

                    name: result.data.name == null ? '' : result.data.name,
                    username: result.data.username == null ? '' : result.data.username,
                    // favIcon: result.data.favicon,
                    professional_email: result.data.professional_email == null ? '' : result.data.professional_email,
                    mobile_phone: result.data.mobile_phone == null ? '' : result.data.mobile_phone,
                    biography: result.data.blurb == null ? '' : result.data.blurb,
                    specialty_list: result.data.specialty_list == null ? '' : result.data.specialty_list,
                    n_p_i: result.data.n_p_i == null ? '' : result.data.n_p_i,
                    dea_number: result.data.dea_number == null ? '' : result.data.dea_number,
                    npdb_url: result.data.npdb_url == null ? "" : result.data.npdb_url,
                    malpractice_coverage_url: result.data.malpractice_coverage_url == null ? '' : result.data.malpractice_coverage_url,
                    edited_license_states: sorted_licenses == null ? [this.newLicenseStateEntry()] : sorted_licenses,
                    view_license_states: sorted_licenses == null ? [this.newLicenseStateEntry()] : sorted_licenses,
                    csrf_token: result.data.csrf_token == null ? '' : result.data.csrf_token,
                    csrf_form_token: result.data.csrf_form_token == null ? '' : result.data.csrf_form_token,

                    w9_url: result.data.scanned_w9_url == null ? '' : result.data.scanned_w9_url,
                    bank_name: result.data.bank_name == null ? '' : result.data.bank_name,
                    bank_account_name: result.data.bank_account_name == null ? '' : result.data.bank_account_name,
                    bank_routing_number: result.data.bank_routing_number == null ? '' : result.data.bank_routing_number,
                    confirm_bank_routing_number: result.data.bank_routing_number == null ? '' : result.data.bank_routing_number,
                    bank_account_number: result.data.bank_account_number == null ? '' : result.data.bank_account_number,
                    confirm_bank_account_number: result.data.bank_account_number == null ? '' : result.data.bank_account_number,
                    business_name: result.data.business_name == null ? '' : result.data.business_name,
                    business_address: result.data.business_address == null ? '' : result.data.business_address
                })

                Logger.log("Account: ");
                Logger.log(result);
            },
            callbackError: (error) => {
                this.setState({isLoaded: true, error})
            }
        })
    }

    newLicenseStateEntry = () => { return {id: '', state: '', license_number: '', expiration: ''}};
    addLicense = (event) => {
        event.preventDefault();

        this.setState((prevState) => ({
            edited_license_states: [...prevState.edited_license_states, this.newLicenseStateEntry()]
            , ...Utils.clearAllNotifications()
        }));
    };

    deleteStateLicense = (event, idx) => {
        event.preventDefault();

        this.setState(prevState => ({edited_license_states: prevState.edited_license_states.map((sl, item_idx) => (item_idx === idx ? { ...sl, _destroy: 1 } : sl)), ...Utils.clearAllNotifications }))
    }

    openLicenseModal = () => {
        this.setState({edit_licenses: true, ...Utils.clearAllNotifications()});
    }

    closeLicenseModal = () => {
        this.setState({edit_licenses: false});
    }

    cancelEditLicenses = () => {
        // Set license_states = view_license_states
        this.setState({edited_license_states: this.state.view_license_states, ...Utils.clearAllNotifications()});
        this.closeLicenseModal();
    };

    saveEditLicenses = () => {
        // Set view_license_states = edited_license_states
        this.setState({
            view_license_states: this.state.edited_license_states.sort((a, b) => {return a.state.toLowerCase() > b.state.toLowerCase() ? 1 : -1}), edit_licenses: false});
    };

    // NPDB
    handleFileUpload = (acceptedFiles, rejectedFiles) => {
        if (this.canUploadMoreNpdb(acceptedFiles.length)) {
            this.setState({
                npdb_self_query: acceptedFiles,
                npdb_self_query_fail: rejectedFiles,
                ...Utils.clearAllNotifications()
            });
        }
    }

    fileUploadRetry = () => {
        this.setState({npdb_self_query: [], ...Utils.clearAllNotifications()});
    };

    canUploadMoreNpdb = () => {
        return this.state.npdb_self_query.length == 0;
    }

    // malpractice_coverage
    handleMalpracticeFileUpload = (acceptedFiles, rejectedFiles) => {
        if (this.canUploadMoreMalpractice(acceptedFiles.length)) {
            this.setState({
                malpractice_coverage: acceptedFiles,
                malpractice_coverage_fail: rejectedFiles,
                ...Utils.clearAllNotifications()
            });
        }
    }

    malpracticeFileUploadRetry = () => {
        this.setState({malpractice_coverage: [], ...Utils.clearAllNotifications()});
    };

    canUploadMoreMalpractice = () => {
        return this.state.malpractice_coverage.length == 0;
    }

    // W9
    handleW9FileUpload = (acceptedFiles, rejectedFiles) => {
        if (this.canUploadMoreW9(acceptedFiles.length)) {
            this.setState({
                w9: acceptedFiles,
                w9_fail: rejectedFiles,
                ...Utils.clearAllNotifications()
            });
        }
    }

    fileUploadRetryW9 = () => {
        this.setState({w9: [], ...Utils.clearAllNotifications()});
    };

    canUploadMoreW9 = () => {
        return this.state.w9.length == 0;
    }

    setSelectedTab = (event, index) => {
        this.setState({selectedTab: index, ...Utils.clearAllNotifications()});
    };

    doNotAllowSubmittion = () => {
        return this.state.error !== null || this.state.confirm_bank_account_number_error || this.state.confirm_bank_routing_number_error;
    };

    confirmAccountNumber = (confirm_bank_account_number) => {
        return this.state.bank_account_number === confirm_bank_account_number;
    };

    confirmRoutingNumber = (confirm_bank_routing_number) => {
        return this.state.bank_routing_number === confirm_bank_routing_number;
    };

    getAddress = (address) => {
        this.state.new_business_address = Utils.addressPickerToString(address);
    };

    renderItemBody = (label, description, input) => {
        return <FlexGridItem>
            <LabelLarge style={{margin: 0}}>{label}</LabelLarge>
            <ParagraphSmall style={{margin: 0, marginTop: '4px', marginBottom: '10px', color: '#666666'}}>{description}</ParagraphSmall>
            {input}
            </FlexGridItem>
    };

    businessInformation = () => {
        return <FlexGrid flexGridRowGap={"scale700"}>
            <FlexGridItem>
                {this.state.content.business_info_name_description}
                <Input value={this.state.business_name} name="business_name" onChange={this.changeHandler}/>
            </FlexGridItem>
            <FlexGridItem>
                {this.state.content.business_info_address_description}
                {this.state.business_address ?
                <FlexGrid flexGridColumnCount={2} flexGridColumnGap={"scale400"}>
                    <FlexGridItem>
                        <Input value={this.state.business_address} name="business_address" disabled/>
                    </FlexGridItem>
                    <FlexGridItem {...narrowProps}>
                        <Button shape={SHAPE.pill} size={SIZE.compact} kind={KIND.secondary} onClick={this.openAddressForm}>Edit</Button>
                    </FlexGridItem>
                </FlexGrid> : <Button shape={SHAPE.pill} size={SIZE.compact} kind={KIND.primary} onClick={this.openAddressForm}>Add</Button>}
            </FlexGridItem>
        </FlexGrid>
    };

    closeAddressForm = () => {
        this.setState({
            show_address_form: false,
            new_business_address: '',
            ...Utils.clearAllNotifications()
        });
    };

    useNewAddress = () => {
        this.setState({business_address: this.state.new_business_address, show_address_form: false,});
    };

    openAddressForm = () => {
        this.setState({show_address_form: true});
    };


    openFileOrLink = (url, upload, event) => {
      if (!(upload.length > 0)) {
        event.stopPropagation();
        event.preventDefault();
        Request.post_to_new_window({
          firebase: this.props.firebase,
          route: url
        });
      }
    };


    renderNewAddressForm = () => {
        if (this.state.show_address_form) {
            return this.state.show_address_form ? <MpsModal
            open={this.state.show_address_form}
            autoFocus={false}
            callback={this.useNewAddress}
            callbackCancel={this.closeAddressForm}
            withFooter={true}
            title={Utils.renderTitleCenter("Add new address")}
            body={<AddressPicker callback={this.getAddress}/>} /> : null;
        }
    };

    renderTabContent = () => {
        if (this.state.selectedTab == PROFILE) {
            return <FlexGrid flexGridRowGap={"scale700"} marginTop="20px">
                <FlexGridItem>
                    <Username username={this.state.username} description={this.state.content.profile_username_description}/>
                </FlexGridItem>
                <FlexGridItem>
                    <LabelLarge style={{margin: 0}}>{this.state.content.profile_account_email}</LabelLarge>
                    <ParagraphSmall style={{margin: 0, marginTop: '5px'}}>{this.props.firebase.getEmail()}</ParagraphSmall>
                </FlexGridItem>
                <FlexGridItem>
                    <LabelLarge style={{margin: 0}}>{this.state.content.profile_account_phone}</LabelLarge>
                    <ParagraphSmall style={{margin: 0, marginTop: '5px'}}>{Utils.renderPhone(this.props.firebase.getPhoneNumber())}</ParagraphSmall>
                </FlexGridItem>
                    {this.renderItemBody(
                        this.state.content.profile_legal_name_title,
                        null,
                        <Input value={this.state.name} name="name" onChange={this.changeHandler}/>)}

                    {this.renderItemBody(
                        this.state.content.profile_legal_contact_email,
                        this.state.content.profile_legal_contact_email_description,
                        <Input name="professional_email" value={this.state.professional_email} onChange={this.changeHandler}/>
                    )}
                    {this.renderItemBody(
                        this.state.content.profile_about_title,
                        this.state.content.profile_about_description,
                        <Textarea
                        value={this.state.biography}
                        name="biography"
                        onChange={this.changeHandler}
                        />
                    )}
                    {this.renderItemBody(
                        this.state.content.profile_specialties_title,
                        this.state.content.profile_specialties_description,
                        <Textarea
                        value={this.state.specialty_list}
                        name="specialty_list"
                        onChange={this.changeHandler}
                        />
                    )}
                <FlexGridItem>
                    <PasswordChangeForm />
                </FlexGridItem>
            </FlexGrid>
        } else if (this.state.selectedTab == CREDENTIALS) {
            return <FlexGrid flexGridRowGap={"scale700"} marginTop="20px">
                {this.renderItemBody(
                    this.state.content.credentials_npi_number_title,
                    this.state.content.credentials_npi_number_description,
                    <Input name="n_p_i" value={this.state.n_p_i} onChange={this.changeHandler}/>
                )}

                {this.renderItemBody(
                    this.state.content.credentials_dea_number_title,
                    this.state.content.credentials_dea_number_description,
                    <Input name="dea_number" value={this.state.dea_number} onChange={this.changeHandler}/>
                )}

                {
                this.renderItemBody(
                    this.state.content.credentials_npdb_query_title,
                    this.state.content.credentials_npdb_query_description,
                    this.state.npdb_url != '' || this.state.npdb_self_query.length > 0
                        ?   <FlexGrid flexGridColumnCount={2}>
                                <FlexGridItem>
                                   <ParagraphSmall>{this.state.npdb_url != '' ? Utils.getFileName(this.state.npdb_url) : Utils.getFileName(this.state.npdb_self_query[0].name)}</ParagraphSmall>
                                </FlexGridItem>
                                <FlexGridItem>
                                    <Button
                                        kind={KIND.secondary}
                                        shape={SHAPE.pill}
                                        size={SIZE.compact}
                                        onClick={(event) => this.setState({npdb_url: "", npdb_self_query: [], ...Utils.clearAllNotifications()})}
                                    >Update</Button>
                                </FlexGridItem>
                                {this.state.npdb_url != '' ?
                                <FlexGridItem>
                                    <Button
                                        $as="a"
                                        onClick={(e) => {openFileOrLink(this.state.npdb_url, this.state.npdb_self_query, e)}}
                                        href={this.state.npdb_self_query.length > 0 ? this.state.npdb_self_query[0] : this.state.npdb_url}
                                        target="_blank"
                                        kind={KIND.secondary}
                                        shape={SHAPE.pill}
                                        size={SIZE.compact}
                                    >View</Button>
                                </FlexGridItem> : null
                                }
                            </FlexGrid>
                        : <FileUploader
                            name='npdb_uploaded'
                            accept=".pdf"
                            maxSize={10000000}
                            onRetry={this.fileUploadRetry}
                            onDrop={(acceptedFiles, rejectedFiles) => {
                                this.handleFileUpload(acceptedFiles, rejectedFiles);
                            }}
                            multiple
                            errorMessage={this.state.npdb_self_query_error}
                        />
                )}

                {this.renderItemBody(
                    this.state.content.credentials_malpractice_title,
                    this.state.content.credentials_malpractice_description,
                    this.state.malpractice_coverage_url != '' || this.state.malpractice_coverage.length > 0
                        ?   <FlexGrid flexGridColumnCount={2}>
                                <FlexGridItem>
                                    <ParagraphSmall>{this.state.malpractice_coverage_url != '' ? Utils.getFileName(this.state.malpractice_coverage_url) : Utils.getFileName(this.state.malpractice_coverage[0].name)}</ParagraphSmall>
                                </FlexGridItem>
                                <FlexGridItem>
                                    <Button
                                        kind={KIND.secondary}
                                        shape={SHAPE.pill}
                                        size={SIZE.compact}
                                        onClick={(event) => this.setState({malpractice_coverage_url: "", malpractice_coverage: [], ...Utils.clearAllNotifications()})}
                                    >Update</Button>
                                </FlexGridItem>
                                {this.state.malpractice_coverage_url != '' ?
                                <FlexGridItem>
                                    <Button
                                        $as="a"
                                        onClick={(e) => {openFileOrLink(this.state.malpractice_coverage_url, this.state.malpractice_coverage, e)}}
                                        href={this.state.malpractice_coverage.length > 0 ? this.state.malpractice_coverage[0] : this.state.malpractice_coverage_url}
                                        target="_blank"
                                        kind={KIND.secondary}
                                        shape={SHAPE.pill}
                                        size={SIZE.compact}
                                    >View</Button>
                                </FlexGridItem> : null
                                }
                            </FlexGrid>
                        : <FileUploader
                            name='malpractice_uploaded'
                            accept=".pdf"
                            maxSize={10000000}
                            onRetry={this.malpracticeFileUploadRetry}
                            onDrop={(acceptedFiles, rejectedFiles) => {
                                this.handleMalpracticeFileUpload(acceptedFiles, rejectedFiles);
                            }}
                            multiple
                            errorMessage={this.state.malpractice_coverage_error}
                        />
                )}

                {this.renderItemBody(
                    this.state.content.credentials_state_license_title,
                    this.state.content.credentials_state_license_description,
                    this.state.view_license_states.filter(val => !val._destroy).map((val, idx) => <FlexGrid flexGridColumnCount={3} key={idx}>
                        <FlexGridItem {...stateProps}><Tag closeable={false} size={TagSize.medium}>{val.state}</Tag></FlexGridItem>
                        <FlexGridItem {...licenseProps}><ParagraphLarge>{val.license_number}</ParagraphLarge></FlexGridItem>
                        <FlexGridItem><ParagraphLarge>{Utils.render_mm_dd_yyyy_date(val.expiration)}</ParagraphLarge></FlexGridItem>
                    </FlexGrid>)
                )}

                <FlexGridItem>
                    <Button
                        kind={KIND.secondary}
                        shape={SHAPE.pill}
                        size={SIZE.compact}
                        onClick={this.openLicenseModal}
                    >{this.state.content.license_edit_button}</Button>
                </FlexGridItem>
            </FlexGrid>
        } else if (this.state.selectedTab == PAYMENTS) {
            return <FlexGrid flexGridRowGap={"scale700"} marginTop="20px">
                <FlexGridItem>
                    {this.state.content.payments_business_info_title}
                    {this.state.content.payments_business_info_description}
                    {this.state.w9_url != '' || this.state.w9_url.length > 0
                        ?   <FlexGrid flexGridColumnCount={2}>
                                <FlexGridItem>
                                    {this.state.w9_url != '' ? Utils.getFileName(this.state.w9_url) : Utils.getFileName(this.state.w9[0].name)}
                                </FlexGridItem>
                                <FlexGridItem>
                                    <Button
                                        kind={KIND.secondary}
                                        shape={SHAPE.pill}
                                        size={SIZE.compact}
                                        onClick={(event) => this.setState({w9_url: "", w9: [], ...Utils.clearAllNotifications()})}
                                    >Update</Button>
                                </FlexGridItem>
                                {this.state.w9_url != '' ?
                                <FlexGridItem>
                                    <Button
                                        $as="a"
                                        onClick={(e) => {openFileOrLink(this.state.w9_url, this.state.w9, e)}}
                                        href={this.state.w9.length > 0 ? this.state.w9[0] : this.state.w9_url}
                                        target="_blank"
                                        kind={KIND.secondary}
                                        shape={SHAPE.pill}
                                        size={SIZE.compact}
                                    >View</Button>
                                </FlexGridItem> : null
                                }
                            </FlexGrid>
                        : <FileUploader
                            name='w9_uploaded'
                            accept=".pdf"
                            maxSize={10000000}
                            onRetry={this.fileUploadRetryW9}
                            onDrop={(acceptedFiles, rejectedFiles) => {
                                this.handleW9FileUpload(acceptedFiles, rejectedFiles);
                            }}
                            multiple
                            errorMessage={this.state.w9_error}
                        />
                    }
                </FlexGridItem>
                <FlexGridItem>
                    {this.businessInformation()}
                </FlexGridItem>
                <FlexGridItem>
                    <FlexGrid flexGridRowGap={"scale400"}>
                        <FlexGridItem>
                            {this.state.content.payments_bank_information_title}
                            {this.state.content.payments_bank_information_description}
                        </FlexGridItem>
                        <FlexGridItem>
                            {this.state.content.payments_bank_name_title}
                            <Input name="bank_name" value={this.state.bank_name} onChange={this.changeHandler} />
                        </FlexGridItem>
                        <FlexGridItem>
                            {this.state.content.payments_name_on_account_title}
                            <Input name="bank_account_name" value={this.state.bank_account_name} onChange={this.changeHandler} />
                        </FlexGridItem>
                        <FlexGridItem>
                            <FlexGrid flexGridColumnCount={"2"} flexGridColumnGap={"scale200"} >
                                <FlexGridItem>
                                    {this.state.content.payments_routing_number_title}
                                    <Input name="bank_routing_number" value={this.state.bank_routing_number} error={this.state.confirm_bank_routing_number_error} onChange={this.changeHandler} />
                                </FlexGridItem>
                                <FlexGridItem>
                                    {this.state.content.payments_routing_confirm_number_title}
                                    <Input name="confirm_bank_routing_number" value={this.state.confirm_bank_routing_number} error={this.state.confirm_bank_routing_number_error} onChange={this.changeHandler} />
                                </FlexGridItem>
                            </FlexGrid>
                        </FlexGridItem>
                        <FlexGridItem>
                            <FlexGrid flexGridColumnCount={"2"} flexGridColumnGap={"scale200"} >
                                <FlexGridItem>
                                    {this.state.content.payments_account_number_title}
                                    <Input name="bank_account_number" value={this.state.bank_account_number} error={this.state.confirm_bank_account_number_error} onChange={this.changeHandler} />
                                </FlexGridItem>
                                <FlexGridItem>
                                    {this.state.content.payments_account_number_confirm_title}
                                    <Input name="confirm_bank_account_number" value={this.state.confirm_bank_account_number} error={this.state.confirm_bank_account_number_error} onChange={this.changeHandler} />
                                </FlexGridItem>
                            </FlexGrid>
                        </FlexGridItem>
                    </FlexGrid>
                </FlexGridItem>
            </FlexGrid>
        } {/*else if (this.state.selectedTab == ID_PROOFING) {
            return <FlexGrid flexGridRowGap={"scale700"} marginTop="20px">
                <FlexGridItem>{this.state.content.id_proofing_title}</FlexGridItem>
                <FlexGridItem>{this.state.content.id_proofing_description}</FlexGridItem>
                <FlexGridItem>{this.state.content.id_proofing_hint}</FlexGridItem>
                <FlexGridItem><Button
                                $as="a"
                                href={"https://api.id.me/saml/SingleSignOnService?EntityID=https%3A%2F%2Fclinician.changehealthcare.com&amp;Binding=urn%3Aoasis%3Anames%3Atc%3ASAML%3A2.0%3Abindings%3AHTTP-POST&amp;AuthnContext=erx"}
                                target="_blank"
                                kind={KIND.primary}
                                shape={SHAPE.pill}
                                size={SIZE.default}
                            >Register with ID.me</Button></FlexGridItem>
            </FlexGrid>
        }*/}
    };

    render() {
        return (
            <AuthUserContext.Consumer>
                {authUser =>
                    (
                        <div>
                            {Utils.renderSuccess(this.state.success)}
                            {Utils.renderError(this.state.error)}
                            {Utils.renderTitleCenter("Profile")}
                            <FlexGrid maxWidth="800px" style={{margin: 'auto'}}>
                                <FlexGridItem>
                                    <ButtonGroup
                                        size={BGSize.compact}
                                        shape={BGShape.pill}
                                        mode={MODE.radio}
                                        selected={this.state.selectedTab}
                                        onClick={(event, index) => {
                                            this.setSelectedTab(event, index);
                                        }}
                                    >
                                        <Button style={{marginLeft: 0}}>{this.state.content.account_title}</Button>
                                        <Button style={{marginLeft: 0}}>{this.state.content.credentials_title}</Button>
                                        <Button style={{marginLeft: 0}}>{this.state.content.payments_title}</Button>
                                        {/*<Button style={{marginLeft: 0}}>{this.state.content.id_proofing_button}</Button>*/}
                                    </ButtonGroup>
                                </FlexGridItem>
                                <FlexGridItem>{this.renderTabContent()}</FlexGridItem>
                                <FlexGridItem>
                                    <FlexGrid style={{marginTop: '30px'}}>
                                        <FlexGridItem {...rightProps}>
                                            {/*this.state.selectedTab != ID_PROOFING ?*/ <Button
                                            disabled={this.doNotAllowSubmittion()}
                                            onClick={this.submitHandler}>Save changes</Button> /*: null*/}
                                        </FlexGridItem>
                                    </FlexGrid>
                                </FlexGridItem>
                            </FlexGrid>

                            <MpsModal
                                open={this.state.edit_licenses}
                                callback={this.saveEditLicenses}
                                callbackCancel={this.cancelEditLicenses}
                                title={Utils.renderTitleCenter(this.state.content.state_license_modal_title)}
                                withFooter={true}
                                body={
                                    <div>
                                    {this.state.edited_license_states.map((val, idx) => {
                                        if (!val._destroy) {
                                        return(
                                            <FlexGrid marginBottom="scale800" flexGridRowGap="scale200" key={idx}>
                                                <FlexGridItem>
                                                <UsStateSelect
                                                    name="state"
                                                    value={val.state}
                                                    onChange={(event) => this.changeLicenseHandler(event, idx)}
                                                />
                                                </FlexGridItem>
                                                <FlexGridItem>
                                                <Input
                                                    name="license_number"
                                                    value={val.license_number}
                                                    onChange={(event) => this.changeLicenseHandler(event, idx)}
                                                    placeholder={this.state.content.license_number}
                                                />
                                                </FlexGridItem>
                                                <FlexGridItem>
                                                <DatePicker
                                                    value={[val.expiration]}
                                                    onChange={({date}) => this.changeLicenseExpirationHandler(date, idx)}
                                                />
                                                </FlexGridItem>
                                                <FlexGridItem>
                                                <Button
                                                    kind={KIND.secondary}
                                                    shape={SHAPE.pill}
                                                    size={SIZE.compact}
                                                    onClick={(event) => this.deleteStateLicense(event, idx) }
                                                >Delete</Button>
                                                </FlexGridItem>
                                            </FlexGrid>
                                        );
                                        }
                                    })}
                                    <Button kind={KIND.secondary} shape={SHAPE.pill} size={SIZE.compact} onClick={this.addLicense}>+ State</Button>
                                    </div>
                                }
                            />

                            {this.renderNewAddressForm()}
                        </div>
                    )
                }
            </AuthUserContext.Consumer>
        );
    }
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(Account);
