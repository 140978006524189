import React, {Component} from 'react';
import Logger from '../../../shared/Utils/logger';
import MpsModal from '../../../shared/Utils/modal';
import Utils from '../../../shared/Utils';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import {Button, KIND, SIZE, SHAPE} from 'baseui/button';
import content from './content';
import { AuthUserContext, withAuthorization } from '../../../shared/Session';
import { Textarea } from "baseui/textarea";
import * as ROUTES from '../../Constants';
import Request from '../../../shared/Request';

class EncounterNote extends Component {
    constructor(props){
        super(props);

        this.state = {
            error: null,
            protocol_subscription: this.props.protocol_subscription,
            content: this.getContent(),
            encounter_note: "",
            encounter_note_submitting: false,
            show: false
        }
    }

    getContent = () => {
        return content('en');
    };

    closeEncounterNote = () => {
        this.setState({show: false, encounter_note: ''});
    };

    openEncounterNote = (e) => {
        this.setState({show: true});
    };

    handleEncounterNoteChange = (event) => {
        this.setState({encounter_note: event.target.value});
    };

    submit = () => {
        this.setState({encounter_note_submitting: true});

        const data = new FormData();
        data.append('patient_uuid', this.props.patient_uuid);
        data.append('protocol_number', this.props.protocol_number);
        data.append('soap_note', this.state.encounter_note);

        Request.post({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/encounter_note",
            body: data,
            callbackSuccess: (result) => {
                this.setState({
                    show: false,
                    encounter_note: '',
                    encounter_note_submitting: false
                });
                if (this.props.callback) {
                    this.props.callback(result.encounter_note);
                }
            },
            callbackError: (error) => {
                this.setState({
                    encounter_note_submitting: false,
                    isLoaded: true,
                    error
                })
            }
        })
    };

    isProtocolActive = () => {
        return this.props.protocol && this.props.protocol.status === 'active';
    };

    render() {
        return <AuthUserContext.Consumer>
            {authUser =>
                (
                    <>
                    {this.isProtocolActive() ? <Button
                        kind={KIND.secondary}
                        size={SIZE.compact}
                        shape={SHAPE.pill}
                        onClick={this.openEncounterNote}
                        startEnhancer={() => <img style={{height:'16px'}} src={window.matchMedia("(prefers-color-scheme: light)").matches ?  "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_16/v1681361177/encounternote-black-01_tc9nef.png" : "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_16,q_auto/v1681361178/encounternote-white-01_wnucnh.png"}/>}
                        overrides={{
                            StartEnhancer: {
                                style: ({ $theme }) => ({
                                  marginRight: '3px'
                                })
                            }
                        }}
                    >{this.state.content.encounter_note_button}</Button> : <Button
                    kind={KIND.secondary}
                    size={SIZE.compact}
                    shape={SHAPE.pill}
                    disabled={true}
                    startEnhancer={() => <img style={{height:'16px'}} src={window.matchMedia("(prefers-color-scheme: light)").matches ?  "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_16/v1681361177/encounternote-black-01_tc9nef.png" : "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_16,q_auto/v1681361178/encounternote-white-01_wnucnh.png"}/>}
                        overrides={{
                            StartEnhancer: {
                                style: ({ $theme }) => ({
                                  marginRight: '3px'
                                })
                            }
                        }}
                >{this.state.content.encounter_note_button}</Button>}

                    <MpsModal
                        open={this.state.show}
                        title={Utils.renderTitleCenter(this.state.content.encounter_note_title)}
                        callback={this.submit}
                        callbackButton={this.state.content.encounter_note_submit_title}
                        disabled={!this.state.encounter_note}
                        callbackCancel={this.closeEncounterNote}
                        withFooter={true}
                        isLoading={this.state.encounter_note_submitting}
                        body={
                            <FlexGrid>
                                <FlexGridItem>{Utils.renderError(this.state.error)}</FlexGridItem>
                                <FlexGridItem>
                                <Textarea
                                    id="encounter_note"
                                    name="encounter_note"
                                    value={this.state.encounter_note}
                                    onChange={this.handleEncounterNoteChange}
                                    autoFocus={true}
                                    overrides={{
                                        Input: {
                                        style: {
                                            maxHeight: '300px',
                                            minHeight: '100px',
                                            minWidth: '300px',
                                            width: '100vw', // fill all available space up to parent max-width
                                            resize: 'both',
                                        },
                                        },
                                        InputContainer: {
                                            style: {
                                                maxWidth: '100%',
                                                width: 'min-content',
                                            },
                                        },
                                    }}
                                />
                                </FlexGridItem>
                            </FlexGrid>
                        }
                    />
                    </>
                )
            }
        </AuthUserContext.Consumer>
    }
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(EncounterNote);
