import React from 'react';
import { LabelLarge, ParagraphXSmall, ParagraphMedium } from 'baseui/typography';

const data = {
    en: {
        prescribing_conflict_title: "Complete encounter?",
        prescribing_conflict_override: "Override",
        prescribing_conflict_cancel: "Go back and fix",

        prescribing_conflict_tab_drug: <LabelLarge>Drug</LabelLarge>,
        prescribing_conflict_tab_pharmacy: <LabelLarge>Pharmacy</LabelLarge>,
        prescribing_conflict_tab_warning: <LabelLarge>Warning</LabelLarge>,

        prescribing_conflict_at_least_one_drug_warning: "At least 1 drug request must be fulfilled in it’s entirety to onboard a new patient.",
        prescribing_conflict_warning: <ParagraphXSmall style={{color: '#999999', margin: 0}}>*Warnings &amp; errors will presist even after correcting them.</ParagraphXSmall>,

        prescribing_conflict_all_good: "All good",

        encounter_note_button: "SOAP note",
        encounter_note_title: "SOAP note",
        encounter_note_submit_title: "Submit",

        reject_after_prescribe: "Cancel any active prescriptions before rejecting the applicant."
    }
}

const content = (lang) => {
    return data[lang];
};

export default content;
