
const data = {
    en: {
        rx_pad_scale: "scale(.95,.95)",
        time_ago_color: "#999999",
        block_border_radius: "13px",
        //block_solid_background_color: theme.colors.backgroundSecondary,
        //block_outline_background_color: theme.colors.backgroundTertiary
    }
}

const content = (lang) => {
    return data[lang];
};

export default content;
