import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import {withAuthentication} from '../../shared/Session'

import Navigation from '../Navigation';
import SignInPage from '../../shared/SignIn';
import SignUpPage from '../../shared/SignUp';
import PasswordResetPage from '../../shared/Password/reset'
import PasswordChange from '../../shared/Password/change';
import MFAEnroll from '../../shared/MFA/enroll';

import Ops from '../Ops';

import * as ROUTES from '../Constants';

import {Client as Styletron} from 'styletron-engine-atomic';
import {Provider as StyletronProvider} from 'styletron-react';
import {LightTheme, BaseProvider, styled} from 'baseui';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import NotFound from '../../shared/NotFound';

const engine = new Styletron();

import './app.css';

const App = () => (
    <StyletronProvider value={engine}>
    <BaseProvider theme={LightTheme}>
    <div>
        <Router>
        <FlexGrid
                flexGridColumnCount={1}
                flexGridColumnGap="scale800"
                >
                <FlexGridItem>
                    <Navigation/>
                </FlexGridItem>
                <FlexGridItem>
                    <Switch>
                    <Route exact path={ROUTES.OPS} component={Ops} />
                        <Route exact path={ROUTES.SIGN_IN} component={SignInPage} />
                        <Route exact path={ROUTES.SIGN_UP} component={SignUpPage} />
                        <Route exact path={ROUTES.PASSWORD_RESET} component={PasswordResetPage} />
                        <Route exact path={ROUTES.PASSWORD_CHANGE} component={PasswordChange} />
                        <Route exact path={ROUTES.MFA_ENROLL} component={MFAEnroll} />
                        <Route component={NotFound} />
                    </Switch>
                </FlexGridItem>
            </FlexGrid>
        </Router>
    </div>
    </BaseProvider>
    </StyletronProvider>
);

export default withAuthentication(App);
