import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { LabelLarge, LabelMedium, ParagraphLarge, ParagraphMedium, ParagraphSmall } from 'baseui/typography';
import React, {Component} from 'react';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import Utils from '../../shared/Utils';
import Logger from '../../shared/Utils/logger';
import { StatefulTooltip } from "baseui/tooltip";


class HistoryNote extends Component{
    constructor(props) {
        super(props);

        Logger.log(this.props);
    }

    pharmacyName = (details) => {
        return (
            <StatefulTooltip
            content={() => (
                details
            )}
            returnFocus
            autoFocus
            >
            {details.split(" ")[0].replace("-", "")}
            </StatefulTooltip>
        );
    };

    render = () => {
        return <AuthUserContext.Consumer>
                {authUser =>
                    (
                        <FlexGrid flexGridRowGap={"scale400"} marginBottom={'12px'} style={{backgroundColor: '#fafafa', padding: '12px'}}>
                            <FlexGridItem>
                                <LabelMedium style={{margin: 0}}>{Utils.renderLocaleString(this.props.note.created_at)} • {this.props.note.provider.name}</LabelMedium>
                            </FlexGridItem>

                            <FlexGridItem>
                                <ParagraphSmall style={{color: '#999999', margin: 0}}>Internal note</ParagraphSmall>
                                <ParagraphLarge style={{margin: 0}}>{this.props.note.note_text}</ParagraphLarge>
                            </FlexGridItem>

                            {this.props.note.prescriptions.length > 0
                            ?
                            <FlexGridItem>
                                <ParagraphSmall style={{color: '#999999', margin: 0}}>Rx prescribed</ParagraphSmall>
                                {this.props.note.prescriptions.map((prescription, idx) => <div key={idx}><ParagraphLarge style={{margin: 0}}>{prescription.drug_name} - Quantity {prescription.quantity_dispensed} • Days supply {prescription.days_supply} • Refills {prescription.refills_remaining}/{prescription.refills} {prescription.refills == 0 ? '' : '*estimated'} ({this.pharmacyName(prescription.pharmacy_detail)})</ParagraphLarge></div>)}
                            </FlexGridItem>
                            : null
                            }

                            {this.props.note.patient_message
                            ? <FlexGridItem>
                                <ParagraphSmall style={{color: '#999999', margin: 0}}>Patient message ({this.props.note.patient_message_status})</ParagraphSmall>
                                <ParagraphLarge style={{margin: 0}}>{this.props.note.patient_message}</ParagraphLarge>
                            </FlexGridItem>
                            : null
                            }

                        </FlexGrid>
                    )
            }
        </AuthUserContext.Consumer>
    };
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(HistoryNote);
