import * as ROUTES from '../../Constants';
import Request from '../../../shared/Request';
import Logger from '../../../shared/Utils/logger';

class SyncPrescriptions {
    // orders, firebase, patient_uuid,
    constructor(params){
        this.firebase = params.firebase;
        this.patient_uuid = params.patient_uuid;
        this.protocol_subscription = params.protocol_subscription;

        this.result = {
            prescribed: [],
            orders: params.orders,
            protocol_subscription: this.protocol_subscription,

            prescribed_list: [],
            not_prescribed_list: [],
            warning_list: [],
            error_list: [],
            error: null
        }
    }

    getFomoPharmacy = (order_id) => {
        const found = this.result.orders.find(order => order.id == order_id);
        if (found) {
            return found.fomo_pharmacy;
        }

        return null;
    }

    isError = () => {
        return this.result.error;
    };

    isEverythingCorrect = () => {
        return this.result.not_prescribed_list.length == 0
            && this.result.warning_list.length == 0
            && this.result.error_list.length == 0
    };

    run = () => {
        var order_numbers_params = '?order_numbers[]=' + this.result.orders.map(order => order.number).join('&order_numbers[]=');

        return new Promise ((resolve, reject) => Request.get({
            firebase: this.firebase,
            route: ROUTES.BASE_URL_API + "/physician_patient/health_change_sync/" + this.patient_uuid + order_numbers_params + '&protocol_subscription_id=' + this.protocol_subscription.id,
            callbackSuccess: (result) => {
                // Used to present prescriptions in the patient single history; need *all* prescriptions including cancelled ones.
                this.result.prescribed = result.prescriptions;
                this.result.orders = result.orders;

                this.calculateRequestedVsPrescribed(this.requestedDrugs());

                resolve(this);
            },
            callbackError: (error) => {
                this.result.error = {message: "Syncing prescriptions failed."};
                if (this.protocol_subscription.status == 'pending' && this.result.prescribed_list.length == 0) {
                    this.result.error.message = this.result.error.message + " At least 1 drug request must be fulfilled in it’s entirety to onboard a new patient.";
                }

                reject(this);
            }
        }));
    }

    getResults = () => {
        return this.result;
    };

    getActivePrescriptionsOfCurrentOrders = () => {
        const order_ids = this.result.orders.map(order => order.id);
        return this.result.prescribed.filter(prescription => prescription.active === true && this.isCurrentlyRelevant(prescription.line_items, order_ids));
    };

    //  it belongs to no order or a current order
    isCurrentlyRelevant = (line_items, order_ids) => {
        if (line_items.length == 0) {
            return true;
        }
        return line_items.filter(line_item => line_item.type === 'VariantLineItem' && order_ids.includes(line_item.order_id)).length > 0;
    };

    calculateRequestedVsPrescribed = (requested) => {
        var new_prescribed_list =  [];
        var new_not_prescribed_list = [];
        var new_warning_list = [];
        var new_error_list = [];
        var unmatched_prescribed = [];
        var active_prescribed = [];

        if (this.result.prescribed === undefined) {
            this.result.prescribed = [];
        } else {
            // Only need active prescriptions.
            active_prescribed = this.getActivePrescriptionsOfCurrentOrders();
        }

        if (requested === undefined) {
            requested = [];
        }

        active_prescribed.map((drug) => {
            var found = false;
            drug._display_name = drug.drug_name;
            drug._display_pharmacy = this.getPharmacyName(drug.pharmacy_detail);
            drug._quantity = drug.quantity_dispensed
            drug._msg = "";

            for (var index in requested) {
                //if line item has been assigned to rx then all checks already passed on the server
                if (drug.line_items.find( li => li.id === requested[index].id )) {
                    new_prescribed_list.push(drug);
                    //if its a match we can pull the req out of the list.
                    requested.splice(index, 1);
                    found = true;
                    break;
                }
            }
            if (found) {
                return;
            } else {
                //if this rx doesn't have a matching req, add it to the list for checking missing rx
                unmatched_prescribed.push(drug);
            }

            //since we didn't return lets figure out the error to apply to this rx
            for (var index in requested) {
                if (requested[index].variant.change_drug_id === drug.change_drug_id) {
                    //if line_item/variant not assigned but correct drug then check quantity and pharmacy
                    found = true;
                    let fomo = false;

                    var same_quantity = drug.quantity_dispensed === parseInt(requested[index].variant.quantity);

                    var same_pharmacy = false;
                    if (requested[index].fulfillment_pharmacy && (drug.ncpdp_pharmacy_id === requested[index].fulfillment_pharmacy.ncpdp_pharmacy_id)){
                        //same fulfilled pharmacy
                        same_pharmacy = true;
                    } else if (!requested[index].fulfillment_pharmacy) {
                        //request is not to a pharmer pharmacy
                        // Get the pharmacy ncpdp id of fomo of this order
                        fomo = this.getFomoPharmacy(requested[index].order_id);
                        if (fomo){
                            //does requested fomo match rx fomo
                            same_pharmacy = drug.ncpdp_pharmacy_id === fomo.ncpdp_pharmacy_id;
                        }
                    }

                    // Quantity Check
                    if (!same_quantity && fomo) {
                        drug._msg = 'Incorrect quantity';
                        if (!same_pharmacy) {
                            drug._msg = 'Incorrect quantity and incorrect pharmacy';
                        }
                        new_warning_list.push(drug);

                        break;
                    } else if (!same_quantity && !fomo) {
                        drug._msg = 'Incorrect quantity';
                        if (!same_pharmacy) {
                            drug._msg = 'Incorrect quantity and incorrect pharmacy';
                        }
                        new_error_list.push(drug);

                        break;
                    }

                    // Pharmacy Check
                    if (!same_pharmacy) {
                        drug._msg = 'Incorrect pharmacy';
                        new_error_list.push(drug);

                        break;
                    }

                }
            }

            //no match on variant so it is just wrong.
            if (!found) {
                if (!drug.pharmacy_id) {
                    // FOMO pharmacy
                    drug._msg = 'Was prescribed but doesn’t match a patient order';
                    new_warning_list.push(drug);
                } else {
                    drug._msg = 'Not requested but was prescribed';
                    new_error_list.push(drug);
                }
            }
        });

        requested.map((line_item) => {

            var found = false;
            //At this point only check requests against prescriptions not already assigned to a request.
            for (var index in unmatched_prescribed) {
                if (line_item.variant.change_drug_id === active_prescribed[index].change_drug_id){
                    found = true;
                    break;
                }
            }
        });

        this.result.prescribed_list = new_prescribed_list;
        this.result.not_prescribed_list = new_not_prescribed_list;
        this.result.error_list = new_error_list;
        this.result.warning_list = new_warning_list;

        return this.result;
    };

    getPharmacyName = (pharmacy_details) => {
        return pharmacy_details.split('-')[0];
    }

    requestedDrugs = () => {
        if (Object.keys(this.result.orders).length == 0) {
            return [];
        }

        let filteredItems = this.result.orders.map(order => order.line_items.filter((item) => item.type == 'VariantLineItem' && item.variant_id != null));

        //flatten array
        return [].concat.apply([], filteredItems)

    };
}

export default SyncPrescriptions;
