import React, {Component} from 'react';
import * as ROUTES from '../Constants';
import { PATIENT_ROUTES } from '../../shared/Constants';
import Favicon, {FaviconSize} from '../../shared/Favicon';
import Request from '../../shared/Request';
import { HeadingSmall, ParagraphMedium, ParagraphLarge, LabelLarge, LabelMedium, HeadingMedium, HeadingLarge, HeadingXXLarge, HeadingXSmall, ParagraphSmall, ParagraphXSmall } from 'baseui/typography';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import Logger from '../../shared/Utils/logger';
import { Input } from "baseui/input";
import {Button, KIND, SIZE, SHAPE} from 'baseui/button';
import {Spinner} from '../../shared/Spinner';
import Utils from '../../shared/Utils';
import { StyledLink } from "baseui/link";
import MpsModal from '../../shared/Utils/modal';
import Patient from '../Patient';
import { ButtonGroup, MODE } from "baseui/button-group";
import { Tag, KIND as TagKind } from "baseui/tag";
import Payments from '../Payments';
import Presence from '../../shared/Presence';
import Show from 'baseui/icon/show'
import { StatefulTooltip, PLACEMENT, TRIGGER_TYPE } from "baseui/tooltip";
import ShareInvite from '../ShareInvite';
import { toaster, ToasterContainer } from "baseui/toast";
import { Drawer, SIZE as DrawerSize, ANCHOR  } from "baseui/drawer";
import Storage from '../../shared/Storage';

const centerProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }),
        },
    },
};

const leftProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
            }),
        },
    },
};

const rightProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                width: 'fit-content',
                display: 'flex',
                flexGrow: 0,
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
            }),
        },
    },
};

const rightExtraInfoProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                width: 'fit-content',
                flexGrow: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
            }),
        },
    },
};

const rightPropsFluidWidth = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
            }),
        },
    },
};

const itemProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                //marginLeft: '16px',
                //marginRight: '16px',
            }),
        },
    },
};

const patientBlock = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                backgroundColor:$theme.colors.backgroundSecondary,

            }),
        },
    },
};

// Selection
const ALL = 0;
const NEW_APPLICANTS = 1;
const REQUESTS = 2;
const MESSAGES = 3;

// Expiration
const IS_EXPIRING = 144; // hours
const IS_EXPIRED = 168; // hours

// Sort
const ASC = 0;
const DESC = 1;

class ProtocolView extends Component {
    constructor(props){
        super(props);

        this.protocolInterval = null;
        this.state = {
            isLoaded: false,
            protocol_id: this.props.match.params.id,
            search: '',
            patient_id: null,
            patient_uuid: null,
            patient_subscription: null,
            patient: null,
            newly_onboarded_subscriptions: [],
            prescribed_orders: [],
            showModal: false,
            isModalCloseable: true,
            new_applicants_count: 0,
            new_request_count: 0,
            new_message_count: 0,
            selection: ALL,
            sort: ASC,
            showPayments: false,
            showShareInvite: false,
            open_patient_drawer: false,
            open_announcements_drawer: false,

            patient_count: 0,
            new_patients: 0,
            lost_patients: 0,
            earnings_estimate: 0.0,

            prescription_results: {}
        };
    }

    componentDidMount = () => {
        this.getProtocol();
        this.getMetrics();

        this.protocolInterval = setInterval(() => {
            if (this.props.firebase.isUserOnline()) {
                this.getProtocol();
                this.getMetrics();
            } else {
                clearInterval(this.protocolInterval);
                this.protocolInterval = null;
            }
        }, 10000);
    }

    getProtocol = () => {
        Request.get({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/physician_protocol/protocols/view/" + this.state.protocol_id,
            callbackSuccess: result => {
                this.setState({
                protocol: result,
                isLoaded: true,
                new_applicants_count: this.new_applicants_count(result), // TODO Exclude expired
                new_request_count: this.request_count(result), // TODO Exclude expired
                new_message_count: this.message_count(result), // TODO Exclude expired
                newly_onboarded_subscriptions: [],
            })},
            callbackError: error => this.setState({error, isLoaded: true})
        })
    };

    getMetrics = () => {
        Request.get({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/metrics/" + this.state.protocol_id,
            callbackSuccess: result => this.setState({
                patient_count: result.data.patients,
                new_patients: result.data.new_patients,
                lost_patients: result.data.lost_patients,
                earnings_estimate: result.data.earnings_estimate
            }),
            callbackError: error => this.setState({error})
        })
    };

    sortDirection = (a, b) => {
        if (a > b) {
            if (this.state.sort == ASC) {
                return 1;
            } else {
                return -1
            }
        } else {
            if (this.state.sort == ASC) {
                return -1;
            } else {
                return 1
            }
        }
    };

    sortByTime = (a, b) => {
        a = new Date(a);
        b = new Date(b);
        if (a > b) {
            if (this.state.sort == ASC) {
                return -1;
            } else {
                return 1
            }
        } else {
            if (this.state.sort == ASC) {
                return 1;
            } else {
                return -1
            }
        }
    };

    filtered_subscriptions = () => {
        let filtered = this.state.protocol.protocol_subscriptions.filter(subscription => subscription.patient && !['cart', 'reapply'].includes(subscription.status));
        if (this.state.search) {
            let term = this.state.search.toLowerCase();
            filtered = this.state.protocol.protocol_subscriptions.filter(
                subscription => !['cart', 'reapply'].includes(subscription.status) && subscription.patient &&
                ((
                    subscription.patient.last_name && subscription.patient.last_name.toLowerCase().startsWith(term))
                || (subscription.patient.first_name && subscription.patient.first_name.toLowerCase().startsWith(term))
                || (subscription.status && subscription.status.toLowerCase().startsWith(term))
                || (subscription.patient.birthdate && subscription.patient.birthdate.toLowerCase().startsWith(term))
                || (subscription.patient.number && subscription.patient.number.toLowerCase().startsWith(term))
                )
            );
        }

        if (this.state.selection == NEW_APPLICANTS) {
            let pending_subscriptions = [];

            filtered.forEach((subscription, idx) => {
                let sorted_orders = [];

                if (subscription.status == 'pending'){
                    subscription.requested_and_prescribed_orders.forEach(order => {
                        sorted_orders.push(order);
                    });

                    if (sorted_orders.length > 0) {
                        sorted_orders.sort((a, b) => {return this.sortByTime(a.checked_out_at, b.checked_out_at)});
                        subscription['checked_out_at'] = sorted_orders[0].checked_out_at;
                    } else {
                        subscription['checked_out_at'] = new Date().toLocaleString();
                    }

                    pending_subscriptions.push(subscription);
                }
            })

            return pending_subscriptions.sort((a, b) => {return this.sortByTime(a.checked_out_at, b.checked_out_at)});
        } else if (this.state.selection == REQUESTS) {

            let orders = [];
            filtered.forEach((subscription, idx) => {
                if (subscription.status != 'pending'){
                    subscription.requested_and_prescribed_orders.forEach(order => {
                        //const orderIndex = this.state.prescribed_orders.findIndex(prescribed_order => prescribed_order.id == order.id);
                        //if (orderIndex == -1) {
                            orders.push({subscription: subscription, order: order});
                        //}
                    })
                }
            })

            return orders.sort((a, b) => {return this.sortByTime(a.order.checked_out_at, b.order.checked_out_at)});
        } else if (this.state.selection == MESSAGES) {
            let messages = [];
            filtered.forEach((subscription, idx) => {
                if (this.hasNewMessage(subscription) == true){
                    subscription.patient_messages.forEach(message => {
                        messages.push({subscription: subscription, message: message});
                    })
                }
            })
            return messages.sort((a, b) => {return this.sortByTime(new Date(a.message.created_at), new Date(b.message.created_at))});
        } else {
            return filtered.sort((a, b) => {return this.sortDirection(a.patient.last_name, b.patient.last_name)})
        }
    };

    renderFavicon = () => {
        return <FlexGrid>
            <FlexGridItem {...centerProps}>
                <Favicon
                    favicon_url={this.state.protocol.favicon_url}
                    size={FaviconSize.MEDIUM}
                    badge_type={this.state.protocol.badge_type}
                    status={this.state.protocol.status}
                    rejecting_new_subscriptions={this.state.protocol.rejecting_new_subscriptions}
                />
            </FlexGridItem>
        </FlexGrid>
    };

    renderName = () => {
        return <FlexGrid
        flexGridColumnGap="scale800"
    >
        <FlexGridItem {...centerProps}>
            <HeadingMedium style={{margin: "6px 0px 4px 0px", fontSize: '28px', lineHeight: '36px', display: 'inline-block'}} >{this.state.protocol.name}</HeadingMedium>
        </FlexGridItem>
    </FlexGrid>
    };

    renderMembers = () => {
        return ['pending'].includes(this.state.protocol.status) ?
        <ParagraphMedium style={{margin: '0 auto 0 auto', display:"flex", justifyContent:"center", maxWidth:"570px", width:"100%"}}>Under review...</ParagraphMedium>
        :
        <ParagraphMedium style={{margin: '0 auto 0 auto', display:"flex", justifyContent:"center", maxWidth:"570px", width:"100%"}}>{this.state.patient_count} members&nbsp;•&nbsp;<span style={{color: "#30CA93"}}>↑ {this.state.new_patients}</span>&nbsp;<span style={{color: "#DA4658"}}>↓ {this.state.lost_patients}</span>&nbsp;•&nbsp;{Utils.renderDollarAmount(this.state.earnings_estimate)} this month</ParagraphMedium>
    }

    renderSearch = () => {
        return <FlexGrid flexGridColumnCount={2} display={'flex'} justifyContent={'center'} style={{margin:'0 auto 0 auto', maxWidth:'570px', width:'100%' }}>
            <FlexGridItem display={'inline'}>
                <Input
                    value={this.state.search}
                    onChange={e => this.setState({search: e.target.value})}
                    placeholder="Search by name, DOB, UID or status"
                    clearable
                    overrides={{
                        Root: {
                          style: {
                            paddingRight: '0px',
                            },
                          },
                        InputContainer: {
                            style: {
                              paddingRight: '0px',
                            },
                          },
                        Input: {
                            style: {
                              paddingRight: '0px',
                            },
                          },
                      }}
                />
            </FlexGridItem>
            <FlexGridItem display={'inline'} {...rightProps}>
                    <Button style={{paddingLeft:'0px', paddingRight:'12px', marginLeft: '8px'}} kind={KIND.secondary} shape={SHAPE.default} size={SIZE.default} onClick={this.switchSorting} endEnhancer={() => this.state.sort == ASC ? <ParagraphSmall style={{margin: 0, fontSize: '10px'}}>▼</ParagraphSmall> : <ParagraphSmall style={{margin: 0, fontSize: '10px'}}>▲</ParagraphSmall>}></Button>
            </FlexGridItem>
        </FlexGrid>
    };

    new_applicants_count = (data) => {
        let count = 0;
        data.protocol_subscriptions.forEach(subscription => {
            if (subscription.status == 'pending') {
                if (subscription.requested_and_prescribed_orders.length > 0) {
                    subscription.requested_and_prescribed_orders.forEach(order => {
                        if (!this.isExpired(order.checked_out_at)) {
                            count += 1;
                        }
                    });
                } else {
                    // The order may have been fulfilled but patient still has not been accepted to the protocol.
                    count += 1;
                }
            }
        });
        return count;
    };

    request_count = (data) => {
        let count = 0;
        data.protocol_subscriptions.forEach(subscription => {
            if (subscription.status != 'pending' && !this.state.newly_onboarded_subscriptions.includes(subscription.id)) {
                subscription.requested_and_prescribed_orders.forEach(order => {
                    if (!this.isExpired(order.checked_out_at)) {
                        count += 1;
                    }
                })
            }
        });
        return count;
    };

    message_count = (data) => {
        let count = 0;
        data.protocol_subscriptions.forEach(subscription => {
            count += subscription.patient_messages.filter(message => message.seen_by_provider !== true).length;
        });
        return count;
    };

    hasNewMessage = (subscription) => {
        return subscription.patient_messages.filter(message => message.seen_by_provider !== true).length > 0;
    };

    hourDiff = (date1, date2) => {
        let hourInMilliseconds = 60 * 60 * 1000;
        return Math.abs(date1 - date2) / hourInMilliseconds;
    };

    isExpiring = (submitted_date) => {
        submitted_date = new Date(submitted_date);
        let today = new Date();

        return this.hourDiff(today, submitted_date) > IS_EXPIRING;
    };

    isExpired = (submitted_date) => {
        submitted_date = new Date(submitted_date);
        let today = new Date();

        return this.hourDiff(today, submitted_date) > IS_EXPIRED;
    };

    showExtraInfo = () => {
        return [NEW_APPLICANTS, REQUESTS, MESSAGES].includes(this.state.selection);
    };

    howLongAgoSubmitted = (data) => {
        if (this.state.selection == NEW_APPLICANTS) {
            return Utils.renderLocaleString(new Date(data));
        } else if (this.state.selection == REQUESTS) {
            return Utils.renderLocaleString(new Date(data));
        } else if (this.state.selection == MESSAGES) {
            return Utils.renderLocaleString(new Date(data));
        } else {
            return null;
        }
    }

    renderExpiring = (submitted_date) => {
        if (this.isExpiring(submitted_date)) {
            return <Tag closeable={false}>Expiring soon</Tag>;
        } else {
            return null;
        }
    };

    renderRequests = (subscriptions) => {
        return <FlexGrid display={'flex'} justifyContent={'center'} maxWidth={'570px'} width={'100%'} marginRight={'auto'} marginLeft={'auto'}>
        {subscriptions.map((subscription_order, idx) => !this.isExpired(subscription_order.order.checked_out_at) && <FlexGridItem key={idx}>
                <StyledLink
                    className={'a_no_line'}
                    onClick={e => {
                        e.preventDefault();
                        this.setState({
                            patient_id: subscription_order.subscription.patient.id,
                            patient_uuid: subscription_order.subscription.patient.uuid,
                            patient_subscription: subscription_order.subscription,
                            patient: subscription_order.patient,
                            showModal: true
                        })
                    }}
                    href="#">
                <FlexGrid
                flexGridColumnCount={3}
                style={{marginTop: '2px'}}
                backgroundColor={this.isExpiring(subscription_order.order.checked_out_at) ? '#FFF8C1' : '#f7f7f7'}>
                    <FlexGridItem>
                        <FlexGrid>
                            <FlexGridItem {...leftProps}><HeadingXSmall style={{margin: '5px 0px 0px 15px', color: (subscription_order.subscription.status == 'dead' ? '#999999' : '')}}><Presence status={subscription_order.subscription.patient.presence} /> {subscription_order.subscription.patient.last_name + ', ' + subscription_order.subscription.patient.first_name}</HeadingXSmall></FlexGridItem>
                            <FlexGridItem {...leftProps}><ParagraphMedium style={{margin: '0px 0px 5px 15px', color: '#999999'}}>{subscription_order.subscription.patient.birthdate} • {subscription_order.subscription.patient.number} • {Utils.upperCaseFirstLetter(subscription_order.subscription.status)}</ParagraphMedium></FlexGridItem>
                        </FlexGrid>
                    </FlexGridItem>

                            <FlexGridItem {...rightExtraInfoProps}>{this.renderExpiring(subscription_order.order.checked_out_at)}</FlexGridItem>
                            <FlexGridItem {...rightExtraInfoProps}>
                                <LabelLarge style={{margin: '0px 15px 0px 0px'}}>{this.howLongAgoSubmitted(subscription_order.order.checked_out_at)}</LabelLarge>
                            </FlexGridItem>
                </FlexGrid>
                </StyledLink>
            </FlexGridItem>)}</FlexGrid>
    };

    renderMessages = (subscriptions) => {
        return <FlexGrid display={'flex'} justifyContent={'center'} maxWidth={'570px'} width={'100%'} marginRight={'auto'} marginLeft={'auto'}>
            {subscriptions.map((subscription_message, idx) => !subscription_message.message.seen_by_provider &&
                <FlexGridItem key={idx}>
                <StyledLink
                    className={'a_no_line'}
                    onClick={e => {
                        e.preventDefault();
                        this.setState({
                            patient_id: subscription_message.subscription.patient.id,
                            patient_uuid: subscription_message.subscription.patient.uuid,
                            patient_subscription: subscription_message.subscription,
                            patient: subscription_message.patient,
                            showModal: true
                        })
                    }}
                    href="#">
                <FlexGrid
                flexGridColumnCount={2}
                style={{marginTop: '2px'}}
                backgroundColor={'#f7f7f7'}>
                    <FlexGridItem>
                        <FlexGrid>
                            <FlexGridItem {...leftProps}><HeadingXSmall style={{margin: '5px 0px 0px 15px', color: (subscription_message.subscription.status == 'dead' ? '#999999' : '')}}><Presence status={subscription_message.subscription.patient.presence} /> {subscription_message.subscription.patient.last_name + ', ' + subscription_message.subscription.patient.first_name}</HeadingXSmall></FlexGridItem>
                            <FlexGridItem {...leftProps}><ParagraphMedium style={{margin: '0px 0px 5px 15px', color: '#999999'}}>{subscription_message.subscription.patient.birthdate} • {subscription_message.subscription.patient.number} • {Utils.upperCaseFirstLetter(subscription_message.subscription.status)}</ParagraphMedium></FlexGridItem>
                        </FlexGrid>
                    </FlexGridItem>
                    <FlexGridItem {...rightExtraInfoProps}>
                        <LabelLarge style={{margin: '0px 15px 0px 0px'}}>{this.howLongAgoSubmitted(subscription_message.message.created_at)}</LabelLarge>
                    </FlexGridItem>
                </FlexGrid>
                </StyledLink>
            </FlexGridItem>)}
        </FlexGrid>
    };

    renderNewApplicants = (subscriptions) => {
        return <FlexGrid display={'flex'} justifyContent={'center'} maxWidth={'570px'} width={'100%'} marginRight={'auto'} marginLeft={'auto'}>
            {subscriptions.map((subscription, idx) => (!this.isExpired(subscription.checked_out_at) || subscription.requested_and_prescribed_orders.length == 0) && <FlexGridItem key={idx}>
                    <StyledLink
                        className={'a_no_line'}
                        onClick={e => {
                            e.preventDefault();
                            this.setState({
                                patient_id: subscription.patient.id,
                                patient_uuid: subscription.patient.uuid,
                                patient_subscription: subscription,
                                patient: subscription.patient,
                                showModal: true
                            })
                        }}
                        href="#">
                    <FlexGrid
                    flexGridColumnCount={3}
                    style={{marginTop: '2px'}}
                    backgroundColor={this.isExpiring(subscription.checked_out_at) ? '#FFF8C1' : '#f7f7f7'}>
                        <FlexGridItem>
                            <FlexGrid>
                                <FlexGridItem {...leftProps}>
                                    <HeadingXSmall style={{margin: '5px 0px 0px 15px', color: (subscription.status == 'dead' ? '#999999' : '')}}>
                                        <Presence status={ subscription.patient.presence} /> { subscription.patient.last_name + ', ' + subscription.patient.first_name}
                                    </HeadingXSmall>
                                </FlexGridItem>
                                <FlexGridItem {...leftProps}>
                                    <ParagraphMedium style={{margin: '0px 0px 5px 15px', color: '#999999'}}>{subscription.patient.birthdate} • {subscription.patient.number} • {Utils.upperCaseFirstLetter(subscription.status)}</ParagraphMedium></FlexGridItem>
                            </FlexGrid>
                        </FlexGridItem>
                            <FlexGridItem {...rightExtraInfoProps}>{this.renderExpiring(subscription.checked_out_at)}</FlexGridItem>
                            <FlexGridItem {...rightExtraInfoProps}>
                                <LabelLarge style={{margin: '0px 15px 0px 0px'}}>{this.howLongAgoSubmitted(subscription.checked_out_at)}</LabelLarge>
                            </FlexGridItem>
                    </FlexGrid>
                    </StyledLink>
                </FlexGridItem>)}</FlexGrid>
    };

    renderPatients = () => {
        let subscriptions = this.filtered_subscriptions();
        if (subscriptions.length == 0) {
            return <FlexGrid display={'flex'} justifyContent={'center'} maxWidth={'570px'} width={'100%'} marginRight={'auto'} marginLeft={'auto'}><FlexGridItem style={{textAlign: 'center'}}><ParagraphMedium>There's nothing here.</ParagraphMedium></FlexGridItem></FlexGrid>
        }

        if (this.state.selection == REQUESTS) {
            return this.renderRequests(subscriptions);
        } else if (this.state.selection == MESSAGES) {
            return this.renderMessages(subscriptions);
        } else if (this.state.selection == NEW_APPLICANTS) {
            return this.renderNewApplicants(subscriptions);
        } else {
            return <FlexGrid display={'flex'} justifyContent={'center'} maxWidth={'570px'} width={'100%'} marginRight={'auto'} marginLeft={'auto'}>
                {subscriptions.map((subscription, idx) => <FlexGridItem key={idx}>
                    <StyledLink
                        className={'a_no_line'}
                        onClick={e => {
                            e.preventDefault();
                            this.setState({
                                patient_id: subscription.patient.id,
                                patient_uuid: subscription.patient.uuid,
                                patient_subscription: subscription,
                                patient: subscription.patient,
                                showModal: true
                            })
                        }}
                        href="#">
                    <FlexGrid {...patientBlock} flexGridColumnCount={1} style={{marginTop: '2px'}}>
                        <FlexGridItem>
                            <FlexGrid>
                                <FlexGridItem {...leftProps}>
                                    <HeadingXSmall style={{margin: '5px 0px 0px 15px', color: (subscription.status == 'dead' ? '#999999' : '')}}><Presence status={subscription.patient.presence} /> {subscription.patient.last_name + ', ' + subscription.patient.first_name}</HeadingXSmall>
                                </FlexGridItem>
                                <FlexGridItem {...leftProps}>
                                    <ParagraphMedium style={{margin: '0px 0px 5px 15px', color: '#999999'}}>{subscription.patient.birthdate} • {subscription.patient.number} • {Utils.upperCaseFirstLetter(subscription.status)}</ParagraphMedium>
                                </FlexGridItem>
                            </FlexGrid>
                        </FlexGridItem>
                    </FlexGrid>
                    </StyledLink>
                </FlexGridItem>)}
            </FlexGrid>
        }
    };

    showModal = () => {
        this.setState({showModal: true});
    };

    closeModal = () => {
        this.setState({showModal: false});
        this.submitEncounter();
        this.getProtocol();
        this.getMetrics();
    };

    showPayments = () => {
        Logger.log('Showing payments...');
        this.setState({showPayments: true});
    };

    closePayments = () => {
        this.setState({showPayments: false});
    };

    showShareInvite = () => {
        Logger.log('Showing share invite...');
        this.setState({showShareInvite: true});
    };

    closeShareInvite = () => {
        this.setState({showShareInvite: false});
    };

    updateInviteCode = (inviteCode) => {
        var data = {id: this.state.protocol.id, invite_code: inviteCode};
        var url = "/physician_protocol/protocol_active_edit";
        Request.post({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + url,
            headers: {"Content-type" : 'application/json'},
            body: JSON.stringify(data),
            callbackSuccess: (result) => {
              this.setState(prevState => ({
                showShareInvite: false,
                protocol: {...prevState.protocol, invite_code: inviteCode}}));
            },
            callbackError: (error) => {
                this.setState({error});
            }
        });
    };

    messagesRead = () => {
        this.state.protocol.protocol_subscriptions.forEach(subscription => {
            if (subscription.patient_id == this.state.patient_id) {
                subscription.patient_messages.forEach(message => message.seen_by_provider = true);

                this.setState({
                    new_message_count: this.message_count(this.state.protocol)
                });

                return ;
            }
        });
    };

    ordersPrescribed = (orders) => {
        this.setState((prevState) => ({
            prescribed_orders: prevState.prescribed_orders.concat(orders)
        }), () => {
            this.setState({
                new_applicants_count: this.new_applicants_count(this.state.protocol),
                new_request_count: this.request_count(this.state.protocol),
                new_message_count: this.message_count(this.state.protocol)
            });
        });
    };

    updateSubscriptionStatus = (subscriptions, subscription_id, new_status) => {
        return subscriptions.map(subscription => {
            if (subscription.id == subscription_id) {
                subscription.status = new_status;
            }

            return subscription;
        })
    }
    setSubscriptionStatus = (subscription_id, status) => {
        let updated_newly_onboarded_subscriptions = this.state.newly_onboarded_subscriptions;
        let updating_subscription = this.state.protocol.protocol_subscriptions.filter(subscription => subscription.id == subscription_id);
        if (updating_subscription.length == 1 && updating_subscription[0].status == 'pending' && status == 'active') {
            updated_newly_onboarded_subscriptions.push(subscription_id);
        }

        this.setState(prevState => ({
            protocol: {
                ...prevState.protocol,
                protocol_subscriptions: this.updateSubscriptionStatus(prevState.protocol.protocol_subscriptions, subscription_id, status)
            },
            newly_onboarded_subscriptions: updated_newly_onboarded_subscriptions
        }))
    };

    setSelectedTab = (index) => {
        Logger.log("Index + ");
        this.setState({selection: index, sort: ASC});
    };

    switchSorting = () => {
        this.setState({sort: this.state.sort == ASC ? DESC : ASC});
    };

    renderSelectionButtons = () => {
        return <FlexGrid flexGridColumnCount={2} display={'flex'} justifyContent={'center'} maxWidth={'570px'} width={'100%'} marginRight={'auto'} marginLeft={'auto'}>
                <FlexGridItem>
                    <ButtonGroup
                        mode={MODE.radio}
                        kind={KIND.secondary}
                        shape={SHAPE.pill}
                        size={SIZE.compact}
                        selected={this.state.selection}
                        onClick={(event, index) => {
                            Logger.log('Clicked button index: ' + index);
                            this.setSelectedTab(index);
                        }}
                        //overrides={{
                            //Root: {
                                //style: ({$theme}) => ({
                                    //maxWidth: 0.95 * window.screen.width + 'px',
                                    //overflowX: 'auto',
                                    //boxSizing: 'border-box',
                                    //padding: '0px',
                                    //margin: '5px 0px 12px 0px'

                                //}),
                            //},
                        //}}
                    >
                        <Button>All</Button>

                        {
                            this.state.new_applicants_count > 0
                            ? <Button
                            style={{marginLeft: 0}}
                            overrides={{
                                BaseButton: {
                                    style: ({ $theme }) => ({
                                        display: 'inline',
                                        marginRight: '4px'
                                    })
                                }
                            }}
                            endEnhancer={() => <span style={{position: 'relative', display: 'inline'}}>
                            <span style={{
                                border: "3px solid white",
                                display: 'inline',
                                position: 'absolute',
                                top: '-33px',
                                left: '49px',
                                padding: "0px 5px 0px 5px",
                                color: 'white',
                                backgroundColor: '#EA4345',
                                borderRadius: '10px'}}>{this.state.new_applicants_count}</span>
                        </span>}>Applicants</Button>
                            : <Button>Applicants</Button>
                        }

                        {
                            this.state.new_request_count > 0
                            ? <Button
                            style={{marginLeft: 0}}
                            overrides={{
                                BaseButton: {
                                    style: ({ $theme }) => ({
                                        display: 'inline',
                                        marginRight: '4px'
                                    })
                                }
                            }}
                            endEnhancer={() => <span style={{position: 'relative', display: 'inline'}}>
                            <span style={{
                                border: "3px solid white",
                                display: 'inline',
                                position: 'absolute',
                                top: '-33px',
                                left: '41px',
                                padding: "0px 5px 0px 5px",
                                color: 'white',
                                backgroundColor: '#EA4345',
                                borderRadius: '10px'}}>{this.state.new_request_count}</span>
                        </span>}>Requests</Button>
                            : <Button>Requests</Button>
                        }

                        {
                            this.state.new_message_count > 0
                            ? <Button
                            style={{marginLeft: 0}}
                            overrides={{
                                BaseButton: {
                                    style: ({ $theme }) => ({
                                        display: 'inline',
                                        marginRight: '4px'
                                    })
                                }
                            }}
                            endEnhancer={() => <span style={{position: 'relative', display: 'inline'}}>
                            <span style={{
                                border: "3px solid white",
                                display: 'inline',
                                position: 'absolute',
                                top: '-33px',
                                left: '45px',
                                padding: "0px 5px 0px 5px",
                                backgroundColor: '#EA4345',
                                color: 'white',
                                borderRadius: '10px'}}>{this.state.new_message_count}</span>
                        </span>}>Messages</Button>
                            : <Button>Messages</Button>
                        }

                    </ButtonGroup>
                </FlexGridItem>
        </FlexGrid>
    };

    executeOnOpenPad = () => {
        Logger.log("Opening the pad... modal cannot be closeable.");
        this.setState({isModalCloseable: false});
    };

    executeOnClosedPad = (prescription_results) => {
        this.setState({isModalCloseable: true, prescription_results: prescription_results});
    };

    submitEncounter = () => {
        Logger.log(this.state.prescription_results);
        if(this.state.prescription_results && Object.keys(this.state.prescription_results).length == 0) {
            Logger.log('Not creating a complete encounter because nothing was prescribed....')
            return;
        }

        // Was this protocol subscription just onboarded now. If so, do not create encounter
        if (this.state.prescription_results.orders.length > 0
            && (
                this.state.newly_onboarded_subscriptions.includes(this.state.prescription_results.protocol_subscription.id)
                || this.state.prescription_results.protocol_subscription.status == 'pending'
            )
        ) {
            Logger.log('Not creating a complete encounter for onboarding...')
            return;
        }

        //this needs to include even bad rxs because otherwise an rx made in error hangs arround indefinately.
        let accepted_prescriptions = this.state.prescription_results.prescribed_list.concat(this.state.prescription_results.warning_list).concat(this.state.prescription_results.error_list);
        const data = new FormData();

        data.append('patient_uuid', this.state.patient_uuid);
        data.append('protocol_number', this.state.protocol_id);
        data.append('prescription_ids', accepted_prescriptions.map((prescription) => prescription.id));
        data.append('order_numbers', this.state.prescription_results.orders.map(order => order.number));

        Request.post({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/encounter",
            body: data,
            callbackSuccess: (result) => {
                Logger.log('Encounter created.')
                this.setState({prescription_results: {}});
            },
            callbackError: (error) => {
                Logger.log('Encounter failed.');
                this.setState({prescription_results: {}});
            }
        })
    }

    executeOnOpenPatientDrawer = () => {
        this.setState({open_patient_drawer: true});

        if (this.props.executeOnOpenPatientDrawer) {
            this.props.executeOnPatientDrawer();
        }
    };

    executeOnOpenAnnouncementsDrawer = () => {
        this.setState({open_announcements_drawer: true});

        if (this.props.executeOnOpenAnnouncementsDrawer) {
            this.props.executeOnOpenAnnouncementsDrawer();
        }
    };

    renderPatientDrawer = () => {
        {
            return <Drawer
            isOpen={this.state.open_patient_drawer}
            autoFocus
            animate={true}
            closeable={true}
            onClose={() => this.setState({open_patient_drawer: false})}
            size= {DrawerSize.full}
            anchor={ANCHOR.bottom}
            showBackdrop={true}
            overrides={{
                DrawerBody: {
                  style: ({ $theme }) => ({
                    marginRight:'16px',
                    marginLeft:'16px'
                  })
                }
              }}
        >
            {Utils.renderSpace()}
                                {this.renderSelectionButtons()}
                                {Utils.renderSpace()}
                                {this.renderSearch()}
                                {Utils.renderSpace()}
                                {this.renderPatients()}
                                {this.state.showModal
                                ? <MpsModal
                                    open={this.state.showModal}
                                    callbackCancel={this.closeModal}
                                    title={''}
                                    closeable={this.state.isModalCloseable}
                                    body={
                                        <Patient
                                            patient_id={this.state.patient_id}
                                            protocol_subscription={this.state.patient_subscription}
                                            patient={this.state.patient}
                                            protocol_id={this.state.protocol_id}
                                            protocol={this.state.protocol}
                                            messages_read_callback={this.messagesRead}
                                            requested_orders_callback={this.ordersPrescribed}
                                            callbackSuccess={this.closeModal}
                                            callbackSubscriptionStatus={this.setSubscriptionStatus}
                                            executeOnOpenPad={this.executeOnOpenPad}
                                            executeOnClosedPad={this.executeOnClosedPad}
                                        />}
                                    fullscreen={this.state.showModal}
                                    />
                                : null}
            </Drawer>
        }
    };

    renderAnnouncementsDrawer = () => {
        {
            return <Drawer
            isOpen={this.state.open_announcements_drawer}
            autoFocus
            animate={true}
            closeable={true}
            onClose={() => this.setState({open_announcements_drawer: false})}
            size= {DrawerSize.full}
            anchor={ANCHOR.bottom}
            showBackdrop={true}
        >
            <FlexGrid display={'flex'} justifyContent={'center'} maxWidth={'570px'} width={'100%'} marginRight={'auto'} marginLeft={'auto'}><FlexGridItem style={{textAlign: 'center'}}><ParagraphMedium>There's nothing here.</ParagraphMedium></FlexGridItem></FlexGrid>
            </Drawer>
        }
    };

    renderButtonOptions = () => {
        {
            return (
                <FlexGrid flexGridColumnCount={1} flexGridRowGap={'scale400'} style={{marginTop:"10px"}}>
                    <FlexGridItem {...itemProps} style={{display: ['pending'].includes(this.state.protocol.status) ? 'none' : '' }}>
                                    <Button
                                            shape={SHAPE.pill}
                                            size={SIZE.large}
                                            kind={KIND.secondary}
                                            onClick={this.executeOnOpenPatientDrawer}
                                            startEnhancer={() => <img style={{height:'24px'}} src={window.matchMedia("(prefers-color-scheme: light)").matches ? "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_24/v1680817701/patients2-black_vmm8ed.png" : "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_24/v1680817701/patients2-white_ojsb5g.png"}/>}
                                            overrides={{
                                                BaseButton: {
                                                    style: ({ $theme }) => ({
                                                        maxWidth: '570px',
                                                        width: '100%',
                                                        paddingTop: '20px',
                                                        paddingBottom: '20px',
                                                        paddingLeft: '0px',
                                                        paddingRight: '0px'
                                                    })
                                                },
                                                StartEnhancer: {
                                                    style: ({ $theme }) => ({
                                                      marginRight: '4px'
                                                    })
                                                }
                                            }}
                                        >Patients</Button>
                    </FlexGridItem>
                    {this.state.protocol.user_is_creator &&
                    <FlexGridItem {...itemProps} style={{display: ['pending'].includes(this.state.protocol.status) ? 'none' : 'none' }}>
                                    <Button
                                            shape={SHAPE.pill}
                                            size={SIZE.large}
                                            kind={KIND.secondary}
                                            onClick={this.executeOnOpenAnnouncementsDrawer}
                                            startEnhancer={() => <img style={{height:'24px'}} src={window.matchMedia("(prefers-color-scheme: light)").matches ? "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_24/v1680711042/pin-black_mqw5ie.png" : "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_24/v1680711042/pin-white_z0rq16.png"}/>}
                                            overrides={{
                                                BaseButton: {
                                                    style: ({ $theme }) => ({
                                                        maxWidth: '570px',
                                                        width: '100%',
                                                        paddingTop: '20px',
                                                        paddingBottom: '20px',
                                                        paddingLeft: '0px',
                                                        paddingRight: '0px'
                                                    })
                                                },
                                                StartEnhancer: {
                                                    style: ({ $theme }) => ({
                                                      marginRight: '2px'
                                                    })
                                                }
                                            }}
                                        >Announcements</Button>
                    </FlexGridItem>}
                    {this.state.protocol.user_is_creator &&
                    <FlexGridItem {...itemProps} style={{display: ['pending'].includes(this.state.protocol.status) ? 'none' : '' }}>
                                    <Button
                                            shape={SHAPE.pill}
                                            size={SIZE.large}
                                            kind={KIND.secondary}
                                            onClick={this.showPayments}
                                            startEnhancer={() => <img style={{height:'24px'}} src={window.matchMedia("(prefers-color-scheme: light)").matches ? "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_24/v1680710752/earnings-black_gxvc4e.png" : "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_24/v1680710752/earnings-white_dcsuy2.png"}/>}
                                            overrides={{
                                                BaseButton: {
                                                    style: ({ $theme }) => ({
                                                        maxWidth: '570px',
                                                        width: '100%',
                                                        paddingTop: '20px',
                                                        paddingBottom: '20px',
                                                        paddingLeft: '0px',
                                                        paddingRight: '0px'
                                                    })
                                                },
                                                StartEnhancer: {
                                                    style: ({ $theme }) => ({
                                                      marginRight: '6px'
                                                    })
                                                }
                                            }}
                                        >Payments</Button>
                    </FlexGridItem>}
                    {this.state.protocol.user_is_creator &&
                    <FlexGridItem {...itemProps}>
                                    <Button
                                            shape={SHAPE.pill}
                                            size={SIZE.large}
                                            kind={KIND.secondary}
                                            $as="a"
                                            href={ROUTES.PROTOCOLS_EDIT + '/' + this.state.protocol.number}
                                            startEnhancer={() => <img style={{height:'24px'}} src={window.matchMedia("(prefers-color-scheme: light)").matches ? "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_24/v1680710891/settings-black_lewflx.png" : "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_24/v1680710891/settings-white_hpwm1y.png"}/>}
                                            overrides={{
                                                BaseButton: {
                                                    style: ({ $theme }) => ({
                                                        maxWidth: '570px',
                                                        width: '100%',
                                                        paddingTop: '20px',
                                                        paddingBottom: '20px',
                                                        paddingLeft: '0px',
                                                        paddingRight: '0px'
                                                    })
                                                },
                                                StartEnhancer: {
                                                    style: ({ $theme }) => ({
                                                      marginRight: '6px'
                                                    })
                                                }
                                            }}
                                        >Settings</Button>
                    </FlexGridItem>}
                    <FlexGridItem {...itemProps} style={{display: ['pending'].includes(this.state.protocol.status) ? 'none' : '' }}>
                    <ToasterContainer autoHideDuration={1400} closeable={false} overrides={{ ToastBody: { style: () => ({/*width:'fit-content'*/})},}}>
                                    <Button
                                            shape={SHAPE.pill}
                                            size={SIZE.large}
                                            kind={KIND.primary}
                                            onClick={(event) => {Utils.copyToClipboard([ 'https://www.clinic.dev' + PATIENT_ROUTES.PROTOCOL_VIEW + '/' + this.state.protocol_id]); let toastKey;
                                                const msg =
                                                "Invite link copied!";
                                                toastKey = toaster.info(
                                                <>
                                                    {msg}
                                                </>,
                                                {
                                                    onClose: () => console.log("Toast closed."),
                                                    overrides: {
                                                    InnerContainer: {
                                                        style: { width: "100%", textAlign: "center"}
                                                    }
                                                    }
                                                }
                                                );
                                            }}
                                            startEnhancer={() => <img style={{height:'24px'}} src={window.matchMedia("(prefers-color-scheme: light)").matches ? "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_24/v1680711121/link-white_xqpoqu.png": "https://res.cloudinary.com/mpsh87/image/upload/c_scale,dpr_2.0,h_24/v1680711121/link-black_r1lx3s.png" }/>}
                                            overrides={{
                                                BaseButton: {
                                                    style: ({ $theme }) => ({
                                                        maxWidth: '570px',
                                                        width: '100%',
                                                        paddingTop: '20px',
                                                        paddingBottom: '20px',
                                                        paddingLeft: '0px',
                                                        paddingRight: '0px'
                                                    })
                                                },
                                                StartEnhancer: {
                                                    style: ({ $theme }) => ({
                                                      marginRight: '6px'
                                                    })
                                                }
                                            }}
                                        >Share&nbsp;link</Button></ToasterContainer>
                    </FlexGridItem>
                </FlexGrid>);
        }
    };

    render = () => {
        return (
            <AuthUserContext.Consumer>
                {authUser =>
                    (
                        this.state.isLoaded
                        ?
                            this.state.protocol
                            ? <div style={{marginTop:'30px'}}>
                                {Utils.renderSpace()}
                                {this.renderFavicon()}
                                {this.renderName()}
                                {this.state.protocol.user_is_creator ? this.renderMembers() : null }
                                {this.renderButtonOptions()}
                                {this.renderPatientDrawer()}
                                {this.renderAnnouncementsDrawer()}
                                {this.state.showPayments
                                ? <Payments
                                    protocol_number={this.state.protocol.number}
                                    closePayments={this.closePayments}
                                />
                                : null}
                                 {this.state.showShareInvite
                                ? <ShareInvite show={this.state.showShareInvite} protocol={this.state.protocol} callback={this.updateInviteCode} closeShareInvite={this.closeShareInvite}/>: null}
                            </div>
                            : 'No protocols found.'
                        : <Spinner />
                    )
                }
            </AuthUserContext.Consumer>
        )
    }
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(ProtocolView);
