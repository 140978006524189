import React, {Component} from 'react';
import * as ROUTES from '../../Constants';
import { AuthUserContext, withAuthorization } from '../../../shared/Session';
import { Button, KIND, SHAPE, SIZE } from "baseui/button";
import Request from '../../../shared/Request';
import Utils from '../../../shared/Utils';
import content from './content';
import Logger from '../../../shared/Utils/logger';
import MpsModal from '../../../shared/Utils/modal';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import {ParagraphLarge} from 'baseui/typography';
import SyncPrescriptions from '../Rx/sync_prescriptions';
import MessagePatient from '../Messages/message_patient';

class Accept extends Component {
    constructor(props){
        super(props);

        this.state = {
            isLoaded: false,
            error: null,
            prescriptions: [],
            orders: [],
            showModal: false,

            prescribed_list: [],
            not_prescribed_list: [],
            prescription_syncing: false,
            warning_list: [],
            error_list: [],
            content: this.getContent(),

            show_welcome_form: false
        };
    }

    getContent = () => {
        return content('en');
    };

    componentDidUpdate = (prevProps) => {
        if (prevProps.patient_uuid !== this.props.patient_uuid) {
            this.setState( {
                isLoaded: false,
                error: null,
                prescriptions: [],
                orders: [],
                showModal: false,
                prescribed_list: [],
                not_prescribed_list: [],
                warning_list: [],
                error_list: []
            });
        }
    }

    isOnboarding = () => {
        return this.props.protocol_subscription.status == 'pending';
    };

    isProtocolActive = () => {
        return this.props.protocol.status === 'active';
    };

    isProtocolRefillService = () => {
        return this.props.protocol.is_refill_service;
    };

    handleModalClose = () => {
        this.setState({showModal: false, ...Utils.clearAllNotifications()});
    }

    renderWelcomeMessage = () => {
        if (this.props.protocol_subscription.status == 'pending' && this.state.show_welcome_form){
            return <MessagePatient
                default_message={this.props.default_message}
                protocol_subscription={this.props.protocol_subscription}
                patient_uuid={this.props.patient_uuid}
                protocol_number={this.props.protocol_number}
                callback={this.finishOnboarding}
                show={this.state.show_welcome_form}
                cancelMessageCallback={this.cancelMessage}
                message_title={'Accept patient?'}
                message_description={'Send info to the patient about getting started.'}
            />
        }

        return null;
    }

    cancelMessage = () => {
        this.setState({show_welcome_form: false});
    };

    finishOnboarding = () => {
        this.submitEncounter();
        if (this.props.closeRx) {
            this.props.closeRx();
        }
    };

    handlePatientPrescriptionSync = (event) => {
        event.preventDefault();

        this.setState({prescription_syncing: true, ...Utils.clearAllNotifications()});

        let synced_prescriptions = new SyncPrescriptions(
            {
                firebase: this.props.firebase,
                patient_uuid: this.props.patient_uuid,
                orders: this.props.orders,
                protocol_subscription: this.props.protocol_subscription
            }
        );

        let syncPromise = synced_prescriptions.run();
        syncPromise.then((sync) => {
            if (sync.isError()) {
                this.setState({error: {message: sync.results.error.message}, prescription_syncing: false});
            } else {
                let prescription_results = sync.getResults();
                this.setState({
                    prescribed_list: prescription_results.prescribed_list,
                    not_prescribed_list: prescription_results.not_prescribed_list,
                    error_list: prescription_results.error_list,
                    warning_list: prescription_results.warning_list,
                    prescription_syncing: false,
                }, () => {
                    let shouldSubmitEncounter = true;

                    if (this.isOnboarding()) {
                        // First the physician needs to write a message and then complete the encounter
                        shouldSubmitEncounter = false;

                        if (this.state.prescribed_list.length == 0 && this.isProtocolRefillService()) {
                            shouldSubmitEncounter = false;
                            Logger.error("There is an error and a warning should be presented...");
                            this.setState({
                                error: {message: this.state.content.prescribing_conflict_at_least_one_drug_warning},
                                show_welcome_form: false
                            });
                        } else {
                            this.setState({
                                show_welcome_form: true,
                                ...Utils.clearAllNotifications()
                            });
                        }
                    } else {
                        if (this.props.orders.length > 0 && this.state.prescribed_list.length == 0) {
                            shouldSubmitEncounter = false;
                            // Throw a warning
                            this.setState({showModal: true})
                        }
                    }

                    if (shouldSubmitEncounter) {
                        this.submitEncounter();
                        if (this.props.closeRx) {
                            this.props.closeRx();
                        }
                    }
                });
            }
        }).catch(sync => {
            let sync_results = sync.getResults();
            Logger.error(' There was a prescription sync error. ');
            Logger.log(sync_results);
            this.setState({error: {message: sync_results.error.message}, prescription_syncing: false, show_welcome_form: false});
        })
    }

    submitEncounter = () => {
        //this needs to include even bad rxs because otherwise an rx made in error hangs arround indefinately.
        let accepted_prescriptions = this.state.prescribed_list.concat(this.state.warning_list).concat(this.state.error_list);
        const data = new FormData();

        data.append('patient_uuid', this.props.patient_uuid);
        data.append('protocol_number', this.props.protocol_number);
        data.append('prescription_ids', accepted_prescriptions.map((prescription) => prescription.id));
        data.append('order_numbers', this.props.orders.map(order => order.number));

        Request.post({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/encounter",
            body: data,
            callbackSuccess: (result) => {
                this.setState({
                    isLoaded: true,
                    showModal: false,
                    ...Utils.clearAllNotifications()
                });

                if ( this.props.callbackSuccess){
                    this.props.callbackSuccess();
                }

                if (this.props.protocol_subscription.status == 'pending') {
                    this.props.callbackSubscriptionStatus(this.props.protocol_subscription.id, 'active');
                }

                this.props.nextPatient(result.encounter);
            },
            callbackError: (error) => {
                this.setState({
                    isLoaded: true,
                    error
                })
            }
        })
    }

    renderDrugSync = () => {
        return <MpsModal
            open={this.state.showModal}
            title={Utils.renderTitleCenter(this.state.content.prescribing_conflict_title)}
            callback={this.submitEncounter}
            callbackButton={"Ok"}
            callbackCancel={this.handleModalClose}
            withFooter={true}
            body={
                <FlexGrid flexGridRowGap={"scale600"}>
                    <FlexGridItem>
                        <ParagraphLarge>Completing this visit will clear out any pending requests. Would you still like to proceed?</ParagraphLarge>
                    </FlexGridItem>
                </FlexGrid>
            }
        />
    }

    render = () => {
        return <AuthUserContext.Consumer>
        {authUser =>
            (
                this.isOnboarding() ?
                <>
                    {Utils.renderError(this.state.error, 5000)}
                    {Utils.renderErrorMulti(this.state.error_list)}
                    {this.renderDrugSync()}
                    {this.renderWelcomeMessage()}
                    {this.isProtocolActive() ? <Button
                        kind={KIND.primary}
                        shape={SHAPE.pill}
                        size={SIZE.compact}
                        onClick={this.handlePatientPrescriptionSync}
                        isLoading={this.state.prescription_syncing}
                        overrides={{
                            BaseButton: {
                                style: ({ $theme }) => ({
                                    color: $theme.colors.primaryB,
                                    backgroundColor: $theme.colors.positive400,
                                    ":hover": {
                                        backgroundColor: $theme.colors.positive500
                                    },
                                    ":focus": {
                                        backgroundColor: $theme.colors.positive300
                                    },
                                    ":active": {
                                        backgroundColor: $theme.colors.positive300
                                    },
                                })
                            }
                        }}
                    >{['pending', 'reapply'].includes(this.props.protocol_subscription.status) ? 'Accept' : 'Complete'}</Button>
                    : <Button
                        kind={KIND.primary}
                        shape={SHAPE.pill}
                        size={SIZE.compact}
                        disabled={true}
                    >{['pending', 'reapply'].includes(this.props.protocol_subscription.status) ? 'Accept' : 'Complete'}</Button>}
                </> : null
            )
        }
    </AuthUserContext.Consumer>
    }
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(Accept);
