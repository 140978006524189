
//   const $menu = $(".dashboard-panel-menu");

//   $(document).mouseup(e => {
//     if (!$menu.is(e.target) && $menu.has(e.target).length === 0) {
//       $menu.removeClass("is-showing-dash-nav");
//     }
//   });

//   $(".dashboard-panel-menu-btn").on("click", () => {
//     $menu.toggleClass("is-showing-dash-nav");
//   });

  (function() {
    var isHidden = function() {
      return $('#dashboard-panel-menu').hasClass('hide');
    };
  
    var closeNav = function() {
      $('#dashboard-panel-menu').addClass('hide');
    };
  
    var openNav = function() {
      $('#dashboard-panel-menu').removeClass('hide');
  
      $(document).on('click', function(e) {
        var $target = $(e.target);
  
        if ( !$target.closest('#dashboard-panel-menu').length && !$target.closest('#dashboard-panel-menu-btn').length ) {
          closeNav();
        }
      });
    };
  
    var toggleNav = function() {
      if ( isHidden() ) {
        openNav();
      } else {
        closeNav();
      }
    };
  
    $(document).on('click', '#dashboard-panel-menu-btn', function() {
      toggleNav();
    });
  
  })();
