// Accordions

// Add a `.accordion--start-open` class to the accordion if it should start open on page load.

// Basic markup:
// <div class="accordion">
//   <div class="accordion__header">Click to Open</div>
//   <div class="accordion__content">
//     <div class="accordion__content__inner">
//       <!-- Accordion content goes here -->
//     </div>
//   </div>
// </div>

(function() {
  var duration = 600;

  var toggle = function($accordion) {
    var $content = $accordion.find('.accordion__content');
    var contentHeight;

    if ( $accordion.hasClass('accordion--transitioning') ) return;

    $accordion.addClass('accordion--transitioning');

    // $accordion.one('app:ajax-load-complete', function() {
    //   var currentContentHeight = contentHeight;

    //   $content.css({ height: '' });
    //   contentHeight = $content.outerHeight(true);
    //   $content.css({ height: currentContentHeight })
    //           .stop()
    //           .transition({ height: contentHeight }, duration, 'easeOutQuint', function() {
    //             $content.css({ height: '' });
    //             $accordion.removeClass('accordion--transitioning');
    //           });
    // });

    if ( $accordion.hasClass('accordion--active') || $accordion.hasClass('accordion--start-open') ) {
      // Close accordion
      $content.css({ display: 'block' });
      $accordion.removeClass('accordion--active accordion--start-open');
      contentHeight = $content.outerHeight(true);
      $content.css({ height: contentHeight, display: 'block', opacity: 1 })
              .transition({ opacity: 0, height: 0 }, duration, 'easeOutQuint', function() {
                $content.hide();
                $accordion.removeClass('accordion--transitioning');
              });
    } else {
      // Open accordion
      $accordion.addClass('accordion--active');
      $content.css({ opacity: 0, height: '' }).show();

      $(document).trigger('app:accordion-open', $accordion);

      contentHeight = $content.outerHeight(true);

      $content.css({ height: 0 })
              .transition({ opacity: 1, height: contentHeight }, duration, 'easeOutQuint', function() {
                $content.css({ height: '' });
                $accordion.removeClass('accordion--transitioning');
              });
    }
  };

  $(document).on('click', '.accordion__header', function(e) {
    toggle( $(this).closest('.accordion') );
  });

  $(document).on('keyup', '.accordion__header', function(e) {
    // Enter key
    if ( e.which == 13 ) {
      toggle( $(this).closest('.accordion') );
    }
  });
})();
