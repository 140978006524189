import React, {Component} from 'react';
import { Input, SIZE } from "baseui/input";
import { Select } from "baseui/select";
import { FormControl } from "baseui/form-control";
import { Checkbox, LABEL_PLACEMENT } from "baseui/checkbox";
import { Button, KIND, SHAPE } from "baseui/button";
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import Utils from '../../shared/Utils';
import Logger from '../../shared/Utils/logger';

const narrowItemProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                width: '90px',
                flexGrow: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }),
        },
    },
};

const rightProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                width: '90px',
                flexGrow: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
            }),
        },
    },
};

class Question extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: this.props.id ? this.props.id : '',
            questionText: this.props.questionText ? this.props.questionText : '',
            questionType: this.props.questionType ? [{label: Utils.upperCaseFirstLetter(this.props.questionType), id: this.props.questionType}] : [],
            responseChoices: this.props.responseChoices ? this.props.responseChoices : [],
            multichoice_hint: "The patient is required to complete multi-select questions. Include None, Never, or N/A as a response option when applicable.",
            responseTypeError: null,
            responsesRequiredError: null,
            responseChoiceError: null,
            questionError: null
        };
    }

    componentDidMount = () => {
        //this.validateQuestion();
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.id != this.props.id
            || prevProps.questionText != this.props.questionText
            || prevProps.questionType != this.props.questionType
            || prevProps.responseChoices != this.props.responseChoices) {
            this.setState({
                id: this.props.id,
                questionText: this.props.questionText ? this.props.questionText : '',
                questionType: this.props.questionType ? [{label: Utils.upperCaseFirstLetter(this.props.questionType), id: this.props.questionType}] : [],
                responseChoices: this.props.responseChoices ? this.props.responseChoices : []
            })
        }
    };

    validateQuestion = () => {
        if (!this.state.questionType[0] || ['multi', 'single', 'text'].indexOf(this.state.questionType[0].id) < 0) {
            this.setState({responseTypeError: 'Response type is required'});
        } else {
            this.setState({responseTypeError: null});
        }
        if (this.state.questionType[0] && ['multi', 'single'].indexOf(this.state.questionType[0].id) >= 0 && this.state.responseChoices.length < 1) {
            this.setState({responsesRequiredError: "At least 1 response is required"});
        } else {
            this.setState({responsesRequiredError: null});
        }
        if (this.state.questionText.length < 1) {
            this.setState({questionError: "Question is required"});
        } else {
            this.setState({questionError: null});
        }
        if (this.state.responseChoices.length > 0) {
            Logger.log('Checking response choices....');
            Logger.log(this.state.responseChoices);

            let unfilledChoices = this.state.responseChoices.filter(choice => choice.text == '');
            if (unfilledChoices.length > 0) {
                this.setState({responseChoiceError: "Response option is required"});
            } else {
                this.setState({responseChoiceError: null});
            }
        } else {
            this.setState({responseChoiceError: "Response option is required"});
        }
    };

    prepareQuestionForParent = () => {
        this.validateQuestion();
        var data = JSON.parse(JSON.stringify(this.state));
        data.questionType = data.questionType.length > 0 ? data.questionType[0].id : '';
        delete data.responseTypeError;
        delete data.responsesRequiredError;
        delete data.questionError;
        return data;
    };

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value }, () => {this.props.callback(this.prepareQuestionForParent(), this.props.index)});
    };

    renderQuestion = () => {
        return <div>
            <FormControl label={() => "Question"} error={this.state.questionError}>
                <Input name="questionText" value={this.state.questionText} onChange={this.handleChange} placeholder="Question" autoFocus/>
            </FormControl>
        </div>;
    };

    handleResponseTypeChange = (event) => {
        if (['single', 'multi'].includes(event.option.id)) {
            this.setState((prevState) => {
                if (['single', 'multi'].includes(prevState.questionType)) {
                    return { questionType: event.value }
                } else {
                    return {questionType: event.value, responseChoices: [{text: '', flag: false}]}
                }
            }, () => {this.props.callback(this.prepareQuestionForParent(), this.props.index)});
        } else if (event.option.id == 'text') {
            this.setState({questionType: event.value, responseChoices: []}, () => {this.props.callback(this.prepareQuestionForParent(), this.props.index)});
        }
    };

    renderResponseType = () => {
        return <FormControl
                label={() => "Response Type"}
                caption={() => this.state.questionType.length > 0 && ['multi'].includes(this.state.questionType[0].id) ? this.state.multichoice_hint : ''}
                error={this.state.responseTypeError} >
              <Select
                error={false}
                backspaceRemoves={false}
                clearable={false}
                deleteRemoves={false}
                escapeClearsValue={false}
                options={[
                    { label: "Single choice", id: "single" },
                    { label: "Multiple choice", id: "multi" },
                    { label: "Text", id: "text" }
                ]}
                value={this.state.questionType}
                filterOutSelected={false}
                onBlurResetsInput={false}
                onCloseResetsInput={false}
                onSelectResetsInput={false}
                searchable={false}
                openOnClick={false}
                onChange={this.handleResponseTypeChange}
                placeholder="Select response type"
            />
            </FormControl>
    };

    handleResponseChoiceOption = (event) => {
        let responseChoices = [...this.state.responseChoices]
        responseChoices[event.target.id].text = event.target.value;
        this.setState({responseChoices: responseChoices}, () => {
            this.props.callback(this.prepareQuestionForParent(), this.props.index);
            this.validateQuestion();
        });
    };

    handleResponseChoiceFlag = (event) => {
        let responseChoices = [...this.state.responseChoices]
        responseChoices[event.target.name].flag = event.target.checked;
        this.setState({responseChoices: responseChoices}, () => {this.props.callback(this.prepareQuestionForParent(), this.props.index)});
    };

    handleResponseChoiceNewOption = (event) => {
        this.setState((prevState) => ({
            responseChoices: [...prevState.responseChoices, {text: '', flag: false}]
        }), () => {this.props.callback(this.prepareQuestionForParent(), this.props.index)});
    };

    handleResponseChoiceDeleteOption = (event) => {
        let responseChoices = [...this.state.responseChoices]
        responseChoices.splice(event.target.id, 1);
        this.setState({responseChoices: responseChoices}, () => {this.props.callback(this.prepareQuestionForParent(), this.props.index); this.validateQuestion()});
    };

    renderResponseChoice = () => {
        if (this.state.questionType.length > 0 && ['multi', 'single'].includes(this.state.questionType[0].id)) {
            return <FormControl
                label={() => 'Response Options'}
                error={this.state.responseChoiceError}>
                <div>
                {this.state.responseChoices.map((choice, idx) => { return <div key={idx}>
                <FlexGrid
                    marginBottom={"scale300"}
                    flexGridColumnCount={3}
                    flexGridColumnGap="scale800"
                    flexGridRowGap="scale800"
                >
                    <FlexGridItem><Input id={idx} value={this.state.responseChoices[idx].text} onChange={this.handleResponseChoiceOption} placeholder="Response option"/></FlexGridItem>
                    <FlexGridItem {...narrowItemProps}><Checkbox name={idx} checked={this.state.responseChoices[idx].flag == 'true'} onChange={this.handleResponseChoiceFlag} labelPlacement={LABEL_PLACEMENT.right} >Flag</Checkbox></FlexGridItem>
                    <FlexGridItem {...rightProps}><Button id={idx} kind={KIND.secondary} shape={SHAPE.pill} onClick={this.handleResponseChoiceDeleteOption}>Delete</Button></FlexGridItem>
                </FlexGrid>
                </div>})}
                <div><Button onClick={this.handleResponseChoiceNewOption} kind={KIND.primary} shape={SHAPE.pill} size={SIZE.default}>Add response</Button></div>
                </div>
            </FormControl>
        }
    };

    render = () => {
        return (
            <div>
                {this.renderQuestion()}
                {this.renderResponseType()}
                {this.renderResponseChoice()}
            </div>
        );
    };
}

export default Question;
