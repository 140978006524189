const data = {
    en: {
        message_patient_button: "Send message",
        message_patient_title: "Message patient",
        message_patient_submit: "Send"
    }
}

const content = (lang) => {
    return data[lang];
};

export default content;
