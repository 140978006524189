// File upload image previews

(function() {
  // https://gist.github.com/runeb/c11f864cd7ead969a5f0
  // Exif orientation value to css transform mapping
  // Does not include flipped orientations
  var rotation = {
    1: 'rotate(0deg)',
    3: 'rotate(180deg)',
    6: 'rotate(90deg)',
    8: 'rotate(270deg)'
  };

  var _arrayBufferToBase64 = function(buffer) {
    var binary = ''
    var bytes = new Uint8Array( buffer )
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode( bytes[ i ] )
    }
    return window.btoa( binary );
  }

  var orientation = function(file, callback) {
    var fileReader = new FileReader();

    fileReader.onloadend = function() {
      var base64img = "data:"+file.type+";base64," + _arrayBufferToBase64(fileReader.result);
      var scanner = new DataView(fileReader.result);
      var idx = 0;
      var value = 1; // Non-rotated is the default
      if(fileReader.result.length < 2 || scanner.getUint16(idx) != 0xFFD8) {
        // Not a JPEG
        if(callback) {
          callback(base64img, value);
        }
        return;
      }
      idx += 2;
      var maxBytes = scanner.byteLength;
      while(idx < maxBytes - 2) {
        var uint16 = scanner.getUint16(idx);
        idx += 2;
        switch(uint16) {
          case 0xFFE1: // Start of EXIF
            var exifLength = scanner.getUint16(idx);
            maxBytes = exifLength - idx;
            idx += 2;
            break;
          case 0x0112: // Orientation tag
            // Read the value, its 6 bytes further out
            // See page 102 at the following URL
            // http://www.kodak.com/global/plugins/acrobat/en/service/digCam/exifStandard2.pdf
            value = scanner.getUint16(idx + 6, false);
            maxBytes = 0; // Stop scanning
            break;
        }
      }
      if(callback) {
        callback(base64img, value);
      }
    }
    fileReader.readAsArrayBuffer(file);
  };

  var showPreview = function($input) {
    if ( !window.FileReader ) {
      return;
    }

    var input = $input.get(0);

    if ( !input.files || !input.files[0] ) return;

    var $wrapper = $input.closest('.ui-file');
    var $preview = $wrapper.find('.ui-file__image-preview');

    if ( !$preview.length ) {
      console.warn('Could not find image preview', $preview);
    }

    var reader = new FileReader();
    var file = input.files[0];

    orientation(file, function(base64img, value) {
      // console.log(rotation[value]);

      if ( value ) {
        $wrapper.addClass('ui-file--showing-preview')
        $preview.css({
                  backgroundImage: 'url(' + base64img + ')',
                  transform: rotation[value]
                });
      }
    });

    // reader.onload = function(e) {
    //   $label.addClass('ui-file__label--image-preview').css({ backgroundImage: 'url(' + e.target.result + ')' });
    // }

    // reader.readAsDataURL(input.files[0]);
  };

  $(document).on('change', '.ui-file input[type="file"]', function() {
    showPreview( $(this) );
  });
})();
