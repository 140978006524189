import React, {Component} from 'react';
import { AuthUserContext, withAuthorization } from '../../../shared/Session';
import Utils from '../../../shared/Utils';
import content from './content';
import Logger from '../../../shared/Utils/logger';
import MpsModal from '../../../shared/Utils/modal';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import {LabelSmall} from 'baseui/typography';

class PrescriptionResults extends Component{
    constructor(props){
        super(props);

        this.state = {
            showModal: true,
            content: this.getContent(),

            protocol_subscription: this.props.protocol_subscription,
            prescribed_list: this.props.prescribed_list,
            not_prescribed_list: this.props.not_prescribed_list,
            warning_list: this.props.warning_list,
            error_list: this.props.error_list
        };
    }

    getContent = () => {
        return content('en');
    };

    handleModalClose = () => {
        this.setState({showModal: false});
        if (this.props.callbackCancel) {
            this.props.callbackCancel();
        }
    }

    prescriptionResultsAccepted = () => {
        if (this.props.callback) {
            this.props.callback();
        }
    };

    drugsPrescribed = () => {
        return this.state.prescribed_list.map((drug, idx) => <FlexGridItem key={idx} >
            <FlexGrid flexGridColumnCount={3} flexGridColumnGap={"scale400"} style={{borderTop: '1px solid #DEDEDE', paddingTop: '10px'}}>
                <FlexGridItem><LabelSmall>{drug._display_name} (quantity {drug._quantity})</LabelSmall></FlexGridItem>
                <FlexGridItem><LabelSmall>{drug._display_pharmacy}</LabelSmall></FlexGridItem>
                <FlexGridItem>{this.state.content.prescribing_conflict_all_good}</FlexGridItem>
            </FlexGrid>
        </FlexGridItem>);
    }

    drugsWarnings = () => {
        return this.state.warning_list.map((drug, idx) => <FlexGridItem key={idx} ><FlexGrid flexGridColumnCount={3} flexGridColumnGap={"scale400"} style={{borderTop: '1px solid #DEDEDE', paddingTop: '10px'}}>
            <FlexGridItem><LabelSmall>⚠️ {drug._display_name} (quantity {drug._quantity})</LabelSmall></FlexGridItem>
            <FlexGridItem><LabelSmall>{drug._display_pharmacy}</LabelSmall></FlexGridItem>
            <FlexGridItem><LabelSmall>{drug._msg}</LabelSmall></FlexGridItem>
        </FlexGrid></FlexGridItem>);
    }

    drugsNotPrescribed = () => {
        return this.state.not_prescribed_list.map((drug, idx) => <FlexGridItem key={idx} ><FlexGrid flexGridColumnCount={3} flexGridColumnGap={"scale400"} style={{borderTop: '1px solid #DEDEDE', paddingTop: '10px'}}>
            <FlexGridItem><LabelSmall>{drug._display_name} (quantity {drug._quantity})</LabelSmall></FlexGridItem>
            <FlexGridItem><LabelSmall>{drug._display_pharmacy}</LabelSmall></FlexGridItem>
            <FlexGridItem><LabelSmall>{drug._msg}</LabelSmall></FlexGridItem>
        </FlexGrid></FlexGridItem>);
    }

    drugsErrors = () => {
        return this.state.error_list.map((drug, idx) => <FlexGridItem key={idx} ><FlexGrid flexGridColumnCount={3} flexGridColumnGap={"scale400"}style={{borderTop: '1px solid #DEDEDE', paddingTop: '10px'}}>
            <FlexGridItem><LabelSmall>❌ {drug._display_name} (quantity {drug._quantity})</LabelSmall></FlexGridItem>
            <FlexGridItem><LabelSmall>{drug._display_pharmacy}</LabelSmall></FlexGridItem>
            <FlexGridItem><LabelSmall>{drug._msg}</LabelSmall></FlexGridItem>
        </FlexGrid></FlexGridItem>);
    }

    renderDrugSync = () => {
        return <MpsModal
            open={this.state.showModal}
            title={Utils.renderTitleCenter(this.state.content.prescribing_conflict_title)}
            callback={this.prescriptionResultsAccepted}
            callbackButton={this.state.content.prescribing_conflict_override}
            disabled={this.state.protocol_subscription.status == 'pending' && this.state.prescribed_list.length == 0}
            callbackCancel={this.handleModalClose}
            callbackCancelButton={this.state.content.prescribing_conflict_cancel}
            withFooter={true}
            body={
                <FlexGrid flexGridRowGap={"scale600"}>
                    <FlexGridItem>
                        <FlexGrid flexGridRowGap={"scale600"}>
                            <FlexGridItem>
                                <FlexGrid flexGridColumnCount={3} flexGridColumnGap={"scale400"}>
                                    <FlexGridItem>
                                        {this.state.content.prescribing_conflict_tab_drug}
                                    </FlexGridItem>
                                    <FlexGridItem>
                                        {this.state.content.prescribing_conflict_tab_pharmacy}
                                    </FlexGridItem>
                                    <FlexGridItem>
                                        {this.state.content.prescribing_conflict_tab_warning}
                                    </FlexGridItem>
                                </FlexGrid>
                            </FlexGridItem>
                            {this.drugsPrescribed()}
                            {this.drugsNotPrescribed()}
                            {this.drugsWarnings()}
                            {this.drugsErrors()}
                        </FlexGrid>
                    </FlexGridItem>
                    <FlexGridItem style={{textAlign: 'center'}}>
                        {this.state.content.prescribing_conflict_warning}
                    </FlexGridItem>
                </FlexGrid>
            }
        />
    }

    render = () => {
        return <AuthUserContext.Consumer>
            {authUser => <>{this.renderDrugSync()}</>}
        </AuthUserContext.Consumer>
    }
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(PrescriptionResults);
