// Show more buttons

$(document).on('click', '[data-show-more-button]', function() {
  var $button = $(this);
  var $container = $button.closest('[data-show-more-container]');
  var $items = $container.find('.show-more-item--hidden');

  $items.removeClass('show-more-item--hidden');
  $button.remove();
});
