import React, {Component} from 'react'
import { Input } from "baseui/input";
import Logger from './logger';

class InputField extends Component {
    constructor(props){
        super(props);

        this.state = {
            name: this.props.name,
            id: this.props.id,
            value: this.props.value,
            onChange: this.props.onChange,
            placeholder: this.props.placeholder,
            disabled: this.props.disabled === true ? true : false,
            className: this.props.className,
            inputRef: this.props.ref,
            defaultValue: this.props.defaultValue
        };

        Logger.log('State Select');
        Logger.log(this.state);
    }

    componentDidUpdate = (prevState) => {
        var options = {};

        if (prevState.value !== this.props.value) {
            options.value = this.props.value;
        }

        if (prevState.disabled !== this.props.disabled) {
            options.disabled = this.props.disabled;
        }

        if (Object.keys(options).length > 0){
            this.setState(options);
        }
    };

    handleChange = (e) => {
        // TODO does not handle multi

        // Set local values
        this.setState({value: e.target.value})

        // Reflect values to the caller
        var event = e;
        event.target.id = this.state.id;
        event.target.name = this.state.name;
        event.target.value = e.target.value;
        event.target.className = this.state.className;
        event.target.defaultValue = this.state.defaultValue;
        event.target.ref = this.state.inputRef;

        //this.state.onChange(event);
    };

    render = () => {
        return <Input
            id={this.state.id}
            name={this.state.name}
            value={this.state.value}
            placeholder={this.state.placeholder}
            onChange={this.handleChange}
            disabled={this.state.disabled}
            inputRef={this.state.inputRef}
      />
    };
}

export default InputField;
