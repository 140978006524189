$(document).ready(function() {
  $("#new_addresses").on("ajax:success", function(event) {
    event.preventDefault()
    console.log(event);
   //otherwise need a hosted order page api to build the customer's cart
    let cbInstance = Chargebee.getInstance();
    let cart = cbInstance.getCart();
    let product = cbInstance.initializeProduct("mega-sleep-aid");
    var customer = {email: "test@example.com", first_name: "First Name", last_name: "Last Name", billing_address:{first_name: "", last_name: "", company: "", phone: "", line1: "", line2: ""}};
    cart.setCustomer(customer);
    cart.replaceProduct(product);
    cart.proceedToCheckout();


  }).on("ajax:error", function(event) { alert("error") });

});
