import React, {Component} from 'react';
import Logger from '../../shared/Utils/logger';
import MpsModal from '../../shared/Utils/modal';
import { withFirebase } from '../../shared/Firebase';
import { HeadingSmall, HeadingXSmall, HeadingMedium, HeadingXLarge, LabelLarge, ParagraphLarge, ParagraphMedium, ParagraphSmall } from 'baseui/typography';
import { Button, KIND, SHAPE, SIZE } from "baseui/button";
import Screen from "../../shared/Utils/screen";
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import Utils from '../../shared/Utils';
import { Input } from "baseui/input";
import * as PatientRoutes from '../../patient/Constants';
import { PATIENT_ROUTES } from '../../shared/Constants';

const blockProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: 'transparent',
                borderRadius: '',
                width:"100%",
                marginTop:'40px',
                marginRight: "auto",
                marginBottom:'10px',
                marginLeft: "auto"
            }),
        },
    },
  };

  const itemProps = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    align: 'center',
  };

  const greyTextProps = {
    overrides: {
        Block: {
          style: ({$theme}) => ({
            color: $theme.colors.primary500,
          }),
        },
    },
  };

class ShareInvite extends Component {
    constructor(props) {
      super(props);
      console.log(props);

        this.state = {
            show: this.props.show,
            error: null,
            inviteCode: this.props.inviteCode,
        };
    }

    componentDidMount = () => {
        Logger.log("ShareInvite did mount...");
    };

    componentDidUpdate = (prevProps) => {
        if (this.props.show !== prevProps.show) {
            this.setState({show: this.props.show});
        }
    };

    saveInvite = () => {
      this.props.callback(this.state.inviteCode.toLowerCase());
    };

    updateInviteCode = (event) => {
      this.setState({inviteCode: event.target.value.toLowerCase()});
    };

    render () {
        Logger.log(this.state);
        return (
            <MpsModal
                open={this.state.show}
                callbackCancel={this.props.closeShareInvite}
                callbackButton={this.props.callbackButton}
                callback={this.saveInvite}
                body={<div style={{marginBottom: '35px'}}>
                    <FlexGrid {...blockProps}>
                        <FlexGridItem>
                            <HeadingXLarge style={{ textAlign:"center", marginTop:"0px", marginBottom:"20px", marginLeft:"auto", marginRight:"auto"}}>
                                  Passcode
                            </HeadingXLarge>
                        </FlexGridItem>
                        {/*<FlexGridItem>
                            <ParagraphMedium style={{marginTop:"20px", marginBottom:"40px", textAlign:"center"}}>Make your clinic exlusive by adding a passcode and sharing it with new subscribers. If left blank, anyone can apply to join your clinic.</ParagraphMedium>
        </FlexGridItem>*/}
                        <FlexGridItem style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                            <Input
                                id="passcode"
                                type="text"
                                placeholder="Enter passcode"
                                value={this.state.inviteCode}
                                onChange={this.updateInviteCode}
                                overrides={{
                                    Root: {
                                      style: {
                                        width: '100%',
                                        marginRight: "5px"
                                      },
                                    },
                                    Input: {
                                      style: {
                                        textTransform: 'lowercase'
                                      },
                                    },
                                  }}
                            />
                        </FlexGridItem>
                        <ParagraphSmall {...greyTextProps}>Passcode must be between 4 and 15 characters in length, and contain only alphanumeric characters (letters A-Z, numbers 0-9), without spaces.</ParagraphSmall>
                    </FlexGrid>
                </div>}
                withFooter={true}
                autoFocus={false}
            />
        );
    }
}

export default withFirebase(ShareInvite);
