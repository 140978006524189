import React, {Component} from 'react';
import { AuthUserContext } from '../../shared/Session';
import {ProtocolList} from '../ProtocolManagement';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import {ParagraphLarge, ParagraphMedium, HeadingXLarge, HeadingMedium, HeadingLarge, HeadingSmall, HeadingXSmall} from 'baseui/typography';
import {withRouter, Link} from 'react-router-dom';
import {withFirebase} from '../../shared/Firebase';
import { Button, KIND, SHAPE, SIZE } from "baseui/button";
import * as ROUTES from '../Constants';
import { StyledLink } from "baseui/link";
import './style.scss';
import Screen from '../../shared/Utils/screen';
import content from './content';
import { Select } from "baseui/select";
import { FormControl } from "baseui/form-control";
import {Checkbox} from 'baseui/checkbox';
import { ArrowRight } from "baseui/icon";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalButton,
    SIZE as ModalSize
  } from 'baseui/modal';
  import {
    MessageCard,
    BUTTON_KIND,
    IMAGE_LAYOUT,
    BACKGROUND_COLOR_TYPE,
  } from 'baseui/message-card';

const itemProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                //marginLeft: '16px',
                //marginRight: '16px',
            }),
        },
    },
};

const buttonProps = {
    overrides: {
        BaseButton: {
            style: ({ $theme }) => ({
                maxWidth: '570px',
                width: '100%',
                paddingTop: '20px',
                paddingBottom: '20px',
                marginLeft: Screen.size() == 'XSMALL' || Screen.size() == 'SMALL'? '16px' : 'auto', 
                marginRight: Screen.size() == 'XSMALL' || Screen.size() == 'SMALL'? '16px' : 'auto'
            })
        }
    }
};

const centerProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }),
        },
    },
};

const endProps = {
    display: 'flex',
    justifyContent: 'flex-end',
};

const landingProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                maxWidth: Screen.size() == 'XSMALL' || Screen.size() == 'SMALL'? '':'570px', 
                width: Screen.size() == 'XSMALL' || Screen.size() == 'SMALL'? '': '100%', 
                marginLeft: Screen.size() == 'XSMALL' || Screen.size() == 'SMALL'? '16px':'auto', 
                marginRight: Screen.size() == 'XSMALL' || Screen.size() == 'SMALL'? '16px':'auto', 
            }),
        },
    },
};

const greyTextProps = {
    overrides: {
        Block: {
          style: ({$theme}) => ({
            color: $theme.colors.contentTertiary
          }),
        },
    },
  };

const headerProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
            position:"sticky",
            top:"0",
            backgroundColor: "transparent",
            width:"100%",
            maxWidth:"768px",
            marginTop:"10px",
            marginBottom:"16px",
            marginRight:"16px",
            marginLeft:"16px"
            //paddingTop:"25px",
            //paddingBottom:"25px"
            }),
        },
    },
};


class HomePage extends Component {
    constructor(props){
        super(props);

        this.state = {
            showAbout: false,
            showContact: false,
            content: this.getContent(),
            isOpen: false,

            accountType: '',
            selectedCard: null,
            showReportIssue: false,
            malpractice_consent: false,
        }
        this.close = this.close.bind(this);
    }

    close() {
        this.setState({ isOpen: false });
      };
    
    getContent = () => {
        return content('en');
    };

    renderBanner = () => {
        return <div id='welcome' style={{marginTop: '24px'}}>
            <FlexGrid
                flexGridRowGap={"scale600"}
                margin="0px 0px 0px 0px"
                padding="35px 35px 35px 35px"
            >
                <FlexGridItem {...centerProps} style={{textAlign: 'center'}}>{this.state.content.banner_title}</FlexGridItem>
                <FlexGridItem {...centerProps} style={{textAlign: 'center'}}>{this.state.content.banner_subtext}</FlexGridItem>
                <FlexGridItem {...centerProps} style={{textAlign: 'center'}}>{this.state.content.banner_call_action}</FlexGridItem>
            </FlexGrid>
        </div>
    };

    renderTitle = () => {
        return <div style={{display:'flex', justifyContent:'center', marginRight:'auto', marginLeft:'auto'}}>
            <HeadingXLarge style={{marginBottom:'20px'}}>Welcome to Clinic</HeadingXLarge>
        </div>
    };

    handleChange = (params) => {
        if (params.value.length === 0) {
          // Handle the case where the value is cleared (empty array).
          // You can set it to a default value or perform any other desired action.
          // For example, setting it to an empty array as it was before.
          this.setState({ accountType: '' });
        } else {
          this.setState({ accountType: params.value });
        }
      };

    renderButtonOptions = () => {
        {
            return (
                <FlexGrid flexGridColumnCount={1} flexGridRowGap={'scale400'} style={{marginTop:"10px", marginRight:"auto", marginLeft: "auto", display:'flex', justifyContent:'center'}}>
                    <FlexGridItem {...itemProps}>
                                    <Button 
                                            shape={SHAPE.pill} 
                                            size={SIZE.large} 
                                            kind={KIND.secondary} 
                                            $as="a"
                                            href={ROUTES.ACCOUNT}
                                            overrides={{
                                                BaseButton: {
                                                    style: ({ $theme }) => ({
                                                        maxWidth: '570px',
                                                        width: '100%',
                                                        paddingTop: '20px',
                                                        paddingBottom: '20px',
                                                        paddingLeft: '0px',
                                                        paddingRight: '0px'
                                                    })
                                                }
                                            }}
                                        >Complete your profile</Button>
                    </FlexGridItem>
                    <FlexGridItem {...itemProps}>
                                    <Button 
                                            shape={SHAPE.pill} 
                                            size={SIZE.large} 
                                            kind={KIND.secondary} 
                                            onClick={() => this.setState({ isOpen: true })}
                                            overrides={{
                                                BaseButton: {
                                                    style: ({ $theme }) => ({
                                                        maxWidth: '570px',
                                                        width: '100%',
                                                        paddingTop: '20px',
                                                        paddingBottom: '20px',
                                                        paddingLeft: '0px',
                                                        paddingRight: '0px'
                                                    })
                                                }
                                            }}
                                        >Activate your Rx pad</Button>
                                        <Modal onClose={this.close} isOpen={this.state.isOpen} overrides={{
                                            Dialog: {
                                            style: ({ $theme }) => ({
                                                width: '343px',
                                            })
                                            }
                                        }}>
                                        <ModalBody>
                                            <FlexGrid flexGridColumnCount={1} flexGridRowGap={"scale500"}style={{marginTop: '24px',
                                                marginBottom: '24px'}}>
                                                <FlexGridItem {...itemProps}>
                                            <Button 
                                            shape={SHAPE.pill} 
                                            size={SIZE.default} 
                                            kind={KIND.primary} 
                                            $as="a"
                                            href="https://api.id.me/saml/SingleSignOnService?EntityID=https%3A%2F%2Fclinician.changehealthcare.com&Binding=urn%3Aoasis%3Anames%3Atc%3ASAML%3A2.0%3Abindings%3AHTTP-POST&AuthnContext=epcs"
                                            target="_blank"
                                            overrides={{
                                                BaseButton: {
                                                    style: ({ $theme }) => ({
                                                        maxWidth: '250px',
                                                        width: '100%',
                                                        //paddingTop: '20px',
                                                        //paddingBottom: '20px',
                                                        paddingLeft: '0px',
                                                        paddingRight: '0px'
                                                    })
                                                }
                                            }}
                                        >I have a DEA number
                                            </Button>
                                            </FlexGridItem>
                                            <FlexGridItem {...itemProps}>
                                            <Button 
                                            shape={SHAPE.pill} 
                                            size={SIZE.default} 
                                            kind={KIND.secondary} 
                                            $as="a"
                                            href="https://api.id.me/saml/SingleSignOnService?EntityID=https%3A%2F%2Fclinician.changehealthcare.com&Binding=urn%3Aoasis%3Anames%3Atc%3ASAML%3A2.0%3Abindings%3AHTTP-POST&AuthnContext=erx"
                                            target="_blank"
                                            overrides={{
                                                BaseButton: {
                                                    style: ({ $theme }) => ({
                                                        maxWidth: '250px',
                                                        width: '100%',
                                                        //paddingTop: '20px',
                                                        //paddingBottom: '20px',
                                                        paddingLeft: '0px',
                                                        paddingRight: '0px'
                                                    })
                                                }
                                            }}
                                        >I do not have a DEA number
                                            </Button>
                                            </FlexGridItem>
                                            </FlexGrid>
                                        </ModalBody>
                                        </Modal>
                    </FlexGridItem>
                    <FlexGridItem {...itemProps}>
                                    <Button 
                                            shape={SHAPE.pill} 
                                            size={SIZE.large} 
                                            kind={KIND.secondary} 
                                            $as="a"
                                            href={ROUTES.PROTOCOLS_NEW}
                                            overrides={{
                                                BaseButton: {
                                                    style: ({ $theme }) => ({
                                                        maxWidth: '570px',
                                                        width: '100%',
                                                        paddingTop: '20px',
                                                        paddingBottom: '20px',
                                                        paddingLeft: '0px',
                                                        paddingRight: '0px'
                                                    })
                                                }
                                            }}
                                        >Build a clinic</Button>
                    </FlexGridItem>
                    <FlexGridItem {...itemProps} style={{marginTop:'20px'}}>
                                    <StyledLink
                                            $as="a"
                                            href={"https://clinicdev.notion.site/Introducing-Clinic-dev-c20765c845d04226a0eec3d924c075e0"}
                                            target="_blank"
                                            style={{color:"#00a1ff"}}
                                        >View our guidelines</StyledLink>
                    </FlexGridItem>
                </FlexGrid>);
        }
    };

    handleCardClick = (index) => {
        this.setState({ selectedCard: index });
      };

    handleSignUp = () => {
    this.props.history.push(ROUTES.SIGN_UP);  
      };

    shouldGetStartedBeDisabled = () => {
        return this.state.accountType === ''
        || this.state.selectedCard === null
        || this.state.malpractice_consent == false;
      };

    aboutCallback = () => {
        this.setState({showAbout: false});
    };

    handleSelectFocus = () => {
        // Programmatically blur the input field to prevent the keyboard from opening
        if (this.selectRef) {
          this.selectRef.blur();
        }
      };

      renderHeader = () => {
        return <FlexGrid {...headerProps} flexGridColumnCount={1}>
        <FlexGridItem {...endProps}>
            <Button 
                        kind={KIND.primary} 
                        shape={SHAPE.pill} 
                        size={SIZE.compact} 
                        startEnhancer={() => <ArrowRight size={18} />}
                        overrides={{
                            BaseButton: {
                                style: ({ $theme }) => ({
                                    paddingLeft: "8px"
                                })
                            },
                            StartEnhancer: {
                                style: ({ $theme }) => ({
                                  marginRight: '0px'
                                })
                            }
                        }}
                        $as="a"
                        href={ROUTES.SIGN_IN}
                        >Login
                    </Button>
        </FlexGridItem>
    </FlexGrid>
    };

    loggedOutLanding = () => {
        let title = 'Clinic for doctors'
        let subtext = 'Create your own internet clinic and share it with patients online. All clinics include eRx with ECPS, messaging & labs.'
        const { selectedCard } = this.state;
        return (<div>
            <FlexGrid flexGridColumnCount={1} style={{display:'grid', margin:'0px auto 0px auto'}}>
                <FlexGridItem {...centerProps}>
                    {this.renderHeader()}
                </FlexGridItem>
                <FlexGridItem {...centerProps}>
                {Screen.size() == 'XSMALL'|| Screen.size() == 'SMALL' ?
                    <HeadingLarge  style={{margin:'0px auto 0px auto', textAlign:'center', fontSize:"28px", lineHeight:"36px", fontFamily:'roobertMedium'}}>{title}</HeadingLarge>
                    :
                    <HeadingXLarge style={{margin:'0px auto 0px auto', textAlign:'center', fontSize:"32px", lineHeight:"32px", fontFamily:'roobertMedium'}}>{title}</HeadingXLarge>
                    }
                </FlexGridItem>
                <FlexGridItem {...centerProps}>
                    {Screen.size() == 'XSMALL'|| Screen.size() == 'SMALL' ?
                    <HeadingXSmall {...greyTextProps} style={{margin:'6px auto 12px auto', fontSize:"18px", lineHeight:"24px", textAlign: "center", maxWidth:'343px', width:'100%'}}
                        >{subtext}
                    </HeadingXSmall>
                    :
                    <HeadingSmall {...greyTextProps} style={{margin:'10px auto 10px auto', fontSize:"24px", lineHeight:"30px", textAlign: "center", maxWidth:'570px', width:'100%'}}
                        >{subtext}
                    </HeadingSmall>
                    }
                </FlexGridItem>
                <FlexGridItem {...landingProps} style={{marginBottom:"16px"}}>
                    <FormControl
                        overrides={{
                            ControlContainer:{
                                style: ({ $theme }) => ({
                                    marginTop: 0,
                                    marginBottom: 0
                                })
                            },
                            Label: {
                                style: ({$theme}) => ({
                                    marginTop: 0
                                })
                            }
                        }}
                        label={() => "Account type"}>
                            <Select
                                name="accountType"
                                options={[
                                { label: "I am a US-licensed MD", id: "MD" },
                                { label: "I am a US-licensed DO", id: "DO" },
                                { label: "I am a US-licensed APRN", id: "APRN" }
                                ]}
                                value={this.state.accountType}
                                inputMode={"none"}
                                required
                                searchable={"false"}
                                placeholder={"Select your account type"}
                                onChange={this.handleChange}
                                onFocus={this.handleSelectFocus}
                                ref={(ref) => (this.selectRef = ref)}
                                overrides={{
                                    ControlContainer: {
                                    style: ({ $theme }) => ({
                                        border: this.state.accountType !== "" ? `2px solid ${$theme.colors.primary}` : ""
                                    })
                                    }
                                }}
                            />
                    </FormControl>
                </FlexGridItem>
                <FlexGridItem {...landingProps} style={{marginBottom:"16px"}}>
                    <FormControl
                                overrides={{
                                    ControlContainer:{
                                        style: ({ $theme }) => ({
                                            marginTop: 0,
                                            marginBottom: 0
                                        })
                                    },
                                    Label: {
                                        style: ({$theme}) => ({
                                            marginTop: 0
                                        })
                                    }
                                }}
                                label={() => "Clinic type"}>
                            <FlexGrid flexGridColumnCount= {Screen.size() == 'XSMALL'|| Screen.size() == 'SMALL' ? 1 : 2} flexGridColumnGap={"scale600"} flexGridRowGap={"scale600"}>
                                <FlexGridItem>
                                    <MessageCard
                                        heading="Free clinic"
                                        paragraph="Free for doctors and only $1.50 per Rx for patients. No additional fees."
                                        isSelected={selectedCard === 0}
                                        onClick={() => this.handleCardClick(0)}
                                        overrides={{
                                            Root: {
                                            style: ({ $theme }) => ({
                                                minHeight: Screen.size() == 'XSMALL' || Screen.size() == 'SMALL'? "135px" : "160px",
                                                backgroundColor: selectedCard === 0 ? $theme.colors.backgroundTertiary : "transparent",
                                                border: selectedCard === 0 ? `2px solid ${$theme.colors.primary}` : `2px solid ${$theme.colors.borderOpaque}`, // Example styling for selected card
                                                cursor: "pointer",
                                                color: $theme.colors.primary
                                            })
                                            }
                                        }}
                                    />
                                </FlexGridItem>
                                <FlexGridItem>
                                    <MessageCard
                                        heading="Subscription clinic"
                                        paragraph="Charge patients a membership. You set the price. We'll remit your earnings each month minus our platform fee (20%)."
                                        isSelected={selectedCard === 1}
                                        onClick={() => this.handleCardClick(1)}
                                        overrides={{
                                            Root: {
                                            style: ({ $theme }) => ({
                                                minHeight:Screen.size() == 'XSMALL' || Screen.size() == 'SMALL'? "135px" : "160px",
                                                backgroundColor: selectedCard === 1 ? $theme.colors.backgroundTertiary : "transparent",
                                                border: selectedCard === 1 ? `2px solid ${$theme.colors.primary}` : `2px solid ${$theme.colors.borderOpaque}`, // Example styling for selected card
                                                cursor: "pointer",
                                                color: $theme.colors.primary
                                            })
                                            }
                                        }}
                                    />
                                </FlexGridItem>
                            </FlexGrid>
                        </FormControl>
                </FlexGridItem>
                <FlexGridItem {...landingProps} style={{marginBottom:"20px"}}>
                    <FormControl
                                overrides={{
                                    ControlContainer:{
                                        style: ({ $theme }) => ({
                                            marginTop: 0,
                                            marginBottom: 0
                                        })
                                    },
                                    Label: {
                                        style: ({$theme}) => ({
                                            marginTop: 0
                                        })
                                    }
                                }}
                                label={() => "Certification"}>
                               <FlexGridItem>
                               <Checkbox
                                checked={this.state.malpractice_consent}
                                onChange={() => this.setState({malpractice_consent: !this.state.malpractice_consent, message: null})}
                                > I have active malpractice insurance with telemedicine coverage
                            </Checkbox>
                            </FlexGridItem>     
                        </FormControl>
                </FlexGridItem>
                <FlexGridItem {...landingProps}>
                    <Link to={ROUTES.SIGN_UP}
                        style={{display:"flex", alignItems:"center", justifyContent:"center", width:"100%", textDecoration: "none", marginTop:"10px", marginBottom:"40px"}}>
                        <Button {...buttonProps}
                            kind={KIND.primary} 
                            shape={SHAPE.pill} 
                            size={SIZE.default} 
                            disabled={this.shouldGetStartedBeDisabled()}
                            onClick={() => this.handleSignUp()}
                            >Get started
                        </Button>
                    </Link>
                </FlexGridItem>
            </FlexGrid>
            </div>
        )
    };

    render(){
        return (
            <AuthUserContext.Consumer>
            {isUserLoggedIn => isUserLoggedIn
            ? <div style={{display:'grid', justifyContent:'center', alignContent:'center', flexWrap:'wrap', height:'75vh'}}>
                {this.renderTitle()}
                {this.renderButtonOptions()}
                {/*<ProtocolList />*/}
            </div>
            : this.loggedOutLanding()}
        </AuthUserContext.Consumer>
        )
    }
};
 
export default withRouter(withFirebase(HomePage));
