// Add to cart buttons

// Need to disable select once selected.
// remove then re-enable it once pushing remove.


(function() {

  var counter = 0;


  var submit = function($form, callback, remove = false) {
    var data = $form.serialize();
    var url = $form.attr('action');

    $.ajax({
      type: 'POST',
      url: url,
      data: data
    }).done(function(response) {
      var $fixedFooterButton = $('#fixed-footer-button');
      if (counter > 0) {
        
        var nextStepText = $fixedFooterButton.attr('data-next-step-label');

        $fixedFooterButton.html(nextStepText).addClass('active').removeClass('fixed-footer-button--inactive');
        
      } else {
        if (counter < 1) {
          var nextStepText = 'To begin, select meds';
          $fixedFooterButton.html(nextStepText).addClass('fixed-footer-button--inactive').removeClass('active');
        }
      }
      $('#selection-bar').toggle(counter > 0);
      if ( callback ) callback();
    }).fail(function(data) {
      console.warn('Error');
    });
  };

  $(document).on('submit', '[data-product-form]', function(e) {
    e.preventDefault();
  });

  $(document).on('click', '[data-product-variant-button]', function(e) {

    var searchId = this.dataset.remover;
    var button = document.getElementById(searchId);
    console.log("This should be the single add button");
    console.log(this);
    // should hide the add button
    // this.toggle()

    
    button.style.visibility="visible";
    $('#'+searchId).toggle();  

    var $element = $(this);
    var $form = $element.closest('form');
    var disableWithText = $element.attr('data-disable-with');
    var initialText = $element.html();

    $element.prop('disabled', true).html(disableWithText);

    
    counter += 1;
    document.getElementById("counter").innerHTML = counter;
    submit($form, function() {
      $element.html('Added').addClass('button--success');

      $element.toggle();

      window.setTimeout(function() {
        $element.prop('disabled', false).html(initialText).removeClass('button--success');
      }, 1500);
      
    });

  });


  $(document).on('click', '.single-remove-button', function(e) {

    var $element = $(this);

    var $searchId = $element[0];
    $searchId.style.visibility="hidden";
    var $form = $element.closest('form');
    // $form.first().find('select')[0].value = "0";
    $form.first().find('#quantity')[0].value = "0";

    // reveal button once product removed from cart
    $form.first().find("button").toggle();
    $element.toggle();


    counter -= 1;
    document.getElementById("counter").innerHTML = counter;

    submit($form, function(){

      // $form.find('#variant_id')[0].value = ""
    })
    $form.first().find('#quantity')[0].value = "1";


  });

  $(document).on('click', '.remove-button', function(e) {


    var $element = $(this);

    var $searchId = $element[0];


    $searchId.style.visibility="hidden";
    $element.toggle();
    
    var $form = $element.closest('form');
    // RE ENABLE SELECT AFTER REMOVE IS PRESSED
    $form.first().find('select')[0].disabled=false;
    
    $form.first().find('#quantity')[0].value = "0";



    var url = $form.attr('action');
    counter -= 1;
    document.getElementById("counter").innerHTML = counter;
    submit($form, function(){
      $form.first().find('select')[0].value = "";
    }, true)

    if (counter < 1) {
      var $fixedFooterButton = $('#fixed-footer-button');
      var nextStepText = 'To begin, select meds';
      $fixedFooterButton.html(nextStepText).addClass('fixed-footer-button--inactive').removeClass('active');
    }
    $form.first().find('#quantity')[0].value = "1";
    

  });

  $(document).on('change', '[data-product-variant-selector]', function(e) {
    var $element = $(this);

    var $form = $element.closest('form');
    var disableWithText = $element.attr('data-disable-with');

    // ARMEN
    var searchId = this.dataset.remover;
    var button = document.getElementById(searchId);
    button.style.visibility="visible";
    //$('#'+searchId).toggle();   
    // END ARMEN
    $element.hide().before('<div class="product-variant-selector-state-button button button--outline">' + disableWithText + '</div>');

    var $stateButton = $form.find('.product-variant-selector-state-button');
    counter += 1;
    document.getElementById("counter").innerHTML = counter;
    submit($form, function() {
      $stateButton.html('Added').addClass('button--success');

      window.setTimeout(function() {
        $stateButton.remove();
        $element.show();
      }, 1500);
    });
    // DISABLE THE SELECT AFTER ONE HAS BEEN SELECTED
    this.disabled=true;
  });
})();
