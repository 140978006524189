import React from 'react';
import { LabelLarge, ParagraphXSmall, ParagraphMedium } from 'baseui/typography';

const data = {
    en: {
        prescribing_conflict_title: "Prescribing conflicts",
        prescribing_conflict_override: "Ignore",
        prescribing_conflict_cancel: "Go back and fix",

        prescribing_conflict_tab_drug: <LabelLarge>Drug</LabelLarge>,
        prescribing_conflict_tab_pharmacy: <LabelLarge>Pharmacy</LabelLarge>,
        prescribing_conflict_tab_warning: <LabelLarge>Warning</LabelLarge>,

        prescribing_conflict_at_least_one_drug_warning: <ParagraphMedium style={{color: "red", margin: 0}}>At least 1 drug request must be fulfilled in it’s entirety to onboard a new patient</ParagraphMedium>,
        prescribing_conflict_warning: '',

        prescribing_conflict_all_good: "All good",

        encounter_note_button: "Encounter note",
        encounter_note_title: "Add an encounter note",
        encounter_note_submit_title: "Add"
    }
}

const content = (lang) => {
    return data[lang];
};

export default content;
