import React, {Component} from 'react';
import { Button, KIND, SIZE, SHAPE } from 'baseui/button';
import { Plus, Upload } from 'baseui/icon';
import Logger from '../../../shared/Utils/logger';
import * as ROUTES from '../../Constants';
import Request from '../../../shared/Request';
import Utils from '../../../shared/Utils';
import { AuthUserContext, withAuthorization } from '../../../shared/Session';


class AttachmentButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
        isLoading: false,
      };
    this.fileInputRef = React.createRef();
  }

  handleFileButtonClick = () => {
    this.fileInputRef.current.click(); // Click the hidden file input
    this.setState({ isLoading: true }); // Set isLoading to true when the button is clicked
  };


  handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const allowedTypes = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'];
      const allowedSize = 10 * 1024 * 1024; // 10MB

      // Check if the file type and size are valid
      if (allowedTypes.includes(selectedFile.type) && selectedFile.size <= allowedSize) {
        // Do something with the selected file here (e.g., upload, read, etc.)
        console.log(selectedFile);
      } else {
        // File type or size is not valid, show an alert with the error message
        window.alert('Invalid file. Please select a file of type .pdf, .jpeg, .jpg, or .png, and size 10MB or less.');
      }
      this.uploadFile(event.target.files[0]);

    } else {
      this.setState({ isLoading: false });
    }
  };


  uploadFile = (file_upload) => {
    const data = new FormData();
    data.append('file_upload', file_upload);
    data.append('patient_uuid', this.props.patient_uuid);
    data.append('protocol_number', this.props.protocol_number);

    Request.post({
      firebase: this.props.firebase,
      route: ROUTES.BASE_URL_API + "/provider_message",
      body: data,
      callbackSuccess: (result) => {this.setState({
        isLoading: false,
        ...Utils.clearAllNotifications()
      });
        if (this.props.callback) {
          this.props.callback();
        }
      },
      callbackError: (error) => this.setState({
        isLoading: false,
        error,
        ...Utils.clearAllNotificationsExceptError()
      })
    })
  };


  //This doesn't seem to do anything...
  handleFilePickerCancel = () => {
    this.setState({ isLoading: false }); // Set isLoading back to false when the file picker is canceled
  };

  render() {
    return (
      <React.Fragment>
        <input
          type="file"
          ref={this.fileInputRef}
          style={{ display: 'none' }}
          onChange={this.handleFileChange}
          onCancel={this.handleFilePickerCancel} // Handle file picker cancel event
          accept=".pdf,.jpeg,.jpg,.png" // Set accepted file types
        />
        <Button
            onClick={this.handleFileButtonClick}
            isLoading={this.state.isLoading}
            kind={KIND.secondary}
            size={SIZE.compact}
            shape={SHAPE.pill}
            startEnhancer={() => <Plus size={16} />}
            overrides={{
                BaseButton: {
                    style: ({ $theme }) => ({
                    })
                    },
                StartEnhancer: {
                    style: ({ $theme }) => ({
                        marginRight: '3px'
                    })
                }
            }}
        >Attach file
        </Button>
      </React.Fragment>
    );
  }
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(AttachmentButton);
