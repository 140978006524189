import React, {Component} from 'react'
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import { LabelLarge, ParagraphLarge, HeadingXSmall, HeadingSmall, LabelSmall, LabelMedium } from 'baseui/typography';
import Utils from '../../shared/Utils';
import Logger from '../../shared/Utils/logger';

const outlinedBlock = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                border: "1px solid " + $theme.colors.backgroundTertiary,
                marginBottom: "8px",
                borderRadius: "13px",
                padding: "12px"
            }),
        },
    },
};

class MedicalInfo extends Component{
    constructor(props){
        super(props);
    }

    renderBox = (color, title, content) => {
        return <FlexGrid {...outlinedBlock}>
            <FlexGridItem><LabelSmall style={{margin:'0 0 6px 0', color: '#999999'}}>{title}</LabelSmall></FlexGridItem>
            <FlexGridItem><LabelMedium style={{margin:0}}>{content}</LabelMedium></FlexGridItem>
        </FlexGrid>
    };

    allergies = () => {
        if (this.props.patient.reported_allergies.length > 0) {
            return this.props.patient.reported_allergies.map((allergy, idx) => <span key={idx}>{idx > 0 ? ', ' : ''}{allergy.short_name}{/*({allergy.details.severity})*/}</span>);
        } else {
            return "None reported";
        }
    }

    reportedRx = () => {
        if (this.props.patient.reported_medications.length > 0) {
            return this.props.patient.reported_medications.map((rx, idx) => <span key={idx}>{idx > 0 ? ', ' : ''}{rx.short_name}</span>);
        } else {
            return "None reported";
        }
    }

    reportedConditions = () => {
        if (this.props.patient.reported_conditions.length > 0) {
            return this.props.patient.reported_conditions.map((condition, idx) => <span key={idx}>{idx > 0 ? ', ' : ''}{condition.short_name}</span>);
        } else {
            return "None reported";
        }
    }

    render = () => {
        return <FlexGrid>
            <FlexGridItem>{this.renderBox('#d9ffcf', 'Drug allergies', this.allergies())}</FlexGridItem>
            <FlexGridItem>{this.renderBox('#d9ffcf', 'Conditions', this.reportedConditions())}</FlexGridItem>
            <FlexGridItem>{this.renderBox('#d9ffcf', 'Meds & supplement list', this.reportedRx())}</FlexGridItem>
    </FlexGrid>
        
        
        
        {/*<FlexGrid>
            <FlexGridItem>{this.renderBox('#d9ffcf', 'Reported drug allergies', this.allergies())}</FlexGridItem>
            <FlexGridItem>{this.renderBox('#d9ffcf', 'Reported conditions', this.reportedConditions())}</FlexGridItem>
            <FlexGridItem>{this.renderBox('#d9ffcf', 'Reported meds & supplements', this.reportedRx())}</FlexGridItem>
    </FlexGrid>*/}
    };
}

export default MedicalInfo;
