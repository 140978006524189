import React, {Component} from 'react';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import * as ROUTES from '../Constants';
import { PATIENT_ROUTES } from '../../shared/Constants';
import Utils from '../../shared/Utils';
import { Input } from "baseui/input";
import { Textarea } from "baseui/textarea";
import {Button, KIND, SIZE, SHAPE} from 'baseui/button'
import { Checkbox, LABEL_PLACEMENT, STYLE_TYPE } from "baseui/checkbox";
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import ProtocolForm from '../ProtocolFormManagement';
import Optionals from './optional';
import Request from '../../shared/Request';
import {Spinner} from '../../shared/Spinner';
import Logger from '../../shared/Utils/logger';
import Screen, {Size as ScreenSize} from '../../shared/Utils/screen';
import MpsModal from '../../shared/Utils/modal';
import { ParagraphLarge, HeadingSmall, HeadingXSmall, LabelLarge, LabelMedium, ParagraphSmall, ParagraphXSmall, HeadingMedium } from 'baseui/typography';
import { Tag, SIZE as TagSize, KIND as TagKind , VARIANT} from "baseui/tag";
import { Select } from "baseui/select";
import {Conditions} from '../../shared/MedicalSearch';
import content from './content';
import Show from 'baseui/icon/show';
import ShareInvite from '../ShareInvite';
import {StatefulTreeView, TreeLabelInteractable} from 'baseui/tree-view';
import {Plus as PlusIcon} from 'baseui/icon';
import {CheckIndeterminate as CheckIndeterminateIcon} from 'baseui/icon';
import { Slider } from "baseui/slider";
import { Alert, Delete} from "baseui/icon";
import {StatefulTooltip, TRIGGER_TYPE} from 'baseui/tooltip';
import {Block} from 'baseui/block';
import { Accordion, Panel } from "baseui/accordion";
import objectToFormData from "../../shared/Utils/form_data";
import { FileUploader } from "baseui/file-uploader";


const centerProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }),
        },
    },
};

const leftCenterProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',

            }),
        },
    },
};

const endProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
                marginTop:'10px'
            }),
        },
    },
};

const narrowProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                width: '70px',
                flexGrow: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }),
        },
    },
};

const narrowRightProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                width: '70px',
                flexGrow: 0,
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
            }),
        },
    },
};

class ProtocolManagement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            submitable: false,
            submit: false,
            content: this.getContent(),
            new_protocol: this.props.match.params.id ? false : true,
            protocol: this.props.match.params.id ? null : {
                billing_price: [],
                description: '',
                id: '',
                name: '',
                patient_eligibility: '',
                requested_formulary: [],
                status: 'draft',
                summary: '',
                protocol_tasks: [],
                welcome_message: '',
                office_hours: '',
                risks: '',
                rejecting_new_subscriptions: false,
                enable_community_features: false,
                is_refill_service: false,
                server_rules: '',
            },
            inviteCode: null,
            conditions: [],
            conditions_add: false,
            error: null,
            errorVideoUrl: null,
            success: null,
            onboarding_form_id: '',
            onboarding_form_show: false,
            onboarding_form: [],
            drug_request_form_id: '',
            drug_request_form_show: false,
            drug_request_form: [],
            protocol_id: this.props.match.params.id,
            video_call_enable: false,
            video_call_url: '',
            video_call_schedule: '',
            onboarding_file_attachment_enable: false,
            onboarding_file_attachment_description: '',
            drug_request_file_attachment_enable: false,
            drug_request_file_attachment_description: '',
            showShareInvite: false,
        }
    }

    getContent = () => {
        return content('en');
    };

    componentDidMount = () => {
        if (!this.state.new_protocol){
            this.getProtocol();
        }
  
        /*
        let previousPosition = 0;

        document.addEventListener('focusin', function(e) {
        // Store the current position before the user interacts with an element
        previousPosition = window.scrollY;
        });

        document.addEventListener('focusout', function(e) {
        // Scroll back to the previous position when the user loses focus
        window.scroll({
            top: previousPosition,
            left: 0,
            behavior: 'smooth'
        });
        });


        document.addEventListener('focusout', function(e) {window.scroll({
            top: '',
            left: 0,
            behavior: 'smooth'
        })});*/
    };

    loadProtocolState = (result) => {
        Logger.log('Protocol management results;');
        Logger.log(result);
        var new_conditions = [];
        result.protocol_metadata_items && result.protocol_metadata_items.forEach((val) => {
            new_conditions.push(
                {
                    id: val.id,
                    details: JSON.parse(val.details),
                    short_name: val.short_name,
                    type: "ReportedCondition"
                }
            );
        });
        Logger.log(new_conditions);
        this.setState({conditions: new_conditions});

        var settings = {
            billing_price: result.billing_price ? [{label: Utils.renderDollarAmount(result.billing_price), id: parseFloat(result.billing_price).toFixed(2)}] : [],
            description: result.description ? result.description : '',
            id: result.id,
            name: result.name ? result.name : '',
            creator_email: result.creator_email,
            patient_eligibility: result.patient_eligibility ? result.patient_eligibility : '',
            requested_formulary: result.requested_formulary ? result.requested_formulary : [],
            status: result.status,
            summary: result.summary ? result.summary : '',
            protocol_tasks: result.protocol_tasks ? result.protocol_tasks : [],
            welcome_message: result.welcome_message ? result.welcome_message : '',
            office_hours: result.office_hours,
            risks: result.risks ? result.risks : '',
            rejecting_new_subscriptions: result.rejecting_new_subscriptions ? result.rejecting_new_subscriptions : false,
            enable_community_features: result.enable_community_features ? result.enable_community_features : false,
            is_refill_service: result.is_refill_service ? result.is_refill_service : false,
            server_rules: result.server_rules ? result.server_rules : '',
        };
        this.setState({
            favicon_image: result.favicon_image_url,
            original_favicon_image: result.favicon_image_url,
            favicon_image_contents: null,
            inviteCode: result.invite_code ? result.invite_code : '',
            protocol: settings,
            submitable: result.status == 'draft',
            onboarding_form_id: result.onboarding_form_id,
            onboarding_form: result.onboarding_form && result.onboarding_form.structure_json ? result.onboarding_form.structure_json : [],
            drug_request_form_id: result.drug_request_form_id,
            drug_request_form: result.drug_request_form && result.drug_request_form.structure_json ? result.drug_request_form.structure_json : []
        });
    };

    getProtocol = () => {
        Request.get({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/physician_protocol/protocols/view/" + this.state.protocol_id,
            callbackSuccess: (result) => {
                this.loadProtocolState(result)
            },
            callbackError: (error) => {
                this.setState({
                    isLoaded: true,
                    error,
                    ...Utils.clearAllNotificationsExceptError()
                })
            }
        })
    };

    areRequiredFieldsSet = (withError) => {
        if (![
                this.state.protocol.name,
                this.state.protocol.description,
                //this.state.protocol.patient_eligibility,
                this.state.protocol.summary,
                this.state.protocol.welcome_message,
                this.state.protocol.office_hours,
                //this.state.protocol.risks,
                //this.state.protocol.server_rules
            ].every(element => element != '')
            || this.state.protocol.billing_price.length == 0
            || this.state.protocol.requested_formulary.length == 0
            //|| this.state.conditions.length == 0
            //|| this.state.conditions.every(condition => condition._destroy == true)
            || this.state.onboarding_form.length == 0
            || this.state.drug_request_form.length == 0
        ) {
            Logger.log("Required fields are not all set...");
            if (withError) {
                this.setState({error: {message: "All required fields must be filled to save changes."}})
            }

            return false;
        }
        Logger.log("Required fields ARE all set...");
        return true;
    };

    drawConditions = () => {
        return this.renderItemBody(
            this.state.content.protocol_conditions_title,
            //this.state.content.conditions_description,
            <div style={{marginBottom:'14px'}} >
                {this.renderConditionsToAdd()}
                <Button
                    style={{marginTop: this.state.conditions.length > 0 ? '10px': '0px'}}
                    kind={this.state.conditions.length > 0 ? KIND.secondary : KIND.primary}
                    shape={SHAPE.default}
                    size={SIZE.default}
                    disabled={['pending'].includes(this.state.protocol.status)}
                    onClick={(e) => this.setState({conditions_add: true}, () => Logger.log(this.state.conditions))}
                    overrides={{
                        BaseButton: {
                            style: ({ $theme }) => ({
                                maxWidth:['XSMALL', 'SMALL'].includes(Screen.size()) ? 'calc(100vw - 32px)': '570px',
                                width: '100%',
                            })
                        }
                    }}
                >{this.state.conditions.length > 0 ? 'Update diagnoses required to join' : 'Add specific diagnoses required to join'}</Button>
            </div>
        )
    };

    saveProtocol = () => {
        this.setState({success: null, error: null, submitable: true});

        if (this.state.new_protocol) {
            this.createNewProtocol();
        } else {
            this.saveEditProtocol();
        }
    };

    saveEditProtocol = () => {
        Logger.log('Save edit protocol...');
        Logger.log(this.state.protocol.status);
        if (
            (this.state.protocol.status == 'draft' && this.canDraftSave())
            || (this.state.protocol.status == 'active' && this.areRequiredFieldsSet(true))
        ){
            Logger.log('Save edit protocol entered...');
            var data = {
                id: this.state.protocol.id,
                summary: this.state.protocol.summary,
                description: this.state.protocol.description,
                patient_eligibility: this.state.protocol.patient_eligibility,
                welcome_message: this.state.protocol.welcome_message,
                office_hours: this.state.protocol.office_hours,
                requested_formulary: this.state.protocol.requested_formulary,
                protocol_metadata_items_attributes: this.state.conditions.filter((val) => ((val.id || !val._destroy) && val.short_name && val.short_name.length > 0)),
                onboarding_form_attributes: { name: this.state.protocol.name + ' Onboarding Form',
                    form_type: 'onboarding_form',
                    structure_json: this.state.onboarding_form },
                drug_request_form_attributes: { name: this.state.protocol.name + ' Drug Request Form',
                    form_type: 'drug_request_form',
                    structure_json: this.state.drug_request_form },
                protocol_tasks_attributes: this.state.protocol.protocol_tasks,
                risks: this.state.protocol.risks,
                rejecting_new_subscriptions: this.state.protocol.rejecting_new_subscriptions,
                enable_community_features: this.state.protocol.enable_community_features,
                is_refill_service: this.state.protocol.is_refill_service,
                server_rules: this.state.protocol.server_rules,
                invite_code: this.state.inviteCode,
            }

            if (this.state.favicon_image != this.state.original_favicon_image) {
              data['favicon_image'] = this.state.favicon_image
            }

            var url = "/physician_protocol/protocol_active_edit";

            if (this.state.protocol.status == 'draft') {
                data['name'] = this.state.protocol.name;
                data['billing_price'] = this.state.protocol.billing_price.length > 0 ? this.state.protocol.billing_price[0].id : null;

                url = "/physician_protocol/protocol_draft_edit";
            }

            Logger.log("Saving protocol");
            data = objectToFormData(data);

            Request.post({
                firebase: this.props.firebase,
                route: ROUTES.BASE_URL_API + url,
                body: data,
                callbackSuccess: (result) => {
                    this.loadProtocolState(result.protocol);
                    this.setState({success: {message: result.message}, ...Utils.clearAllNotificationsExceptSuccess()});
                },
                callbackError: (error) => {
                    this.setState({error});
                }
            })
        }
        Logger.log('Save edit protocol end...');
    };

    submitProtocol = () => {
        Request.put({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + '/physician_protocol/submit/' + this.state.protocol.id,
            callbackSuccess: (result) => {
                this.loadProtocolState(result.protocol);
                this.setState(prevState => ({
                success: {message: this.state.content.protocol_submitted_description},
                ...Utils.clearAllNotificationsExceptSuccess()
            }))},
            callbackError: (error) => {
                this.setState({error})
            }
        });
    };

    canDraftSave = () => {
        if (!this.state.protocol.name) {
            this.setState({
                error: {message: 'The following fields are required: Name'},
                ...Utils.clearAllNotificationsExceptError()
            });
            return false;
        }

        return true;
    };

    createNewProtocol = () => {
        if (this.canDraftSave()){
            Logger.log("Conditions:");
            Logger.log(this.state.conditions);

            var data = {
                name: this.state.protocol.name,
                billing_price: this.state.protocol.billing_price.length > 0 ? this.state.protocol.billing_price[0].id : null,
                summary: this.state.protocol.summary,
                description: this.state.protocol.description,
                patient_eligibility: this.state.protocol.patient_eligibility,
                welcome_message: this.state.protocol.welcome_message,
                office_hours: this.state.protocol.office_hours,
                requested_formulary: this.state.protocol.requested_formulary,
                onboarding_form_attributes: { form_type: 'onboarding_form', structure_json: this.state.onboarding_form },
                drug_request_form_attributes: { form_type: 'drug_request_form', structure_json: this.state.drug_request_form },
                protocol_tasks_attributes: this.state.protocol.protocol_tasks,
                protocol_metadata_items_attributes: this.state.conditions.filter((val) => (val.short_name && val.short_name.length > 0)),
                risks: this.state.protocol.risks,
                rejecting_new_subscriptions: this.state.protocol.rejecting_new_subscriptions,
                enable_community_features: this.state.protocol.enable_community_features,
                is_refill_service: this.state.protocol.is_refill_service,
                server_rules: this.state.protocol.server_rules,
                invite_code: this.state.inviteCode,
            }

            if (this.state.favicon_image && this.state.favicon_image_contents) {
                data['favicon_image'] = this.state.favicon_image;
            }

            data = objectToFormData(data);

            Request.post({
                firebase: this.props.firebase,
                route: ROUTES.BASE_URL_API + "/physician_protocol",
                body: data,
                callbackSuccess: (result) => {
                    this.loadProtocolState(result.protocol);
                    this.setState(prevState => ({
                        success: {message: result.message},
                        new_protocol: false,
                        ...Utils.clearAllNotificationsExceptSuccess()
                    }));
                },
                callbackError: (error) => {
                    this.setState({error, ...Utils.clearAllNotificationsExceptError()})
                }
            })
        }
    };

    deleteFormulary = (event, id) => {
        event.preventDefault();

        let currentList = this.state.protocol.requested_formulary;
        currentList.splice(id, 1);

        this.setState(prevState => ({
            protocol: {
                submitable: false,
                ...prevState.protocol,
                requested_formulary: currentList
            },
            ...Utils.clearAllNotifications(),
        }));
    }

    newFormularyEntry = () => { return {name: '', dosage: '', quantity: ''}};
    addNewFormulary = (event) => {
        event.preventDefault();

        this.setState(prevState => ({
            protocol: {
                submitable: false,
                ...prevState.protocol,
                requested_formulary: [...prevState.protocol.requested_formulary, this.newFormularyEntry()]
            },
            ...Utils.clearAllNotifications(),
        }));
    };

    handleProtocolDataChange = (event) => {
        event.persist();
        this.setState(prevState => ({
            submitable: false,
            protocol: {
            ...prevState.protocol,
            [event.target.name]: event.target.value
            },
            ...Utils.clearAllNotifications(),
        }));
      }      

    handleProtocolDataCheckbox = (event) => {
        event.persist();
        this.setState(prevState => ({
            submitable: false,
            protocol: {
                ...prevState.protocol,
                [event.target.name]: event.target.checked
            },
            ...Utils.clearAllNotifications(),
        }));
    };

    handlBillingPrice = (data) => {
        this.setState(prevState => ({
            submitable: false,
            protocol: {
                ...prevState.protocol,
                billing_price: data.value
            },
            ...Utils.clearAllNotifications()
        }));
    };

    handleFormularyChange = (event, idx) => {
        let requested_formulary = [...this.state.protocol.requested_formulary]
        requested_formulary[idx][event.target.name] = event.target.value;

        this.setState(prevState => ({
            submitable: false,
            protocol: {
                ...prevState.protocol,
                requested_formulary
            },
            ...Utils.clearAllNotifications()
        }));
    };

    // Render Methods /////////////////////////////////////////////////////////////////
    renderStatus = () => {
        if (this.state.protocol.status == 'draft') {
            return <FlexGridItem {...centerProps}>
                <Tag
                    closeable={false}
                    kind={TagKind.custom}
                    size={TagSize.large}
                    color="#40e6ff"
                    variant={VARIANT.solid}
                    overrides={{
                        Root: {
                        style: ({ $theme }) => ({
                            borderRadius: '4px',
                            margin: '20px 0 0 0',
                        })
                        },
                        Text: {
                            style: ({ $theme }) => ({
                                maxWidth: 'fit-content',
                                width: '100%'
                            })
                        }
                    }}
                    >Clinic builder</Tag>
            </FlexGridItem>;
        } else if (this.state.protocol.status == 'active') {
            return <FlexGridItem {...centerProps}>
                <Tag
                    closeable={false}
                    kind={TagKind.custom}
                    size={TagSize.large}
                    color="#00ff00"
                    variant={VARIANT.solid}
                    overrides={{
                        Root: {
                        style: ({ $theme }) => ({
                            borderRadius: '4px',
                            margin: '20px 0 0 0',
                        })
                        },
                        Text: {
                            style: ({ $theme }) => ({
                                maxWidth: 'fit-content',
                                width: '100%'
                            })
                        }
                    }}
                >{this.state.protocol.name} settings</Tag>
            </FlexGridItem>;
        } else if (this.state.protocol.status == 'pending') {
            return <FlexGridItem {...centerProps}>
                <Tag
                    closeable={false}
                    kind={TagKind.custom}
                    size={TagSize.large}
                    color="#d576f8"
                    variant={VARIANT.solid}
                    overrides={{
                        Root: {
                        style: ({ $theme }) => ({
                            borderRadius: '4px',
                            margin: '20px 0 0 0',
                        })
                        },
                        Text: {
                            style: ({ $theme }) => ({
                                maxWidth: 'fit-content',
                                width: '100%'
                            })
                        }
                    }}
                >{this.state.protocol.name} is pending</Tag>
            </FlexGridItem>;
        } else {
            return <FlexGridItem {...centerProps}>
                <Tag
                closeable={false}
                kind={TagKind.custom}
                size={TagSize.large}
                color="#9a9a9a"
                variant={VARIANT.solid}
                overrides={{
                    Root: {
                      style: ({ $theme }) => ({
                        borderRadius: '4px',
                        margin: '20px 0 0 0',
                      })
                    },
                    Text: {
                        style: ({ $theme }) => ({
                            maxWidth: 'fit-content',
                            width: '100%'
                        })
                    }
                  }}
                >{this.state.protocol.name} is inactive</Tag>
            </FlexGridItem>;
        }
    };


    canUploadMorePhoto = () => {
        return this.state.favicon_image == null;
    }

    handleFaviconImageUpload = (acceptedFiles, rejectedFiles) => {
      var fr = new FileReader();

      fr.onload = (ev2) => {
        console.dir(ev2);
        this.setState({favicon_image_contents: ev2.target.result});
      };

      fr.readAsDataURL(acceptedFiles[0]);

      this.setState({
        favicon_image: acceptedFiles[0],
        ...Utils.clearAllNotifications()
      });
    }

    clearFavicon = () => {
      this.setState({favicon_image: null});
    }

    renderFaviconUpload = () => {
      return this.renderItemBody(
        this.state.content.protocol_favicon_image,
        this.renderUploader());
    }

    renderUploader = () => {
      if (this.state.favicon_image == null) {
        return  <FileUploader
        name='favicon_image'
        accept=".jpeg,.jpg,.png"
        maxSize={10000000}
        onRetry={this.faviconImageUploadRetry}
        onDrop={(acceptedFiles, rejectedFiles) => {
          // handle file upload...
          Logger.log('File Upload In Progress...');
          Logger.log(acceptedFiles);
          Logger.log(rejectedFiles);
          this.handleFaviconImageUpload(acceptedFiles, rejectedFiles);
        }}
        errorMessage={this.state.favicon_image_error}
          />
      } else {
        return <div>
          <div style={{textAlign: 'center'}}><img src={this.state.favicon_image_contents ? this.state.favicon_image_contents : this.state.favicon_image} alt="Current Favicon image" width="50%"/></div>
          {Utils.renderSpace()}
          <Button onClick={this.clearFavicon} shape={SHAPE.pill} kind={KIND.secondary} size={SIZE.compact}>Replace</Button>
          </div>
      }


    }

    renderName = () => {
        return this.renderItemBody(
            this.state.content.protocol_name,
            //this.state.content.protocol_name_description,
            <Input
                name='name'
                type='text'
                value={this.state.protocol.name}
                onChange={this.handleProtocolDataChange}
                placeholder={this.state.content.protocol_name_placeholder}
                disabled={!['draft'].includes(this.state.protocol.status)}
            />
        );
    };

    renderSummary = () => {
        return this.renderItemBody(
            this.state.content.protocol_summary,
            //this.state.content.protocol_summary_description,
            <Input
                name='summary'
                value={this.state.protocol.summary}
                onChange={this.handleProtocolDataChange}
                placeholder={this.state.content.protocol_summary_placeholder}
                disabled={['pending'].includes(this.state.protocol.status)}
            />
        );
    };

    renderDescription = () => {
        return this.renderItemBody(
            this.state.content.protocol_description,
            //this.state.content.protocol_description_text,
            <Textarea
                name='description'
                value={this.state.protocol.description}
                onChange={this.handleProtocolDataChange}
                placeholder={this.state.content.protocol_description_placeholder}
                disabled={['pending'].includes(this.state.protocol.status)}
                overrides={{
                    Input: {
                      style: {
                        maxHeight: '300px',
                        minHeight: '100px',
                        minWidth: '300px',
                        width: '100vw', // fill all available space up to parent max-width
                        resize: 'both',
                      },
                    },
                    InputContainer: {
                      style: {
                        maxWidth: '100%',
                        width: 'min-content',
                      },
                    },
                  }}
            />
        );
    };

    renderIsRefillService = () => {
      return this.renderItemBody(
        <span>Refill Service</span>,
        this.state.content.is_refill_service,
        <Checkbox
        name='is_refill_service'
        checked={this.state.protocol.is_refill_service}
        onChange={this.handleProtocolDataCheckbox}
        labelPlacement={LABEL_PLACEMENT.right}
        disabled={""}
        checkmarkType={STYLE_TYPE.toggle}
        overrides={{
          ToggleTrack: {
            style: ({ $theme }) => ({
              width:'100%',
              maxWidth: '40px'
            })
          }
        }}
        ><LabelMedium style={{margin: 0}}>Enable Refill Service</LabelMedium></Checkbox>)
    };

    renderFormulary = () => {
        return this.renderItemBody(
            <span>{this.state.content.protocol_formulary}
            <StatefulTooltip
                    accessibilityType={'tooltip'}
                    content={() => (
                        <Block padding={"10px"} marginRight={'auto'} marginLeft={'auto'} maxWidth={"fit-content"} display={'flex'} justifyContent={'center'}>
                            A list of medications approved for your members to request.
                        </Block>
                    )}
                    showArrow
                ><Alert style={{transform: "rotate(180deg)"}}/>
            </StatefulTooltip></span>,
            //this.state.content.protocol_formulary_description,
            <div>{this.state.protocol.requested_formulary.map((formulary, idx) => {
                return <FlexGrid
                    key={idx}
                    flexGridColumnCount={4}
                    flexGridColumnGap="scale200"
                    marginBottom="scale400"
                    display={'flex'}
                    flexWrap={'wrap'}
                >
                    <FlexGridItem style={{flexBasis:'30%'}}>
                        <Input
                            name="name"
                            value={this.state.protocol.requested_formulary[idx].name}
                            onChange={(event) => this.handleFormularyChange(event, idx)}
                            disabled={['active', 'pending', 'inactive'].includes(this.state.protocol.status)}
                            placeholder={this.state.content.protocol_formulary_drug_placeholder}/>
                    </FlexGridItem>
                    <FlexGridItem style={{flexBasis:'25%'}}>
                        <Input
                            name="dosage"
                            value={this.state.protocol.requested_formulary[idx].dosage}
                            onChange={(event) => this.handleFormularyChange(event, idx)}
                            disabled={['active', 'pending', 'inactive'].includes(this.state.protocol.status)}
                            placeholder={this.state.content.protocol_formulary_dosage_placeholder}/>
                    </FlexGridItem>
                    <FlexGridItem style={{flexBasis:'25%'}}>
                        <Input
                            name="quantity"
                            value={this.state.protocol.requested_formulary[idx].quantity}
                            onChange={(event) => this.handleFormularyChange(event, idx)}
                            disabled={['active', 'pending', 'inactive'].includes(this.state.protocol.status)}
                            placeholder={this.state.content.protocol_formulary_quantity_placeholder}/>
                    </FlexGridItem>
                    <FlexGridItem style={{flexBasis:'5%'}}>
                        <Button
                            kind={KIND.tertiary}
                            shape={SHAPE.default}
                            size={SIZE.default}
                            disabled={['active', 'pending', 'inactive'].includes(this.state.protocol.status)}
                            overrides={{
                                BaseButton: {
                                    style: ({ $theme }) => ({
                                       padding:'12px 8px 12px 8px'
                                    })
                                }
                            }}
                            onClick={(event) => this.deleteFormulary(event, idx)}><Delete size={24}/></Button>

                    </FlexGridItem>
                </FlexGrid>
            })}
            <div><Button
                kind={KIND.primary}
                shape={SHAPE.default}
                size={SIZE.default}
                onClick={this.addNewFormulary}
                disabled={['active', 'pending', 'inactive'].includes(this.state.protocol.status)}
                overrides={{
                    BaseButton: {
                        style: ({ $theme }) => ({
                            maxWidth:['XSMALL', 'SMALL'].includes(Screen.size()) ? 'calc(100vw - 32px)': '570px',
                            width: '100%',
                        })
                    }
                }}
            >Add therapeutic</Button></div>
            </div>)
    };

    renderFormularyEntry = (formulary) => {
        return <FlexGridItem>{formulary}</FlexGridItem>
    };

    renderPatientEligibility = () => {
        return this.renderItemBody(
            this.state.content.protocol_patient_eligibility,
            //this.state.content.protocol_patient_eligibility_description,
            <Textarea
                name='patient_eligibility'
                value={this.state.protocol.patient_eligibility}
                onChange={this.handleProtocolDataChange}
                placeholder={this.state.content.protocol_patient_eligibility_placeholder}
                disabled={['pending', 'inactive'].includes(this.state.protocol.status)}
                overrides={{
                    Input: {
                        style: {
                        maxHeight: '300px',
                        minHeight: '100px',
                        minWidth: '300px',
                        width: '100vw', // fill all available space up to parent max-width
                        resize: 'both',
                        },
                    },
                    InputContainer: {
                        style: {
                            maxWidth: '100%',
                            width: 'min-content',
                        },
                    },
                }}
            />
        );
    };

    /*renderProtocolPriceWithSlider = () => {
        return this.renderItemBody(
            this.state.content.protocol_subscription_fee,
            <Slider
                value={this.state.protocol.billing_price}
                onChange={params => this.handlBillingPrice(params)}
                min={0}
                max={200}
                step={5}
                valueToLabel={value => `$${value}`}
                disabled={['active', 'pending', 'inactive'].includes(this.state.protocol.status)}
            />
            )
    };*/

    renderProtocolPrice = () => {
        let link = "https://www.notion.so/Run-pharmr-com-47b6d5c37ab54044a2ea5e206a15da63";
        return this.renderItemBody(
            this.state.content.protocol_subscription_fee,
            //this.state.content.protocol_subscription_fee_description,
            <Select
                options={[
                    { label: "Free", id: "0.00" },
                    { label: "$5.00", id: "5.00" },
                    { label: "$10.00", id: "10.00" },
                    { label: "$15.00", id: "15.00" },
                    { label: "$20.00", id: "20.00" },
                    { label: "$30.00", id: "30.00" },
                    { label: "$50.00", id: "50.00" },
                    { label: "$100.00", id: "100.00" },
                    { label: "$150.00", id: "150.00" }
                ]}
                searchable={false}
                value={this.state.protocol.billing_price}
                disabled={['active', 'pending', 'inactive'].includes(this.state.protocol.status)}
                onChange={params => this.handlBillingPrice(params)}
                inputMode="none"
            />)
    };

    callbackOnboarding = (data) => {
        this.setState({
            //onboarding_form_show: false,
            onboarding_form: data,
            submitable: false
        }, () => this.saveProtocol());
    };

    callbackCancelOnboarding = () => {
        this.setState({
            onboarding_form_show: false,
            ...Utils.clearAllNotifications()
        });
    };

    showOnboardingForm = () => {
        if (this.state.onboarding_form_show) {
            return <ProtocolForm
                key={1}
                title={this.state.protocol.name + " Onboarding Form"}
                data={this.state.onboarding_form}
                callback={this.callbackOnboarding}
                callbackCancel={this.callbackCancelOnboarding}
            />
        }
    };

    enableOnboardingForm = () => {
        this.setState({onboarding_form_show: true, success: null, error: null});
    };

    renderIntakeFormTitle = () => {
        return <FlexGridItem>
            <LabelLarge style={{margin: 0}}>{this.state.content.protocol_intake_form}</LabelLarge>
            {/*<ParagraphSmall style={{margin: 0, color: '#666666'}}>{this.state.content.protocol_intake_form_description}</ParagraphSmall>*/}
        </FlexGridItem>
    };

    renderIntake = () => {
        return <FlexGrid>
            <FlexGridItem style={{marginBottom:'4px'}}><LabelLarge style={{margin: 0}}>{this.state.content.protocol_intake_form}</LabelLarge></FlexGridItem>
            <FlexGridItem style={{marginBottom: '10px'}}> <Button
                            kind={this.state.onboarding_form.length > 0 ? KIND.secondary : KIND.primary}
                            shape={SHAPE.default}
                            size={SIZE.default}
                            disabled={['pending', 'inactive'].includes(this.state.protocol.status)}
                            onClick={this.enableOnboardingForm}
                            overrides={{
                                BaseButton: {
                                    style: ({ $theme }) => ({
                                        maxWidth:['XSMALL', 'SMALL'].includes(Screen.size()) ? 'calc(100vw - 32px)': '570px',
                                        width: '100%',
                                    })
                                }
                            }}
                        >{this.state.onboarding_form.length > 0 ? 'Edit onboarding form' : 'Create an onboarding form'}</Button></FlexGridItem>
            <FlexGridItem style={{marginBottom: '10px'}}><Button
                        kind={this.state.drug_request_form.length > 0 ? KIND.secondary : KIND.primary}
                        shape={SHAPE.default}
                        size={SIZE.default}
                        disabled={['pending', 'inactive'].includes(this.state.protocol.status)}
                        onClick={this.enableDrugRequestForm}
                        overrides={{
                            BaseButton: {
                                style: ({ $theme }) => ({
                                    maxWidth:['XSMALL', 'SMALL'].includes(Screen.size()) ? 'calc(100vw - 32px)': '570px',
                                    width: '100%',
                                })
                            }
                        }}
                    >{this.state.drug_request_form.length > 0 ? 'Edit drug request form' : 'Create a drug request form'}</Button></FlexGridItem>
        </FlexGrid>
    };

    renderOnboardingForm = () => {
        return <FlexGridItem>
            <FlexGrid flexGridColumnCount={2} gridColumnGap={"scale400"}>
                <FlexGridItem {...narrowProps}>
                    <Button
                        kind={KIND.primary}
                        shape={SHAPE.pill}
                        size={SIZE.compact}
                        disabled={['pending', 'inactive'].includes(this.state.protocol.status)}
                        onClick={this.enableOnboardingForm}
                    >{this.state.onboarding_form.length > 0 ? 'Edit' : 'Create'}</Button>
                </FlexGridItem>
                <FlexGridItem>
                    <LabelMedium style={{margin: 0}}>{this.state.content.protocol_onboarding_form}</LabelMedium>
                    <ParagraphSmall style={{margin: 0, color: '#666666'}}>{this.state.content.protocol_onboarding_form_description}</ParagraphSmall></FlexGridItem>
            </FlexGrid>
        </FlexGridItem>
    };

    callbackDrugRequest = (data) => {
        this.setState({
            //drug_request_form_show: false,
            drug_request_form: data,
            submitable: false,
        }, () => this.saveProtocol());
    };

    callbackCancelDrugRequest = () => {
        this.setState({drug_request_form_show: false});
    };

    enableDrugRequestForm = () => {
        this.setState({drug_request_form_show: true, success: null, error: null});
    };

    showDrugRequestForm = () => {
        if (this.state.drug_request_form_show){
            return <ProtocolForm
                key={2}
                title={this.state.protocol.name + " Drug Request Form"}
                data={this.state.drug_request_form}
                callback={this.callbackDrugRequest}
                callbackCancel={this.callbackCancelDrugRequest}
            />
        }
    };

    renderSubmitChickenMessage = () => {
        return <MpsModal
            open={this.state.submit}
            title={Utils.renderTitleCenter('Submit for review')}
            body={
                <ParagraphLarge>
                    {this.state.content.protocol_submission_description}
                </ParagraphLarge>
            }
            withFooter={true}
            callback={this.confirmSubmittion}
            callbackButton={'Continue'}
            callbackCancel={this.cancelSubmittion}

        />
    };

    confirmSubmittion = () => {
        this.setState({
            submit: false,
        })
        this.submitProtocol();
    };

    cancelSubmittion = () => {
        this.setState({
            submit: false,
            ...Utils.clearAllNotifications()
        })
    };

    renderDrugRequestForm = () => {
        return <FlexGridItem>
            <FlexGrid flexGridColumnCount={2} gridColumnGap={"scale600"}>
                <FlexGridItem {...narrowProps}>
                    <Button
                        kind={KIND.primary}
                        shape={SHAPE.pill}
                        size={SIZE.compact}
                        disabled={['pending', 'inactive'].includes(this.state.protocol.status)}
                        onClick={this.enableDrugRequestForm}
                    >{this.state.drug_request_form.length > 0 ? 'Edit' : 'Create'}</Button>
                </FlexGridItem>
                <FlexGridItem>
                    <LabelMedium style={{margin: 0}}>{this.state.content.protocol_drug_request_form_name}</LabelMedium>
                    <ParagraphSmall style={{margin: 0, color: '#666666'}}>{this.state.content.protocol_drug_request_form_description}</ParagraphSmall>
                </FlexGridItem>
            </FlexGrid>
        </FlexGridItem>
    };

    cancel = () => {
        if (this.state.protocol.status == 'draft') {
            this.props.history.push(ROUTES.HOME);
        } else {
            this.props.history.push(ROUTES.PROTOCOLS_VIEW + '/' + this.props.match.params.id);
        }
    };

    renderActionButtons = (authUser) => {
        return <FlexGridItem {...endProps}>
            <Button kind={KIND.tertiary}
                    shape={SHAPE.default}
                    size={SIZE.default}
                    onClick={this.cancel}>Close</Button>
            {(!this.state.protocol_id || ( this.state.protocol && this.state.protocol.creator_email == authUser.email ))?
              <Button
                  style={{marginLeft: '10px'}}
                  onClick={this.saveProtocol}>Save</Button> : null }
              {this.state.protocol && this.state.protocol.status == 'active'
              ?
              <Button style={{marginLeft: '10px'}}
                      size={SIZE.default}
                      shape={SHAPE.default}
                      kind={KIND.secondary}
                      $as={'a'}
                      href={[PATIENT_ROUTES.BASE_URL + PATIENT_ROUTES.PROTOCOL_VIEW + '/' + this.state.protocol_id]} target="_blank"><Show title="Preview" size={22}/>&nbsp;View&nbsp;clinic</Button>
                      : null}
              {this.state.protocol && this.state.protocol.status == 'draft' && this.state.protocol.creator_email == authUser.email
              ? <Button
              style={{marginLeft: '10px'}}
              overrides={{
                              BaseButton: {
                                  style: ({ $theme }) => ({
                                      color: $theme.colors.primaryB,
                                      backgroundColor: $theme.colors.positive400,
                                      ":hover": {
                                          backgroundColor: $theme.colors.positive500
                                      },
                                      ":focus": {
                                          backgroundColor: $theme.colors.positive300
                                      },
                                      ":active": {
                                          backgroundColor: $theme.colors.positive300
                                      },
                                  })
                              }
                          }}
              onClick={(e) => this.areRequiredFieldsSet(false)
              ? this.setState({submit: true, success: null, error: null})
              : this.setState({error: {message : 'Please complete all clinc overview fields'}})}>Submit</Button> : null}
        </FlexGridItem>
    };

    optionalsCallback = (settings) => {
        this.setState(prevState => (
            {
                protocol: {
                    ...prevState.protocol,
                    protocol_tasks: settings
                },
                ...Utils.clearAllNotifications()
            }
        ));
    };

    callbackError = (error) => {
        this.setState({errorVideoUrl: error, ...Utils.clearAllNotificationsExceptError()});
    };

    renderWelcomeMessage = () => {
        return this.renderItemBody(
            this.state.content.protocol_welcome_message,
            //this.state.content.protocol_description_text,
            <Textarea
                name='welcome_message'
                value={this.state.protocol.welcome_message}
                onChange={this.handleProtocolDataChange}
                placeholder={this.state.content.protocol_welcome_message_placeholder}
                disabled={['pending'].includes(this.state.protocol.status)}
                overrides={{
                    Input: {
                      style: {
                        maxHeight: '300px',
                        minHeight: '100px',
                        minWidth: '300px',
                        width: '100vw', // fill all available space up to parent max-width
                        resize: 'both',
                      },
                    },
                    InputContainer: {
                      style: {
                        maxWidth: '100%',
                        width: 'min-content',
                      },
                    },
                  }}
            />
        );
    };

    renderOfficeHours = () => {
        return this.renderItemBody(
            this.state.content.protocol_office_hours,
            //this.state.content.protocol_office_hours_description,
            <Input
                name='office_hours'
                value={this.state.protocol.office_hours}
                onChange={this.handleProtocolDataChange}
                placeholder={this.state.content.protocol_office_hours_placeholder}
                disabled={['pending', 'inactive'].includes(this.state.protocol.status)}
            />)
    };

    renderItemBody = (label, description, input) => {
        return <FlexGridItem>
            <LabelLarge style={{margin: 0}}>{label}</LabelLarge>
            <div style={{margin: 0, marginTop: '4px', marginBottom: '10px', color: '#666666'}}>{description}</div>
            {input}
            </FlexGridItem>
    };

    renderRisksAndAlternatives = () => {
        return this.renderItemBody(
            this.state.content.protocol_risks,
            //this.state.content.protocol_risks_description,
            <Textarea
                name='risks'
                value={this.state.protocol.risks}
                onChange={this.handleProtocolDataChange}
                placeholder={this.state.content.protocol_risks_placeholder}
                disabled={['pending', 'inactive'].includes(this.state.protocol.status)}
                overrides={{
                    Input: {
                        style: {
                        maxHeight: '300px',
                        minHeight: '100px',
                        minWidth: '300px',
                        width: '100vw', // fill all available space up to parent max-width
                        resize: 'both',
                        },
                    },
                    InputContainer: {
                        style: {
                            maxWidth: '100%',
                            width: 'min-content',
                        },
                    },
                }}
            />)
    };

    renderRejectingNewSubscriptions = () => {
        return <FlexGridItem>
            <FlexGrid>
                <FlexGridItem>
                    <Checkbox
                        name='rejecting_new_subscriptions'
                        checked={this.state.protocol.rejecting_new_subscriptions}
                        onChange={this.handleProtocolDataCheckbox}
                        labelPlacement={LABEL_PLACEMENT.right}
                        disabled={['pending', 'inactive'].includes(this.state.protocol.status)}
                        checkmarkType={STYLE_TYPE.toggle}
                        overrides={{
                            ToggleTrack: {
                                style: ({ $theme }) => ({
                                    width:'100%',
                                    maxWidth: '40px'
                                })
                            }
                        }}
                    ><LabelMedium style={{margin: 0}}>{this.state.content.rejecting_new_subscriptions}</LabelMedium></Checkbox>
                </FlexGridItem>
                {/*<FlexGridItem><ParagraphSmall style={{margin: 0, color: '#666666', marginTop: '4px', marginBottom: '10px'}}>
                    {this.state.content.rejecting_new_subscriptions_description}</ParagraphSmall></FlexGridItem>*/}
            </FlexGrid>
        </FlexGridItem>;
    };

    renderEnableCommunityFeatures = () => {
        return <FlexGridItem>
            <FlexGrid>
                <FlexGridItem>
                    <Checkbox
                        name='enable_community_features'
                        checked={this.state.protocol.enable_community_features}
                        onChange={this.handleProtocolDataCheckbox}
                        labelPlacement={LABEL_PLACEMENT.right}
                        disabled={""}
                        checkmarkType={STYLE_TYPE.toggle}
                        overrides={{
                            ToggleTrack: {
                                style: ({ $theme }) => ({
                                    width:'100%',
                                    maxWidth: '40px'
                                })
                            }
                        }}
                    ><LabelMedium style={{margin: 0}}>{this.state.content.enable_community_features}</LabelMedium></Checkbox>
                </FlexGridItem>
                {/*<FlexGridItem><ParagraphSmall style={{margin: 0, color: '#666666', marginTop: '4px', marginBottom: '10px'}}>
                    {this.state.content.enable_community_features_description}</ParagraphSmall></FlexGridItem>*/}
                </FlexGrid>
        </FlexGridItem>;
    };

    renderTitle = () => {
        return <FlexGridItem {...centerProps} style={{margin: 0}}>
            <HeadingMedium style={{marginTop:"32px", marginBottom:"0px"}}>{['draft'].includes(this.state.protocol.status) ? 'Clinic builder': this.state.protocol.name + ' settings'}</HeadingMedium>
            </FlexGridItem>
    };

    renderSubtext = () => {
        return <FlexGridItem {...centerProps} style={{margin: '16px 0px'}}>
            <ParagraphLarge style={{margin: 0, maxWidth: '600px', textAlign: 'center'}}>
            {this.state.content.page_subtext}
            </ParagraphLarge>
        </FlexGridItem>
    };

    getConditions = () => {
        return this.state.conditions;
    };

    renderConditionsToAdd = () => {
        return this.state.conditions.map((condition, idx) => condition._destroy || !condition.short_name ? null : <FlexGrid key={idx}>
                <FlexGridItem {...leftCenterProps}><ParagraphSmall style={{margin: 0}}>{condition.short_name}</ParagraphSmall></FlexGridItem>
            </FlexGrid>);
    };

    addConditions = (new_conditions) => {
        this.setState({
            conditions: new_conditions,
            conditions_add: false
        })
    };

    cancelCondition = () => {
        this.setState({
            conditions_add: false
        })
    }

    renderServerRules = () => {
        return this.renderItemBody(
            this.state.content.protocol_rules,
            this.state.content.protocol_rules_description,
            <Textarea
                name='server_rules'
                value={this.state.protocol.server_rules}
                onChange={this.handleProtocolDataChange}
                placeholder={this.state.content.protocol_rules_placeholder}
                disabled={['pending', 'inactive'].includes(this.state.protocol.status)}
                overrides={{
                    Input: {
                        style: {
                        maxHeight: '300px',
                        minHeight: '100px',
                        minWidth: '300px',
                        width: '100vw', // fill all available space up to parent max-width
                        resize: 'both',
                        },
                    },
                    InputContainer: {
                        style: {
                            maxWidth: '100%',
                            width: 'min-content',
                        },
                    },
                }}
            />)
    };

    renderInviteCode = () => {
        return this.renderItemBody(
            this.state.content.optionals_share_invite_title,
            this.state.content.optionals_share_invite_description,
            <Button
                    size={SIZE.compact}
                    shape={SHAPE.pill}
                    kind={KIND.secondary}
                    onClick={this.showShareInvite}>🔒&nbsp;Add&nbsp;passcode
            </Button>)
    };

    showShareInvite = () => {
        Logger.log('Showing share invite...');
        this.setState({showShareInvite: true});
    };

    closeShareInvite = () => {
        this.setState({showShareInvite: false});
    };

    updateInviteCode = (inviteCode) => {
      this.setState({
        showShareInvite: false,
        inviteCode: inviteCode
      });
    };


    render = () => {
        return (
            <AuthUserContext.Consumer>
                {authUser =>
                    (
            <div style={{maxWidth: '570px', margin: 'auto'}}>
                {Utils.renderError(this.state.error)}
                {Utils.renderSuccess(this.state.success)}

                {this.state.protocol ? <FlexGrid
                    flexGridRowGap={"scale500"}
                    style={{
                        maxWidth:['XSMALL', 'SMALL'].includes(Screen.size()) ? 'calc(100vw - 32px)': '570px',
                        width:'100%'
                    }}
                    >
                    {/*this.renderTitle()*/}
                    {/*this.renderSubtext()*/}
                    {this.renderStatus()}
                    <Accordion
                        onChange={({ expanded }) => console.log(expanded)}
                        accordion={false}
                        overrides={{
                            Header: {
                              style: ({ $theme }) => ({
                                paddingRight: 0,
                                paddingLeft: 0
                              })
                            },
                            Content: {
                              style: ({ $theme }) => ({
                                paddingRight: 0,
                                paddingLeft: 0,
                                paddingTop: 0,
                                paddingBottom: '20px'
                              })
                            }
                          }}
                    >
                    <Panel title={this.state.protocol.status == 'draft' ? <HeadingXSmall style={{margin: 0}}>Clinic overview {this.areRequiredFieldsSet(false) ? '' : <span style={{color:'red'}}>(incomplete)</span> }</HeadingXSmall> : <HeadingXSmall style={{margin: 0}}>Clinic overview</HeadingXSmall>}>
                    <FlexGridItem>{this.renderFaviconUpload()}</FlexGridItem>
                    <FlexGridItem>{this.renderName()}</FlexGridItem>
                    <FlexGridItem>{this.renderSummary()}</FlexGridItem>
                    <FlexGridItem>{this.renderDescription()}</FlexGridItem>
                    <FlexGridItem>{this.renderProtocolPrice()}</FlexGridItem>
                    <FlexGridItem>{this.renderWelcomeMessage()}</FlexGridItem>
                    <FlexGridItem>{this.renderOfficeHours()}</FlexGridItem>
                    <FlexGridItem>{this.renderIntake()}</FlexGridItem>
                    <FlexGridItem>{this.renderFormulary()}</FlexGridItem>
                    <FlexGridItem>{this.renderIsRefillService()}</FlexGridItem>
                    {this.showOnboardingForm()}
                    {this.showDrugRequestForm()}
                    </Panel>
                    <Panel title={<HeadingXSmall style={{margin: 0}}>{this.state.content.optionals_title}</HeadingXSmall>}>
                    <FlexGridItem><Optionals protocol_tasks={this.state.protocol.protocol_tasks} protocol_status={this.state.protocol.status} callback={this.optionalsCallback} callbackError={this.callbackError}/></FlexGridItem>
                    </Panel>
                    <Panel title={<HeadingXSmall style={{margin: 0}}>Additional eligibility requirements</HeadingXSmall>}>
                    {this.renderPatientEligibility()}
                    {this.drawConditions()}
                    {this.renderRisksAndAlternatives()}
                    {this.state.conditions_add ? <Conditions
                            callback={this.addConditions}
                            callbackCancel={this.cancelCondition}
                            showModal={this.state.conditions_add}
                            items={this.getConditions()}
                        /> : null}
                    </Panel>
                    <Panel title={<HeadingXSmall style={{margin: 0}}>Privacy settings</HeadingXSmall>}>
                    {this.renderInviteCode()}
                    <ShareInvite show={this.state.showShareInvite} inviteCode={this.state.inviteCode} callback={this.updateInviteCode} closeShareInvite={this.closeShareInvite} callbackButton="Done" />
                    </Panel>
                    <Panel title={<HeadingXSmall style={{margin: 0}}>Overflow control</HeadingXSmall>}>
                    {this.renderRejectingNewSubscriptions()}
                    </Panel>
                    <Panel title={<HeadingXSmall style={{margin: 0}}>Community (experimental)</HeadingXSmall>}>
                    {this.renderEnableCommunityFeatures()}
                    </Panel>
                    </Accordion>
                    {this.renderActionButtons(authUser)}
                    {this.renderSubmitChickenMessage()}
                    </FlexGrid> : <Spinner />}
            </div>
            )
        }
    </AuthUserContext.Consumer>
        );
    };
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(ProtocolManagement);
