import React, {Component} from 'react';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import Account from './account';

class AccountPage extends Component {
    render() {
        return (
            <AuthUserContext.Consumer>
                {authUser => 
                    (
                        <div>
                            <Account />
                        </div>
                    )
                }
            </AuthUserContext.Consumer>
        );
    }
};

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(AccountPage);
