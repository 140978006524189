import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { LabelLarge, ParagraphLarge } from 'baseui/typography';
import React, {Component} from 'react';
import Utils from '../../shared/Utils';

class ProtocolInfo extends Component {
    constructor(props) {
        super(props);
    }

    render = () => {
        return <FlexGrid flexGridColumnCount={1} marginTop='10px'>
            <FlexGridItem><ParagraphLarge style={{margin: 0, display: 'inline'}}>Member status: </ParagraphLarge><LabelLarge style={{margin: 0, display: 'inline'}}>{Utils.upperCaseFirstLetter(this.props.subscription.status)}</LabelLarge></FlexGridItem>
        </FlexGrid>
    }
}

export default ProtocolInfo;
