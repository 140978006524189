import React from 'react';
import { StyledLink } from 'baseui/link';
import * as ROUTES from '../Constants';
import Screen from '../../shared/Utils/screen';
import {ParagraphLarge, DisplayMedium} from 'baseui/typography';

const data = {
    en: {
        top_image: "",

        banner_title: <DisplayMedium style={{margin: 0, padding: 0, fontSize: '34px', lineHeight: '24px'}}>Provider dashboard</DisplayMedium>,
        banner_subtext: <ParagraphLarge style={{margin: 0, fontSize: '21px', maxWidth: '1160px'}}>To get started, ① create your <StyledLink href={ROUTES.PROTOCOLS_NEW}>server</StyledLink>, ② complete your <StyledLink href={ROUTES.ACCOUNT}>profile</StyledLink> and ③ submit it for review. Once approved, your server will go live on the platform & you’re ready to see patients. Patient subscriptions are paid out once every 30 days.</ParagraphLarge>,
        banner_call_action: "",

        home_title: <span>Clinic for providers</span>,
        home_subtext: <span>Clinic gives doctors and APNRs an easy way to create their own internet clinic and share it with patients online. All clinics include an eRx pad with ECPS and lab requisitioning.</span>,

        sign_up_title: 'Sign up',

        // SlideShow
        slide_timeout: 900000,
        images_width: Screen.size() == 'SMALL' ? 'auto' : 'auto',
        images_height: Screen.size() == 'SMALL' ? '88px' : '88px',

        images: [
            "",
            "",
        ],

        // Footer
        about_text: "",
        legal_text: "Legal",
        legal_link: "https://github.com/Pharmr/run-legal",
        contact_text: "",
    }
}

const content = (lang) => {
    return data[lang];
};

export default content;
