import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../Constants';
import {AuthUserContext} from '../../shared/Session'
import SignOutButton from '../../shared/SignOut';

class Navigation extends Component {
    // TODO Could use the firebase protocol_id as a way to rerender navigation...
    render(){
        return(
            <div>
                <AuthUserContext.Consumer>
                    {isUserLoggedIn => isUserLoggedIn ? <NavigationAuth /> : <NavigationNonAuth />}
                </AuthUserContext.Consumer>
            </div>    
        );
    }
};
const NavigationAuth = () => {
    return (
        <ul>
            <li>
                <Link to={ROUTES.OPS}>Ops</Link>
            </li>
            <li>
                <Link to={ROUTES.PASSWORD_CHANGE}>Change Password</Link>
            </li>
            <li>
                <SignOutButton />
            </li>
        </ul>
    );
};

const NavigationNonAuth = () => {
    return (
        <ul>
            <li>
                <Link to={ROUTES.SIGN_IN}>Sign in</Link>
            </li>
            <li>
                <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
            </li>
            <li>
                <Link to={ROUTES.PASSWORD_RESET}>Reset Password</Link>
            </li>
        </ul>
    );
};
 
export default Navigation;