// Patient chat test

$(document).on('click', '#chat-button--1', function() {
  App.$body.removeClass('js-chat-button-2 js-chat-button-3').toggleClass('js-chat-button-1');
});

$(document).on('click', '#chat-button--2', function() {
  App.$body.removeClass('js-chat-button-1 js-chat-button-3').toggleClass('js-chat-button-2');
});

$(document).on('click', '#chat-button--3', function() {
  App.$body.removeClass('js-chat-button-1 js-chat-button-2').toggleClass('js-chat-button-3');
});

$(document).on('click', '.menu-panel__close', function() {
  App.$body.removeClass('js-chat-button-1 js-chat-button-2 js-chat-button-3');
});

$(document).on('keyup', '.str-chat__textarea__textarea', function(e) {
  if ( e.which == 13 ) {
    var $input = $(this);

    $input.blur();
  }
});
