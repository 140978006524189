import React, {Component} from 'react';
import { AuthUserContext, withAuthorization } from '../../shared/Session';
import * as ROUTES from '../Constants';
import Utils from '../../shared/Utils';
import Logger from '../../shared/Utils/logger';
import { DisplayXSmall, LabelLarge, LabelSmall, ParagraphSmall, ParagraphXSmall } from 'baseui/typography';
import {FlexGrid, FlexGridItem} from 'baseui/flex-grid';
import Request from '../../shared/Request';
import {Spinner} from '../../shared/Spinner';

const rightItemProps = {
    overrides: {
        Block: {
            style: ({$theme}) => ({
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'start',
            }),
        },
    },
};

class PaymentDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            payment: null
        };
    }

    componentDidMount = () => {
        Request.get({
            firebase: this.props.firebase,
            route: ROUTES.BASE_URL_API + "/remittances/" + this.props.number,
            callbackSuccess: (result) => {
                this.setState({
                    isLoaded: true,
                    payment: result,
                    ...Utils.clearAllNotifications()
                })
            },
            callbackError: (error) => this.setState({
                isLoaded: true,
                payment: null,
                error,
                ...Utils.clearAllNotificationsExceptError()
            })
        })
    };

    renderInvoice = () => {
        return <div>
            <ParagraphSmall style={{margin: 0}}>Date: {Utils.renderLocaleString(this.state.payment.created_at)}</ParagraphSmall>
            <ParagraphSmall style={{color: "#999999", margin: 0}}>{this.state.payment.number}</ParagraphSmall>
        </div>
    };

    renderBusiness = () => {
        return <div>
            <ParagraphSmall style={{margin: 0}}>{this.state.payment.business_name}</ParagraphSmall>
            <ParagraphSmall style={{margin: 0}}>{this.state.payment.address}</ParagraphSmall>
            <ParagraphSmall style={{margin: 0}}>Acct ending in {this.state.payment.account_last_four}</ParagraphSmall>
        </div>
    };

    renderPayPeriod = () => {
        return <div>
            <ParagraphSmall style={{margin: 0}}>Pay period: {this.state.payment.start_date.replaceAll("-", "/")} - {this.state.payment.end_date.replaceAll("-", "/")}</ParagraphSmall>
            <ParagraphSmall style={{margin: 0}}>Status: {Utils.upperCaseFirstLetter(this.state.payment.payment_status)}</ParagraphSmall>
            <div></div>
        </div>
    };

    renderCompanyInfo = () => {
        return <div style={{textAlign: 'center'}}>
            <ParagraphSmall style={{margin: "20px 0px 0px 0px"}}>Clinic.dev</ParagraphSmall>
            <ParagraphSmall style={{margin: 0}}>{Utils.renderCompanyAddress()}</ParagraphSmall>
        </div>
    };

    renderNoneFeeItems = () => {
        return this.state.payment.remittance_items.map(
            (remittance, idx) => !remittance.is_fee && <FlexGrid flexGridColumnCount={3} key={idx}>
                <FlexGridItem><ParagraphSmall style={{margin: 0}}>{remittance.name} {Utils.renderDollarAmount(remittance.amount)}</ParagraphSmall></FlexGridItem>
                <FlexGridItem {...rightItemProps}><ParagraphSmall style={{margin: 0}}>{remittance.quantity}</ParagraphSmall></FlexGridItem>
                <FlexGridItem {...rightItemProps}><ParagraphSmall style={{margin: 0}}>{Utils.renderDollarAmount(parseFloat(remittance.amount) * remittance.quantity)}</ParagraphSmall></FlexGridItem>
            </FlexGrid>
        )
    };

    renderFeeItems = () => {
        return this.state.payment.remittance_items.map(
            (remittance, idx) => remittance.is_fee && <FlexGrid flexGridColumnCount={3} key={idx}>
                <FlexGridItem><ParagraphSmall style={{margin: 0}}>{remittance.name}</ParagraphSmall></FlexGridItem>
                <FlexGridItem {...rightItemProps}><ParagraphSmall style={{margin: 0}}></ParagraphSmall></FlexGridItem>
                <FlexGridItem {...rightItemProps}><ParagraphSmall style={{margin: 0}}>{Utils.renderDollarAmount(parseFloat(remittance.amount) * remittance.quantity)}</ParagraphSmall></FlexGridItem>
            </FlexGrid>
        )
    };

    renderProtocol = () => {
        return <div>
            <LabelLarge style={{margin: "0px 0px 20px 0px"}}>{this.state.payment.protocol.name} ({this.state.payment.protocol.number})</LabelLarge>
            <FlexGrid flexGridColumnCount={1}>
                <FlexGridItem>
                <FlexGrid flexGridColumnCount={3}>
                    <FlexGridItem><LabelSmall style={{color: "#999999", margin: 0}}>Item</LabelSmall></FlexGridItem>
                    <FlexGridItem {...rightItemProps}><LabelSmall style={{color: "#999999", margin: 0}}>Quantity</LabelSmall></FlexGridItem>
                    <FlexGridItem {...rightItemProps}><LabelSmall style={{color: "#999999", margin: 0}}>Total</LabelSmall></FlexGridItem>
                </FlexGrid>

                {this.renderNoneFeeItems()}

                <FlexGrid flexGridColumnCount={3} marginBottom={"20px"}>
                    <FlexGridItem><LabelSmall style={{margin: 0}}>Subtotal</LabelSmall></FlexGridItem>
                    <FlexGridItem display="none"></FlexGridItem>
                    <FlexGridItem {...rightItemProps}><LabelSmall style={{margin: 0}}>{Utils.renderDollarAmount(this.state.payment.subtotal)}</LabelSmall></FlexGridItem>
                </FlexGrid>

                {this.renderFeeItems()}

                <FlexGrid flexGridColumnCount={3}>
                    <FlexGridItem><DisplayXSmall style={{cmargin: 0}}>Total Payment</DisplayXSmall></FlexGridItem>
                    <FlexGridItem display="none"></FlexGridItem>
                    <FlexGridItem {...rightItemProps}><DisplayXSmall style={{margin: 0}}>{Utils.renderDollarAmount(this.state.payment.total)}</DisplayXSmall></FlexGridItem>
                </FlexGrid>
                </FlexGridItem>
            </FlexGrid>
        </div>
    };

    render () {
        return (
            <AuthUserContext.Consumer>
                {
                    authUser => (
                        <FlexGrid flexGridRowGap={"scale600"}>
                            {
                                this.state.payment
                                ?
                                    <>
                                        <FlexGridItem>{this.renderInvoice()}</FlexGridItem>
                                        <FlexGridItem>{this.renderBusiness()}</FlexGridItem>
                                        <FlexGridItem>{this.renderPayPeriod()}</FlexGridItem>
                                        <FlexGridItem>{this.renderProtocol()}</FlexGridItem>
                                        <FlexGridItem>{this.renderCompanyInfo()}</FlexGridItem>
                                    </>
                                : <FlexGridItem>{
                                        this.state.isLoaded
                                        ? <ParagraphXSmall>No payment details found.</ParagraphXSmall>
                                        : <Spinner />}
                                    </FlexGridItem>
                            }
                        </FlexGrid>
                    )
                }
            </AuthUserContext.Consumer>
        );
    }
}

const loggedInUser = authUser => !!authUser;
export default withAuthorization(loggedInUser)(PaymentDetails);
